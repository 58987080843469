import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from '../../../store/actions/employeeActions';
// import SelectField from '../../elements/form/SelectField';
import { createReviewSchedule, getReviewForms } from '../../../store/actions/reviewsActions';
import AutocompleteSelect from '../../elements/form/AutocompleteSelect';
import RadioGroup from '../../elements/form/RadioGroup';
import DateField from '../../elements/form/DateField';
import FormButton from '../../elements/form/FormButton';
import { ERROR } from '../../../store/types';

const NewReviewSchedule = ({employee}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const reviewsSelector = useSelector(state => state.reviews)
    const [schedulePayload, setSchedulePayload] = useState({
        employee: employee?._id || undefined
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [dueDateRange, setDueDateRange] = useState({});

    useEffect(() => {
        dispatch(fetchEmployees(0, 0))
        dispatch(getReviewForms(0, 0))
        return () => {
            
        };
    }, [dispatch]);

    const [dueType, setDueType] = useState('');
    const dueDateTypes = [
        {
            label: 'Specific Date', 
            value: 'specific',
            description: 'The review will be due on a specific day'
        },
        {
            label: 'Date Range', 
            value: 'range',
            description: 'Allows for a date range within which the review can be submitted'
        }
    ]

    const validateForm = () => {
        let errors = {}
        if (!schedulePayload.employee || schedulePayload.employee === '') {
            errors.employee = true
        }
        if (!schedulePayload.reviewer || schedulePayload.reviewer === '') {
            errors.reviewer = true
        }
        if(dueType === 'specific' && (!schedulePayload.dueDate || schedulePayload.dueDate === '')){
            errors.dueDate = true
        }
        if(dueType === 'range' && (!dueDateRange.from || dueDateRange.from === '')){
            errors.dueDateFrom = true
        }
        if(dueType === 'range' && (!dueDateRange.to || dueDateRange.to === '')){
            errors.dueDateTo = true
        }

        setValidationErrors(errors)
        return errors
    }

    const pushSchedule = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = {...schedulePayload}
        if(dueType === 'range') {
            payload.dueDateRange = dueDateRange
        } 

        dispatch(createReviewSchedule(payload))

    }

    return (
        <div className='w-full'>
            {employeesSelector?.employees?.employees?.length > 0 ? <div className='w-full mt-2'>
                <AutocompleteSelect
                    selectOptions = {employeesSelector?.employees?.employees}
                    requiredField={true}
                    inputLabel = "Employee"
                    titleField = "fullName"
                    displayImage = {true}
                    imageField = "image"
                    fieldId = "employee"
                    preSelected={employee._id || ''}
                    preSelectedLabel='_id'
                    hasError = {validationErrors && validationErrors.employee}
                    returnFieldValue={(value)=>{setSchedulePayload({...schedulePayload, ...{employee: value._id}})}}
                    disabled={employee}
                />
            </div> : 
            <p className='text-gray-400 text-xs'>No employees found</p>
            }
            
            {employeesSelector?.employees?.employees?.length > 0 ? <div className='w-full mt-2'>
                <AutocompleteSelect
                    selectOptions = {employeesSelector?.employees?.employees}
                    requiredField={true}
                    inputLabel = "Reviewer (Who will review the selected employee above)"
                    titleField = "fullName"
                    displayImage = {true}
                    imageField = "image"
                    fieldId = "reviewer"
                    preSelected={''}
                    preSelectedLabel='code'
                    hasError = {validationErrors && validationErrors.employee}
                    returnFieldValue={(value)=>{setSchedulePayload({...schedulePayload, ...{reviewer: value._id}})}}
                    disabled={false}
                />
            </div> : 
            <p className='text-gray-400 text-xs'>No employees found</p>
            }

            {reviewsSelector?.reviewForms?.forms?.length > 0 ? <div className='w-full mt-2'>
                <AutocompleteSelect
                    selectOptions = {reviewsSelector.reviewForms?.forms}
                    requiredField={true}
                    inputLabel = "Review form"
                    titleField = "name"
                    displayImage = {true}
                    imageField = "image"
                    fieldId = "form"
                    preSelected={''}
                    preSelectedLabel='code'
                    hasError = {validationErrors && validationErrors.form}
                    returnFieldValue={(value)=>{setSchedulePayload({...schedulePayload, ...{form: value._id}})}}
                    disabled={false}
                />
            </div> : 
            <p className='text-gray-400 text-xs'>No employees found</p>
            }

            <div className='mt-4 w-full'>
                <RadioGroup
                    inputLabel="Due date type"
                    items={dueDateTypes} 
                    inline={false}
                    hasError={validationErrors.gender} 
                    returnSelected={(value)=>{setDueType(value.value)}}
                />
            </div>

            {dueType === 'specific' && 
                <div className='w-full mt-4'>
                    <DateField
                        inputLabel="Date" 
                        inputPlaceholder="" 
                        fieldId={`review-due-date`} 
                        inputType="date" 
                        hasError={validationErrors.dueDate} 
                        returnFieldValue={(value) => {setSchedulePayload({...schedulePayload, ...{dueDate: new Date(value)}})}}
                    />
                </div>
            }

            {dueType === 'range' && 
                <div className='w-full flex items-center justify-between gap-x-[10px]'>
                    <div className='w-full mt-4'>
                        <DateField
                            inputLabel="Date from" 
                            inputPlaceholder="" 
                            fieldId={`review-due-date-from`} 
                            inputType="date" 
                            hasError={validationErrors.dueDate} 
                            returnFieldValue={(value) => {setDueDateRange({...dueDateRange, ...{from: new Date(value)}})}}
                        />
                    </div>
                    <div className='w-full mt-4'>
                        <DateField
                            inputLabel="Date" 
                            inputPlaceholder="" 
                            fieldId={`review-due-date-to`} 
                            inputType="date" 
                            hasError={validationErrors.dueDate} 
                            returnFieldValue={(value) => {setDueDateRange({...dueDateRange, ...{to: new Date(value)}})}}
                        />
                    </div>
                </div>
            }

            <div className='mt-8 mb-4 flex flex-row-reverse items-center justify-between'>
                <div className='w-full'>
                    <FormButton 
                        buttonLabel={`Create Review Schedule`} 
                        buttonAction={()=>{pushSchedule()}} 
                        processing={reviewsSelector.creatingReview}
                    />
                </div>
            </div>


            
        </div>
    )
}

export default NewReviewSchedule