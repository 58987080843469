import React, { useEffect, useState } from 'react'
import Logo from '../../assets/img/ss-tt.png'
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextField from '../../components/elements/form/TextField';
import RadioGroup from '../../components/elements/form/RadioGroup';
import FormButton from '../../components/elements/form/FormButton';
import SelectField from '../../components/elements/form/SelectField';
import DateField from '../../components/elements/form/DateField';
import ArrowIcon from '../../components/elements/icons/ArrowIcon';
import FileUpload from '../../components/elements/form/FileUpload';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types';
import Preloader from '../../components/elements/Preloader';
// import Checkbox from '../../components/elements/form/Checkbox';
// import ReadDocument from '../../components/partials/documents/ReadDocument';
// import ModalLayout from '../../components/layout/ModalLayout';

const Signup = () => {
    const [onboardPayload, setOnboardPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
	const [activeStep, setActiveStep] = useState(1);
	const navigate = useNavigate();
	const { invitationCode } = useParams()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true);
	// const [invitationDetails, setInvitationDetails] = useState(null);
	// const [documentSignings, setDocumentSignings] = useState([]);
	const [nextOfKin, setNextOfKin] = useState({});
	const [emergencyContact, setEmergencyContact] = useState({});
	const [socialMedia, setSocialMedia] = useState({});

	useEffect(() => {
		const getInvitation = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/employees/invitations/${invitationCode}?expand=requiredDocuments`, {  })
				// setInvitationDetails(response.data.data)
				// setDocumentSignings(response.data.data.requiredDocuments.map((doc)=> {
				// 	return {
				// 		document: doc._id,
				// 		signed: false,
				// 		signature: ''
				// 	}
				// }))

				const docsToProvide = response.data.data.requiredDocuments.filter(required => required.providedBy === 'employee')

				setProvidedDocuments(docsToProvide.map(doc => {return {
					_id: doc._id,
					requiresDocumentNumber: doc.requiresDocumentNumber,
					requiresExpiry: doc.requiresExpiry
				}}))
				setOnboardPayload({
					firstName: response.data.data.firstName,
					middleName: response.data.data.middleName || '',
					lastName: response.data.data.lastName,
					email: response.data.data.email,
					gender: response.data.data.employee.gender
				})
				setLoading(false)
			} catch (error) {
			  dispatch({
				type: ERROR,
				error: error
			  })
			  setLoading(false)
			}
		  }
	
		  getInvitation()
		return () => {
			
		};
	}, [dispatch, invitationCode]);
	
	const [showPasswordLengthWarning, setShowPasswordLengthWarning] = useState(false);
	const validateStep1Form = () => {
        let errors = {}

        if (!password || password === '') {
            errors.password = true
        }

		if(password && password.length < 8) {
			errors.password = true
			setShowPasswordLengthWarning(true)
		}

        setValidationErrors(errors)
        return errors
    }

	const completeStep1 = () => {
		if (Object.values(validateStep1Form()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
		setActiveStep(2)
	}

	const [password, setPassword] = useState('');
	const educationTypes = [
		{
			label: "Select certification/type",
			value: ""
		},
		{
			label: "Bachelors Degree",
			value: "Bachelors Degree"
		},
		{
			label: "Masters Degree",
			value: "Masters Degree"
		},
		{
			label: "Doctorate Degree",
			value: "Doctorate Degree"
		},
	]

	const educationItem = {
		type: '',
		institution: '',
		course: '',
		startDate: '',
		endDate: ''
	}

	const [education, setEducation] = useState([educationItem]);

	const addEducation = () => {
		let tempEducation = [...education]
		tempEducation.push(educationItem)
		setEducation(tempEducation)
	}

	const updateEducation = (index, field, value) => {
		let tempEducation = [...education]
		tempEducation[index][field] = value
		setEducation(tempEducation)
	}

	const validateStep2Form = () => {
        let errors = {}
		education.forEach((item, itemIndex)=>{
			if (!item.type || item.type === '') {
				errors[`type-${itemIndex}`] = true
			}
			if (!item.institution || item.institution === '') {
				errors[`institution-${itemIndex}`] = true
			}
			if (!item.startDate || item.startDate === '') {
				errors[`startDate-${itemIndex}`] = true
			}
			if (!item.endDate || item.endDate === '') {
				errors[`endDate-${itemIndex}`] = true
			}
			// if (!item.course || item.course === '') {
			// 	errors[`course-${itemIndex}`] = true
			// }
		})

        setValidationErrors(errors)
        return errors
    }

	const completeStep2 = () => {
		if (Object.values(validateStep2Form()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
		setActiveStep(3)
	}

	const validateStep3Form = () => {
        let errors = {}
		if(!nextOfKin.name || nextOfKin.name === '') {
			errors.nokName = true
		}

		if(!nextOfKin.phone || nextOfKin.phone === '') {
			errors.nokPhone = true
		}

		if(!nextOfKin.email || nextOfKin.email === '') {
			errors.nokEmail = true
		}

		if(!nextOfKin.address || nextOfKin.address === '') {
			errors.nokAddress = true
		}

		if(!emergencyContact.name || emergencyContact.name === '') {
			errors.emergencyContactName = true
		}

		if(!emergencyContact.phone || emergencyContact.phone === '') {
			errors.emergencyContactPhone = true
		}

		if(!emergencyContact.email || emergencyContact.email === '') {
			errors.emergencyContactEmail = true
		}

		if(!emergencyContact.address || emergencyContact.address === '') {
			errors.emergencyContactAddress = true
		}

        setValidationErrors(errors)
        return errors
    }

	const completeStep3 = () => {
		if (Object.values(validateStep3Form()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
		setActiveStep(4)
	}

	const completeStep4 = () => {
		setActiveStep(5)
	}

	const [bankDetails, setBankDetails] = useState({});

	const validateStep5 = () => {
		let errors = {}
		if(!bankDetails.accountNumber || bankDetails.accountNumber === '') {
			errors.accountNumber = true
		}
		if(!bankDetails.accountName || bankDetails.accountName === '') {
			errors.accountName = true
		}
		if(!bankDetails.bank || bankDetails.bank === '') {
			errors.bank = true
		}
		if(!bankDetails.sortCode || bankDetails.sortCode === '') {
			errors.sortCode = true
		}

        setValidationErrors(errors)
        return errors
	}

	const completeStep5 = () => {
		if (Object.values(validateStep5()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

		setActiveStep(6)
	}

	const [providedDocuments, setProvidedDocuments] = useState([]);

	const updateDocument = (index, field, value) => {
		let temp = [...providedDocuments]
		temp[index][field] = value
		setProvidedDocuments(temp)
	}

	// const [files, setFiles] = useState([]);

	// const addFile = (doc, file) => {
	// 	let tempFiles = [...files]
	// 	tempFiles.push({
	// 		document: doc,
	// 		file: file
	// 	})
	// 	setFiles(tempFiles)
	// }
  
	const handleUpload = async (file) => {
		console.log(file)

		var formData = new FormData()
		formData.append('file', file.file )
		const headers = new Headers();

		try {
			const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
				method: "POST",
				headers,
				body: formData,
			});
			const response = await doUpload.json();

			return response
		} catch (error) {
			dispatch({
				type: ERROR,
				error,
			});
		}
  	}

	// const completeStep3 = () => {
	// 	if(!files || files.length === 0) {
	// 		// setActiveStep(4)
	// 		dispatch({
	// 			type: ERROR,
	// 			error: {response: {data: {
	// 				message: 'Please upload required documents'
	// 			}}}
	// 		});
	// 		return
	// 	}
	// 	setActiveStep(4)
	// }

	// const [activeDocument, setActiveDocument] = useState(null);
	// const [readingDocument, setReadingDocument] = useState(false);

	// const readDocument = (document) => {
	// 	console.log('active document: ', document)
	// 	setActiveDocument(document)
	// 	setTimeout(() => {
	// 		setReadingDocument(true)
	// 	}, 100);
	// }

	const [processing, setProcessing] = useState(false);
	const completeOnboarding = async () => {
		try {
			// upload files
			setProcessing(true)
	
			let uploadedDocuments = []
			for (let index = 0; index < providedDocuments.length; index++) {
				const element = providedDocuments[index];
				const uploaded = await handleUpload(element.file)
	
				uploadedDocuments.push({
					_id: element._id, 
					documentUrl: uploaded.data.file,
					documentNumber: element.documentNumber || undefined,
					documentExpiry: element.documentExpiry || undefined,
				})
			}
			const payload = {
				...onboardPayload,
				...{
					password: password,
					education: education,
					documents: uploadedDocuments,
					nextOfKin,
					emergencyContact,
					socialMedia
				}
			}
	
			console.log(payload)
			// return
			await axios.post(`${process.env.REACT_APP_API_URL}/employees/signup/${invitationCode}`, payload, {})
			dispatch({
				type: SET_SUCCESS_MESSAGE,
				payload: {
					successMessage: 'Onboarding is complete. You can now log in to the user area.'
				}
			})
			navigate('/')
			
		} catch (error) {
			dispatch({
				type: ERROR,
				error: error
			})
			setProcessing(false)
		}
	}

	// const docDetails = (docId) => {
	// 	const doc = invitationDetails.requiredDocumentSignings.find((requiredDoc) => {
	// 		return requiredDoc._id === docId
	// 	})
	// 	return doc
	// }

	// const updateDocSigning = (index, field, value) => {
	// 	let temp = [...documentSignings]
	// 	temp[index][field] = value
	// 	setDocumentSignings(temp)
	// }

	const genders = [
		{label: 'Female', value: 'FEMALE'},
		{label: 'Male', value: 'MALE'},
	]

	return (
		<>
			<div className="relative">
				<div className="w-10/12 xl:w-8/12 2xl:w-4/12 p-10 rounded border border-gray-10 mx-auto mt-24">
					<Link to="/">
						<span className="flex items-center gap-x-2">
							<img src={Logo} alt="logo" className='w-[100px]' />
							{/* <p className="tracking-[0.2em] font-medium mt-[10px]">HRMS</p> */}
						</span>
					</Link>

					<h3 className='font-montserrat font-medium mt-3'>Hi {onboardPayload.firstName}, welcome aboard</h3>
					<p className='mt-2 dark:text-gray-500 text-sm'>You&apos;ve gotten here because you have been enrolled on the Verovian HRMS Platform by and administrator.</p>
					<p className='mt-4 dark:text-gray-500 text-sm'>Please provide your details in the following steps to complete your onboarding</p>
					
					{loading === true ? 
					<Preloader preloadingText={'Loading your invitation... '} />
					: 
					<>
						{activeStep === 1 && <>
							<p className='text-sm text-gray-500 mt-6'><span className='text-black fon-medium'>STEP 1:</span> Personal Information</p>
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="First name" 
									inputPlaceholder="Your given name" 
									fieldId="first-name" 
									inputType="first-name" 
									disabled={true}
									preloadValue={onboardPayload.firstName || ''}
									hasError={validationErrors.firstName} 
									returnFieldValue={(value)=>{setOnboardPayload({...onboardPayload, ...{firstName: value}})}}
								/>
							</div>
							
							<div className='mt-6 w-full'>
								<TextField
									inputLabel="Middle name" 
									inputPlaceholder="Middle name" 
									fieldId="middle-name" 
									inputType="text" 
									hasError={validationErrors.middleName} 
									disabled={true}
									preloadValue={onboardPayload.middleName || ''}
									returnFieldValue={(value)=>{setOnboardPayload({...onboardPayload, ...{middleName: value}})}}
								/>
							</div>
							
							<div className='mt-6 w-full'>
								<TextField
									inputLabel="Last name" 
									inputPlaceholder="Your surname" 
									fieldId="last-name" 
									inputType="text" 
									hasError={validationErrors.lastName} 
									disabled={true}
									preloadValue={onboardPayload.lastName || ''}
									returnFieldValue={(value)=>{setOnboardPayload({...onboardPayload, ...{lastName: value}})}}
								/>
							</div>
							
							<div className='mt-6 w-full'>
								<TextField
									inputLabel="Email" 
									inputPlaceholder="Your surname" 
									fieldId="last-name" 
									inputType="text" 
									hasError={validationErrors.email} 
									disabled={true}
									preloadValue={onboardPayload.email || ''}
									returnFieldValue={(value)=>{setOnboardPayload({...onboardPayload, ...{email: value}})}}
								/>
							</div>

							<div className='mt-6 w-full'>
								<RadioGroup
									inputLabel="Gender"
									items={genders} 
									hasError={validationErrors.gender} 
									disabled={false}
									preSelectedIndex={genders.findIndex(gender => gender.value === onboardPayload?.gender)}
									returnSelected={(value)=>{setOnboardPayload({...onboardPayload, ...{gender: value.value}})}}
								/>
							</div>

							<div className='mt-6 w-full'>
								<TextField
									inputLabel="Pronouns" 
									inputPlaceholder="Your pronouns" 
									fieldId="pronouns" 
									inputType="text" 
									hasError={false} 
									disabled={false}
									preloadValue={onboardPayload.pronouns || ''}
									returnFieldValue={(value)=>{setOnboardPayload({...onboardPayload, ...{pronouns: value}})}}
								/>
							</div>

							<p className='mt-4 dark:text-gray-500 text-sm mb-2'>Create your password to use and access this platform. please choose a strong password</p>
							<div className='mb-4 w-full'>
								<TextField
									inputLabel="Password" 
									inputPlaceholder="Your password" 
									fieldId="password" 
									inputType="password" 
									preloadValue={password || ''}
									hasError={validationErrors.password} 
									returnFieldValue={(value)=>{setPassword(value)}}
								/>
								{showPasswordLengthWarning && 
								<p className="text-red-500 text-xs">Password should not be less than 8 characters.</p>
								}
							</div>

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full'>
								<FormButton 
									buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`-4 h-4 transform -rotate-90`} /></span>} 
									buttonAction={()=>{completeStep1()}} />
								{/* <Link to="/admin" className='block w-full p-4 bg-black text-white text-center text-md rounded-md transition duration-200 hover:bg-allawee-ink-navy'>Login to your account</Link> */}
							</div>
						</>}
						
						{activeStep === 2 && <>
							<p className='text-sm text-gray-500 mt-6'><span className='text-black fon-medium'>STEP 2:</span> Education</p>
							{education.map((item, itemIndex)=>(<div key={itemIndex} className='w-full border rounded p-3 mb-5'>
								<div className='mt-3 w-full'>
									<SelectField
										selectOptions={educationTypes}
										inputLabel="Select education type"
										titleField="label"
										displayImage={false}
										imageField=""
										preSelected=''
										fieldId="account"
										hasError={validationErrors[`type-${itemIndex}`]}
										// return id of accounts of the selected option
										returnFieldValue={(value) => {updateEducation(itemIndex, 'type', value.value)}}
									/>
								</div>
								
								<div className='mt-6 w-full'>
									<TextField
										inputLabel="Institution" 
										inputPlaceholder="Name of institution" 
										fieldId="institution-name" 
										inputType="text" 
										hasError={validationErrors[`institution-${itemIndex}`]} 
										preloadValue={item.institution || ''}
										returnFieldValue={(value) => {updateEducation(itemIndex, 'institution', value)}}
									/>
								</div>
								
								<div className='mt-6 w-full'>
									<TextField
										inputLabel="Course" 
										inputPlaceholder="Which course did you study here?" 
										fieldId="middle-name" 
										inputType="text" 
										hasError={validationErrors[`course-${itemIndex}`]} 
										preloadValue={item.course || ''}
										returnFieldValue={(value) => {updateEducation(itemIndex, 'course', value.label)}}
									/>
								</div>

								<div className='mt-6 w-full flex items-center justify-between gap-x-6'>
									<div className='w-full'>
										<DateField
											inputLabel="Start date" 
											inputPlaceholder="" 
											fieldId="education-start-date" 
											inputType="date" 
											hasError={validationErrors[`startDate-${itemIndex}`]} 
											returnFieldValue={(value) => {updateEducation(itemIndex, 'startDate', value)}}
											preloadValue={item.startDate || ''}
										/>
									</div>
									<div className='w-full'>
										<DateField
											inputLabel="End date" 
											inputPlaceholder="" 
											fieldId="education-end-date" 
											inputType="date" 
											hasError={validationErrors[`endDate-${itemIndex}`]}
											preloadValue={item.endDate || ''}
											returnFieldValue={(value) => {updateEducation(itemIndex, 'endDate', value)}}
										/>
									</div>
								</div>
							</div>))}

							<button className='rounded bg-verovian-light-purple p-3 text-verovian-purple text-sm' onClick={()=>{addEducation()}}>Add Education</button>

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
									<FormButton 
										buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`-4 h-4 transform -rotate-90`} /></span>} 
										buttonAction={()=>{completeStep2()}} />
								</div>
							</div>
						</>}

						{activeStep === 3 && <>
							<p className='text-sm text-gray-700 mt-6'><span className='text-black font-medium'>STEP 3:</span> Next of Kin & Emergency Contacts</p>
							<p className='mt-3 text-gray-600 text-sm font-[500]'>Please Provide details of your next of Kin below</p>

							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Next of Kin Name" 
									inputPlaceholder="Name" 
									fieldId="nok-name" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.name || ''}
									hasError={validationErrors.nokName} 
									returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{name: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Phone number" 
									inputPlaceholder="Active phone number" 
									fieldId="nok-phone" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.phone || ''}
									hasError={validationErrors.nokPhone} 
									returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{phone: value}})}}
								/>
							</div>

							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Email address" 
									inputPlaceholder="Active email address" 
									fieldId="nok-email" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.email || ''}
									hasError={validationErrors.nokEmail} 
									returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{email: value}})}}
								/>
							</div>

							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Address" 
									inputPlaceholder="Current address of Next of Kin" 
									fieldId="nok-address" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.address || ''}
									hasError={validationErrors.nokAddress} 
									returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{address: value}})}}
								/>
							</div>

							<p className='mt-8 text-gray-600 text-sm font-[500]'>Please Provide details of an emergency contact below</p>
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Emergency contact name" 
									inputPlaceholder="Name" 
									fieldId="nok-name" 
									inputType="text" 
									disabled={false}
									preloadValue={emergencyContact.name || ''}
									hasError={validationErrors.emergencyContactName} 
									returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{name: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Phone number" 
									inputPlaceholder="Active phone number" 
									fieldId="nok-phone" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.phone || ''}
									hasError={validationErrors.emergencyContactPhone} 
									returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{phone: value}})}}
								/>
							</div>

							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Email address" 
									inputPlaceholder="Active email address" 
									fieldId="nok-email" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.email || ''}
									hasError={validationErrors.emergencyContactEmail} 
									returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{email: value}})}}
								/>
							</div>

							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Address" 
									inputPlaceholder="Current address of Next of Kin" 
									fieldId="nok-address" 
									inputType="text" 
									disabled={false}
									preloadValue={nextOfKin.address || ''}
									hasError={validationErrors.emergencyContactAddress} 
									returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{address: value}})}}
								/>
							</div>

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
								<FormButton 
									buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`w-4 h-4 transform -rotate-90`} /></span>} 
									buttonAction={()=>{completeStep3()}} />
								</div>
							</div>
						</>}

						{activeStep === 4 && <>
							<p className='text-sm text-gray-700 mt-6'><span className='text-black font-medium'>STEP 4:</span> Social Media</p>
							<p className='mt-4 text-gray-600 text-sm mb-2'>Provide links to your social media profiles below if available. You can skip this step if you do not have any social media presence.</p>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Linkedin" 
									inputPlaceholder="Paste linkedin profile url" 
									fieldId="linkedin" 
									inputType="text" 
									disabled={false}
									preloadValue={socialMedia.linkedin || ''}
									hasError={false} 
									returnFieldValue={(value)=>{setSocialMedia({...socialMedia, ...{linkedin: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Twitter/X" 
									inputPlaceholder="Paste twitter/X profile url" 
									fieldId="twitter" 
									inputType="text" 
									disabled={false}
									preloadValue={socialMedia.twitter || ''}
									hasError={false} 
									returnFieldValue={(value)=>{setSocialMedia({...socialMedia, ...{twitter: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Facebook" 
									inputPlaceholder="Paste facebook profile url" 
									fieldId="facebook" 
									inputType="text" 
									disabled={false}
									preloadValue={socialMedia.facebook || ''}
									hasError={false} 
									returnFieldValue={(value)=>{setSocialMedia({...socialMedia, ...{facebook: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Instagram" 
									inputPlaceholder="Paste instagram profile url" 
									fieldId="instagram" 
									inputType="text" 
									disabled={false}
									preloadValue={socialMedia.instagram || ''}
									hasError={false} 
									returnFieldValue={(value)=>{setSocialMedia({...socialMedia, ...{instagram: value}})}}
								/>
							</div>

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
								<FormButton 
									buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`-4 h-4 transform -rotate-90`} /></span>} 
									buttonAction={()=>{completeStep4()}} />
								</div>
							</div>
						</>}

						{activeStep === 5 && <>
							<p className='text-sm text-gray-700 mt-6'><span className='text-black font-medium'>STEP 5:</span> Bank/Payment Details</p>
							<p className='mt-4 text-gray-600 text-sm mb-2'>Provide your valid bank details below.</p>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Account Number" 
									inputPlaceholder="Your account number" 
									fieldId="account-number" 
									inputType="text" 
									disabled={false}
									preloadValue={bankDetails.accountNumber || ''}
									hasError={validationErrors.accountNumber} 
									returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{accountNumber: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Account Name" 
									inputPlaceholder="Valid account name" 
									fieldId="account-number" 
									inputType="text" 
									disabled={false}
									preloadValue={bankDetails.accountName || ''}
									hasError={validationErrors.accountName} 
									returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{accountName: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Bank Name" 
									inputPlaceholder="Bank name" 
									fieldId="account-number" 
									inputType="text" 
									disabled={false}
									preloadValue={bankDetails.bank || ''}
									hasError={validationErrors.bank} 
									returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{bank: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="Sort Code" 
									inputPlaceholder="Bank Sort Code" 
									fieldId="sort-code" 
									inputType="text" 
									disabled={false}
									preloadValue={bankDetails.sortCode || ''}
									hasError={validationErrors.bank} 
									returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{sortCode: value}})}}
								/>
							</div>
							
							<div className='mt-3 w-full'>
								<TextField
									inputLabel="National Insurance Number" 
									inputPlaceholder="National Insurance Number" 
									fieldId="nin" 
									inputType="text" 
									disabled={false}
									preloadValue={bankDetails.nationalInsuranceNumber || ''}
									hasError={validationErrors.bank} 
									returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{nationalInsuranceNumber: value}})}}
								/>
							</div>

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
									{providedDocuments.length > 0 ? 
										<FormButton 
											buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`-4 h-4 transform -rotate-90`} /></span>} 
											buttonAction={()=>{completeStep5()}} /
										> 
										: 
										<FormButton 
											buttonLabel={<span className='text-sm'>Complete onboarding</span>} processing={processing} buttonAction={()=>{completeOnboarding()}} 
										/>
									}
								</div>
							</div>
						</>}
						
						{activeStep === 6 && providedDocuments.length > 0 && <>
							<p className='text-sm text-gray-500 mt-6'><span className='text-black fon-medium'>STEP 5:</span> Documentation</p>
							<p className='mt-3 dark:text-gray-500 text-sm'>Please upload the following required document(s)</p>
							
							{providedDocuments.map((doc, docIndex)=>(
								<div key={docIndex} className='mt-3 pt-3 border-t border-gray-300 w-full pb-4'>
									<p className='font-[550] text-verovian-purple text-sm'>{doc.name}</p>
									<label className='block mt-2 text-sm text-gray-500'>{doc.description}</label>
									<div className='w-full mb-3'>
										<FileUpload
											hasError={false}
											fieldLabel={doc.documentName}
											returnFileDetails={(details)=>{
												updateDocument(docIndex, 'file', details)
											}}
											acceptedFormats={['pdf', 'jpeg', 'jpg', 'png']}
										/>
									</div>
									{doc.requiresExpiry && <div className='w-full mt-3'>
										<DateField
											inputLabel="Document Expiry Date" 
											inputPlaceholder="" 
											fieldId={`doc-${docIndex}-expiry-date`} 
											inputType="date" 
											hasError={validationErrors[`doc-${docIndex}-expiry-date`]} 
											returnFieldValue={(value) => {updateDocument(docIndex, 'documentExpiry', new Date(value))}}
										/>
									</div>}
									{doc.requiresDocumentNumber && <div className='mt-3'>
										<TextField
											inputLabel="Document Number" 
											inputPlaceholder="Document number, ID or reference" 
											fieldId={`doc-${docIndex}-number`} 
											inputType="text" 
											disabled={false}
											preloadValue={doc.documentNumber || ''}
											hasError={validationErrors[`doc-${docIndex}-number`]} 
											returnFieldValue={(value)=>{updateDocument(docIndex, 'documentNumber', value)}}
										/>
									</div>}
								</div>
							))}

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
									{/* <FormButton 
										buttonLabel={<span className='flex items-center gap-x-2 text-sm'>Continue <ArrowIcon className={`-4 h-4 transform -rotate-90`} /></span>} 
										buttonAction={()=>{completeStep3()}} /> */}
										<FormButton buttonLabel={<span className='text-sm'>Complete onboarding</span>} processing={processing} buttonAction={()=>{completeOnboarding()}} />
								</div>
							</div>
						</>}
						
						{/* {activeStep === 6 && <>
							<p className='text-sm text-gray-500 mt-6'><span className='text-black fon-medium'>STEP 6:</span> Acceptance</p>
							<p className='mt-3 dark:text-gray-500 text-sm'>Please sign off the following on-boarding document(s) to complete your onboarding</p>
							
							{invitationDetails.requiredDocuments.filter(required => required.providedBy === 'admin').map((doc, docIndex)=>(
								<div key={docIndex} className='mt-3 w-full bg-gray-100 bg-opacity-40 p-5 rounded '>
									<p className='font-medium text-verovian-purple mb-2'>{docDetails(doc._id)?.name}</p>
									<p className='text-md text-gray-600 mb-4 text-sm'>{docDetails(doc._id)?.description}</p>

									<button onClick={()=>{readDocument(doc)}} className='mb-3 font-medium text-verovian-purple text-sm underline'>Click to read document</button>
									<Checkbox 
										isChecked={doc.signed}
										checkboxToggleFunction={()=>{updateDocSigning(docIndex, 'signed', !doc.signed)}}
										checkboxLabel='I acknowledge that i have read and understood the provisions of this document'
									/>
									<div className='mt-3 w-full'>
										<TextField
											inputLabel="Your full name" 
											inputPlaceholder="Sign your full name" 
											fieldId={`full-name-${docIndex}`} 
											inputType="full-name" 
											disabled={false}
											preloadValue={''}
											hasError={validationErrors[`full-name-${docIndex}`]} 
											returnFieldValue={(value)=>{updateDocSigning(docIndex, 'signature', value)}}
										/>
									</div>
									<label className='block mt-3 text-xs text-gray-400'>Sign this document by writing your full name above</label>
								</div>
							))}

							<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
								<div className='w-4/12'>
									<button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-3 text-gray-500 text-sm'>
										<ArrowIcon className={`w-4 h-4 transform rotate-90`} /> Go back
									</button>
								</div>
								<div className='w-8/12'>
									<FormButton buttonLabel={<span className='text-sm'>Complete onboarding</span>} processing={processing} buttonAction={()=>{completeOnboarding()}} />
								</div>
							</div>
						</>} */}
					</>
					}
					
				</div>
			</div>

			{/* {activeDocument && <ModalLayout
				isOpen={readingDocument} 
				closeModal={()=>{setReadingDocument(false)}} 
				actionFunction={()=>{}} 
				actionFunctionLabel='Create department' 
				dialogTitle={activeDocument.name}
				maxWidthClass='max-w-4xl'
			>
				<ReadDocument 
					documentId={activeDocument.document} 
					employeeId={invitationDetails.employee._id} />
			</ModalLayout>} */}

		</>
	);
}

export default Signup