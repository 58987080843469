import React from 'react'

const RoadmapIcon = ({className}) => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
    >
        <path
        id="SVGRepo_iconCarrier"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6 21c1.5-1.6 3-3.033 3-4.8S7.657 13 6 13s-3 1.433-3 3.2 1.5 3.2 3 4.8m0 0h11.5a2.5 2.5 0 0 0 0-5H15m3-5c1.5-1.6 3-3.033 3-4.8S19.657 3 18 3s-3 1.433-3 3.2 1.5 3.2 3 4.8m0 0h-3.5a2.5 2.5 0 0 0 0 5h1.1"
        ></path>
    </svg>
  )
}

export default RoadmapIcon