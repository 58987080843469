import React, { useEffect, useState } from 'react'
import { authHeader } from '../../../utils';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import Preloader from '../../elements/Preloader';
import moment from 'moment';
// import Status from '../../elements/Status';
import PlusIcon from '../../elements/icons/PlusIcon';
import ModalLayout from '../../layout/ModalLayout';
import OnboardingPackageSelector from '../onboarding-packages/OnboardingPackageSelector';
import { fetchOnboardingPackages } from '../../../store/actions/onboardingPackagesActions';
import { updateEmployee } from '../../../store/actions/employeeActions';
import EmptyState from '../../elements/icons/EmptyState';
import OnboardingDayItemCard from '../onboarding-packages/OnboardingDayItemCard';


const EmployeeOnboarding = ({employeeDetails}) => {
    const dispatch = useDispatch()
    const [loadingPackage, setLoadingPackage] = useState(true);
    const [onboardingPackage, setOnboardingPackage] = useState(null);
    const onboardingPackagesSelector = useSelector(state => state.onboardingPackages)

    useEffect(() => {
        dispatch(fetchOnboardingPackages(0, 0))
        const getOnboardingPackage = async () => {
            try {
                const headers = authHeader()
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/onboarding-packages/employee/${employeeDetails._id}/${employeeDetails.onboardingPackage}`, { headers })
                setOnboardingPackage(response.data.data)
                setLoadingPackage(false)
            } catch (error) {
                dispatch({
                    type: ERROR,
                    error: error
                })
                setLoadingPackage(false) 
            }
        }
        if(employeeDetails.onboardingPackage && employeeDetails.onboardingPackage !== ''){
            getOnboardingPackage()
        } else {
            setLoadingPackage(false)
        }
        return () => {
            
        };
    }, [dispatch, employeeDetails._id, employeeDetails.onboardingPackage]);

    const [assigningPackage, setAssigningPackage] = useState(false);
    // const [newOnboardingPackage, setNewOnboardingPackage] = useState(null);

    const pushPackageAssignment = (packageId) => {
        console.log(packageId)
        dispatch(updateEmployee(employeeDetails._id, {onboardingPackage: packageId}))
    }

    return (
        <>
            <div>
                <div className='flex items-start justify-between mt-55 mb-4'>
                    <div className='w-8/12'>
                        <p className="text-md mb-1 font-[500]">Employee Onboarding Process</p>
                        <p className='text-sm'>Employee onboarding tasks are displayed below with their statuses</p>
                    </div>
                    <div className='flex flex-row-reverse'>
                        {
                            (!employeeDetails.onboardingPackage || employeeDetails.onboardingPackage === '') &&
                            <button onClick={()=>{setAssigningPackage(true)}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-[16px] py-[10px] text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                <PlusIcon className={`h-5 w-5`} />
                                Assign onboarding package
                            </button>
                        }
                    </div>
                </div>

                <div className='w-full'>
                    {loadingPackage ? 
                        <Preloader />
                        :
                        onboardingPackage !== null ? <>
                            <div className='w-full overflow-x-scroll min-h-[800px] h-inherit border border-verovian-purple/10 rounded-[8px] bg-verovian-light-purple/40 flex flex-shrink-0 items-start gap-x-[20px] px-[20px]'>
                                {onboardingPackage.onboardingDays.map((day, dayIndex) => (
                                    <div key={dayIndex} className='mt-[10px] w-[350px] pt-[20px]'>
                                       <h3 className='uppercase tracking-[0.2em] text-[12px] font-[500] text-verovian-purple mb-[20px]'>Day {dayIndex + 1} - <span className='text-[10px] font-[400] text-gray-500'>{moment(employeeDetails.onboardingDays[dayIndex + 1]).format('ddd, DD MMM YYYY')}</span></h3>
                                        {day.dayItems.map((item, itemIndex) => (
                                            <div key={itemIndex} className='w-[350px]'>
                                                <OnboardingDayItemCard item={item} hideLink={true} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                {/* {onboardingPackage.onboardingDays.map((day, dayIndex) => (
                                    <div key={dayIndex} className='mt-[10px] w-[300px]'>
                                        <h3 className='uppercase tracking-[0.2em] text-[12px] font-[500] text-verovian-purple '>Day {dayIndex + 1}</h3>
                                        
                                    </div>
                                ))} */}
                            </div>
                        </> : 
                            <EmptyState />
                        
                    }
                </div>
            </div>
            <ModalLayout
                isOpen={assigningPackage} 
                closeModal={()=>{setAssigningPackage(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <OnboardingPackageSelector
                    packages={onboardingPackagesSelector.onboardingPackages?.packages}
                    selectedPackageId={employeeDetails.onboardingPackage}
                    saveSelection={(selected)=>{pushPackageAssignment(selected)}}
                    closeModal={()=>{setAssigningPackage(false)}}
                />
            </ModalLayout>
        </>
    )
}

export default EmployeeOnboarding