import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { authHeader } from '../../../utils';
import axios from 'axios';
import { ERROR } from '../../../store/types';
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
import Preloader from '../../elements/Preloader';
import ArrowIcon from '../../elements/icons/ArrowIcon';
import CheckIcon from '../../elements/icons/CheckIcon';

const EmployeeProfileDocumentAssignmentCard = ({assignment}) => {
    const dispatch = useDispatch()
    const [assignedDocument, setAssignedDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchDocument = async () => {    
            try{
                const headers = authHeader()
                let requestUrl = `documents/document/${assignment.document}`
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        
                setAssignedDocument(response.data.data)
                setLoading(false)
            }
            catch(error){
                console.log('employee details error -> ', error)
                dispatch( {
                    type: ERROR,
                    error
                })
            }
        }  

        fetchDocument()
      
        return () => {
            
        };
    }, [assignment.document, dispatch]);
    return (
        <>  
        {loading ? 
            <Preloader />
            :
            <div className='w-full p-5 my-[10px] flex items-start gap-x-3 border bg-white border-transparent hover:border-gray-200 transition duration-200 cursor-pointer'>
                <div className='w-[50px] h-inherit pt-1'>
                    <img src={DocumentSvgIcon} alt="" className='w-[40px]' />
                </div>
                <div className='w-full'>
                    <p className='text-sm font-medium text-verovian-purple capitalize'>{assignedDocument.name}</p>
                    
                    <p className='text-xs text-gray-600 my-[5px]'>{assignment.description.substr(0,75)}{assignedDocument.description.length > 75 && '...'}</p>
                    
                    <p className='text-xs text-gray-600'>{assignedDocument.description.substr(0,75)}{assignedDocument.description.length > 75 && '...'}</p>
                    {assignedDocument.providedBy === 'employee' && !assignment.documentUrl && 
                        <p className='text-xs text-yellow-700 mt-[10px]'>Upload pending</p>
                    }

                    {assignedDocument.providedBy === 'employee' && assignment.documentUrl && assignment.documentUrl !== '' && 
                        <a href={assignment.documentUrl} download target='_blank' className='text-xs text-gray-800 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-[10px]' rel="noreferrer">
                            View/Download Document <ArrowIcon className={`w-4 h-4 -rotate-90`}/>
                        </a>
                    }

                    {assignedDocument.providedBy === 'admin' && assignedDocument.requiresSigning && !assignment.documentSigning && 
                        <p className='text-xs text-yellow-700 mt-[10px]'>Signature pending</p>
                    }

                    {assignedDocument.providedBy === 'admin' && assignedDocument.requiresSigning && assignment.documentSigning && assignment.documentSigning !== '' &&
                        <p className='text-xs text-green-600 mt-[10px] flex items-center gap-x-[5px]'><CheckIcon className={`w-4 h-4`} />Signed by employee</p>
                    }

                    {assignedDocument.providedBy === 'admin' && assignedDocument.requiresDocumentNumber && assignment.documentNumber && assignment.documentNumber !== '' &&
                        <p className='text-xs text-gray-600 mt-[10px] flex items-center gap-x-[5px]'>Doc Number: {assignment.documentNumber}</p>
                    }

                    {assignedDocument.providedBy === 'admin' && assignedDocument.requiresExpiry && assignment.documentExpiry && assignment.documentExpiry !== '' &&
                        <p className='text-xs text-gray-600 mt-[10px] flex items-center gap-x-[5px]'>Expiry: {assignment.documentNumber}</p>
                    }


                </div>
            </div>
        }
        </>
    )
}

export default EmployeeProfileDocumentAssignmentCard