import React, { useState } from 'react'

const RadioGroup = ({items, inputLabel, returnSelected, hasError, inline, preSelectedIndex, disabled}) => {

    const [selectedOption, setSelectedOption] = useState(preSelectedIndex?.toString() || null)

    const selectOption = (index, item) => {
        if(disabled) {
            return
        }
        setSelectedOption(index)
        returnSelected(item)
    }

    return (
        <>
            <label 
            className={`font-montserrat cursor-text bg-transparent text-xs z-10 font-outfit font-medium transition duration-200  
            ${hasError ? 'text-red-600' : 'text-gray-500'}`}>
                {inputLabel}
            </label>
            <div className={`${inline && inline === true && 'flex items-center gap-x-4'}`}>
                {items.map((item, itemIndex)=>(
                <button key={itemIndex} onClick={()=>{selectOption(itemIndex, item)}} className={`text-left cursor-pointer flex items-start gap-x-4 my-1 transition duration-200 rounded border hover:bg-verovian-purple hover:bg-opacity-10 px-4 py-3 ${selectedOption?.toString() === itemIndex?.toString() ? 'border-verovian-purple bg-verovian-purple bg-opacity-10' : 'border-transparent'} ${inline ? 'w-max' : 'w-full'}`}>
                    <div className='w-[25px]'>
                        <div 
                            className={`flex items-center justify-center rounded-full w-[20px] h-[20px] border-4 transition duration-200 cursor-pointer mt-[3px]
                                ${selectedOption?.toString() === itemIndex?.toString() ? 'bg-verovian-purple border-white' : 'bg-transparent border-black'}
                                ${hasError ? 'border-red-600' : 'border-black'}`
                            }
                        />
                    </div>
                    <div>
                        <label className={`text-sm cursor-pointer block ${hasError ? 'text-red-600' : 'text-black'}`}>
                        {item.label}
                        </label>
                        {item.description && <label className={`text-xs cursor-pointer mt-[5px] ${hasError ? 'text-red-500' : 'text-black'}`}>
                            {item.description}
                        </label>}
                    </div>
                </button>
                ))
                }
            </div>
        </>
    )
}

export default RadioGroup