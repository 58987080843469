import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import { selfUpdateEmployee } from '../../../store/actions/employeeActions';
import TextField from '../../elements/form/TextField';
import FormButton from '../../elements/form/FormButton';

const AddressInfoUpdate = ({addressInfo}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const [address, setAddress] = useState(addressInfo || {});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!address.address || address.address === '') {
            errors.address = true
        }
        if(!address.city || address.city === '') {
            errors.city = true
        }
        if(!address.state || address.state === '') {
            errors.state = true
        }
        if(!address.country || address.country === '') {
            errors.country = true
        }

        setValidationErrors(errors)
        return errors
    }

    const update = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        dispatch(selfUpdateEmployee({
            address: address
        }))
    }
    return (
        <div>
            <p className='text-sm mb-3'>Please provide address details below</p>
            <div className='my-4 w-full'>
                <TextField
                inputLabel="Address" 
                fieldId="employee-address" 
                inputType="text" 
                preloadValue={address?.address || ''}
                hasError={validationErrors.address} 
                returnFieldValue={(value)=>{setAddress({...address, ...{address: value}})}}
                />
            </div>

            <div className='w-full my-4'>
                <TextField
                    inputLabel="City" 
                    fieldId="employee-address-city" 
                    inputType="text" 
                    preloadValue={address?.city || ''}
                    hasError={validationErrors.city} 
                    returnFieldValue={(value)=>{setAddress({...address, ...{city: value}})}}
                />
            </div>
            
            <div className='w-full my-4'>
                <TextField
                    inputLabel="Post code" 
                    fieldId="employee-address-postcode" 
                    inputType="text" 
                    preloadValue={address?.postCode || ''}
                    hasError={validationErrors.postCode} 
                    returnFieldValue={(value)=>{setAddress({...address, ...{postCode: value}})}}
                />
            </div>

            <div className='w-full my-4'>
                <TextField
                    inputLabel="State" 
                    fieldId="employee-address-state" 
                    inputType="text" 
                    preloadValue={address?.state || ''}
                    hasError={validationErrors.state} 
                    returnFieldValue={(value)=>{setAddress({...address, ...{state: value}})}}
                />
            </div>

            <div className='w-full my-4'>
                <TextField
                    inputLabel="Country" 
                    fieldId="employee-address-country" 
                    inputType="text" 
                    preloadValue={address?.country || ''}
                    hasError={validationErrors.country} 
                    returnFieldValue={(value)=>{setAddress({...address, ...{country: value}})}}
                />
            </div>

            <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex flex-row-reverse items-center gap-x-4 justify-between'>
                <div className='w-6/12'>
                    <FormButton 
                        buttonLabel='Save Address' 
                        buttonAction={()=>{update()}} 
                        processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddressInfoUpdate