import React, { useState } from 'react'
import FormButton from '../../elements/form/FormButton'
import Checkbox from '../../elements/form/Checkbox'
import TextField from '../../elements/form/TextField';

const DocumentsSelector = ({selectedPackage, selectedDocuments=[], documents, closeModal, saveSelection}) => {

    const [newSelectedDocuments, setNewSelectedDocuments] = useState(selectedDocuments);
    const [displayedDocs, setDisplayedDocs] = useState(documents);

    const updateAndClose = () => {
        saveSelection(newSelectedDocuments)
        closeModal()
    }

    const docInPackage = (documentId) => {
        if (!selectedPackage || !selectedPackage.onboardingDays || !Array.isArray(selectedPackage.onboardingDays)) {
            return false
        }
    
        for (const day of selectedPackage.onboardingDays) {
            if (day.dayItems && Array.isArray(day.dayItems)) {
                for (const item of day.dayItems) {
                    if (item.item === documentId) {
                        return true;
                    }
                }
            }
        }
    
        return false;
    }

    const toggleDocumentSelection = (doc) => {

        if(docInPackage(doc)) return

        let temp = [...newSelectedDocuments]
        if(temp.includes(doc)){
            temp = temp.filter(item => item !== doc)
        } else {
            temp.push(doc)
        }
        setNewSelectedDocuments(temp)
    }

    const searchForDocument = (searchTerm) => {
        if(searchTerm.length === 0) {
            clearSearch()
        }
        if(!searchTerm || searchTerm === '') {
            return
        }

        if (!Array.isArray(documents) || typeof searchTerm !== 'string') {
            setDisplayedDocs([])
        }
    
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
        const results = documents.filter(doc => {
            const nameIncludes = doc.name && doc.name.toLowerCase().includes(lowerCaseSearchTerm);
            const descriptionIncludes = doc.description && doc.description.toLowerCase().includes(lowerCaseSearchTerm);
            return nameIncludes || descriptionIncludes;
        });

        setDisplayedDocs(results)
    }

    const clearSearch = () => {
        setDisplayedDocs(documents)
    }

    return (
        <div className='w-full'>
            <div className='w-full mb-4'>
                <TextField
                    inputLabel="Search for a Document" 
                    fieldId="doc-search-term" 
                    inputType="text" 
                    preloadValue={''}
                    inputPlaceholder={`Document name or part of description`}
                    hasError={false} 
                    returnFieldValue={(value)=>{searchForDocument(value)}}
                />
            </div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
                {displayedDocs.map((doc, docIndex) => (
                    <div key={docIndex} className={`w-full border rounded flex items-start transition duration-200 gap-x-2 mb-2 p-[7px] border-transparent cursor-pointer ${newSelectedDocuments?.includes(doc._id) && 'bg-verovian-light-purple border-verovian-purple bg-opacity-30'}`} onClick={(()=>{toggleDocumentSelection(doc._id)})}>
                        <div className='w-[25px] pt-[5px]'>
                            <Checkbox 
                                isChecked={newSelectedDocuments?.includes(doc._id)}
                                checkboxToggleFunction={()=>{toggleDocumentSelection(doc._id)}}
                                checkboxLabel=''
                                hasError={docInPackage(doc._id)}
                            />
                        </div>
                        <div className='w-full relative'>
                            {docInPackage(doc._id)&&<p className="text-xs text-red-600 font-[500] absolute top-0 right-0">Already assigned in onboarding package</p>}
                            <p className='text-[13px] font-medium text-verovian-purple'>{doc.name}</p>
                            <p className='text-xs text-gray-500 mt-[5px]'>{doc.description.substr(0, 100)}{doc.description.length > 100 && '...'}</p>
                            <div className='mt-[5px]'>
                                <span className={`rounded p-[5px] border text-xs mr-[5px] ${doc.providedBy === 'admin' ? 'bg-purple-100 bg-opacity-50 border-purple-300 text-verovian-purple' : 'bg-green-100 text-green-700 border-green-300 bg-opacity-50'}`}>Provided by {doc.providedBy}</span>
                                {doc.requiresSigning && <span className='rounded p-[5px] border text-xs mr-[5px] bg-red-100 bg-opacity-50 text-red-700 border-red-300'>Requires Signature</span>}
                                {doc.requiresExpiry && <span className='rounded p-[5px] border text-xs mr-[5px] bg-blue-100 bg-opacity-50 text-blue-600 border-blue-300'>Has Expiry</span>}
                                {doc.requiresDocumentNumber && <span className='rounded p-[5px] border text-xs mr-[5px] bg-gray-100 text-gray-700 border-gray-300'>Has Document Number</span>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='flex flex-row-reverse mt-[20px]'>
                <div className='w-max'>
                    <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                </div>
            </div>
        </div>
    )
}

export default DocumentsSelector