

const initialState = {
    assignments: [],
    loadingAssignments: false,
    categories: [],
    loadingCategories: false,
    items: [],
    loadingItems: false,
    variants: [],
    loadingVariants: false,
    inventoryError: null,
    creatingItem: false,
    createdVariant: null,
    creatingAssignment: false,
    createdAssignment: null,
    creatingCategory: false,
    createdCategory: null,
    updatingItem: false,
    updatedVariant: null,
    updatingAssignment: false,
    updatedAssignment: null,
    updatingCategory: false,
    updatedCategory: null,
    updatingInstructor: false,
    updatedInstructor: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        // case GETTING_INSTRUCTORS:
        // return {
        //     ...state,
        //     loadingInstructors: action.payload,
        //     // fetchingMembers:false
        // }
        // case GET_INSTRUCTORS:
        // return{
        //     ...state,
        //     loadingInstructors: false,
        //     instructorsError: false,
        //     instructors: action.payload,
        // }
        // case CREATING_INSTRUCTOR:
        // return {
        //     ...state,
        //     creatingInstructor: action.payload,
        //     // fetchingMembers:false
        // }
        // case CREATE_INSTRUCTOR:
        // return{
        //     ...state,
        //     creatingInstructor:false,
        //     instructorsError: false,
        //     createdInstructor: action.payload,
        // }
        // case UPDATING_INSTRUCTOR:
        // return {
        //     ...state,
        //     updatingInstructor: action.payload,
        //     // fetchingMembers:false
        // }
        // case UPDATE_INSTRUCTOR:
        // return{
        //     ...state,
        //     updatingInstructors:false,
        //     instructorsError: false,
        //     updatedInstructors: action.payload,
        // }
        // case INSTRUCTORS_ERROR:
        // return{
        //     ...state,
        //     loadingInstructors:false,
        //     creatingInstructor:false,
        //     updatingInstructor:false,
        //     instructorsError: action.payload 
        // }
        default: return state
    }

}