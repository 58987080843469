import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import Wysiwyg from '../../../components/elements/form/Wysiwyg'
import TextField from '../../../components/elements/form/TextField'
import FormButton from '../../../components/elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../store/types'
import { clearCreatedDocument, createDocument } from '../../../store/actions/documentActions'
import CopyToClipboard from 'react-copy-to-clipboard'
import CopyIcon from '../../../components/elements/icons/CopyIcon'
import { useNavigate } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import RadioGroup from '../../../components/elements/form/RadioGroup'
import FileUpload from '../../../components/elements/form/FileUpload'

const NewDocument = () => {
    const navigate = useNavigate()
    const [validationErrors, setValidationErrors] = useState({});
    const [documentPayload, setDocumentPayload] = useState({
      requiresSigning: false,
      requiresExpiry: false,
      requiresDocumentNumber: false
    });
    
    const dispatch = useDispatch()
    const documentsSelector = useSelector(state => state.documents)

    useEffect(() => {
      if(documentsSelector.createdDocument !== null){
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: {successMessage: "Document created successfully"}
        })
        dispatch(clearCreatedDocument())
        setDocumentPayload({})
        navigate('/user/documents')
      }
      return () => {
        
      };
    }, [dispatch, navigate, documentsSelector.createdDocument]);

    const validateForm = () => {
      let errors = {}
      if (!documentPayload.name || documentPayload.name === '') {
        errors.name = true
      }
      if (!documentPayload.description || documentPayload.description === '') {
        errors.description = true
      }
      if (!documentPayload.providedBy || documentPayload.providedBy === '') {
        errors.provider = true
      }
      if (documentPayload.providedBy === 'admin' && (!documentPayload.documentBody || documentPayload.documentBody === '')) {
        errors.documentBody = true
      }
      if(requiresHeaderImage && (!file || file === '')){
        errors.file = true
      }

      setValidationErrors(errors)
      return errors
    }

    const [requiresHeaderImage, setRequiresHeaderImage] = useState(false);
    const [file, setFile] = useState();

    const triggerCreateDocument = async () => {
      if (Object.values(validateForm()).includes(true)) {
        dispatch({
            type: ERROR,
            error: {response: {data: {
                message: 'Please check the highlighted fields'
            }}}
        });
        return
      }

      const payload = documentPayload

      if(file) {
        const uploaded = await handleUpload(file)
        payload.documentHeaderImage = uploaded.data.file
      }

      dispatch(createDocument(payload))
    }

    const handleUpload = async (file) => {
      console.log(file)
  
      var formData = new FormData()
      formData.append('file', file.file )
      const headers = new Headers();
  
      try {
    
        const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
          method: "POST",
          headers,
          body: formData,
        });
        const response = await doUpload.json();
    
        return response
      } catch (error) {
        dispatch({
          type: ERROR,
          error,
        });
      }
    }

    const documentVariables = [
      {
        value: '[[firstName]]',
        description: 'Copy and paste this to insert employee first name'
      },
      {
        value: '[[middleName]]',
        description: 'Copy and paste this to insert employee middle name'
      },
      {
        value: '[[lastName]]',
        description: 'Copy and paste this to insert employee last name'
      },
      {
        value: '[[email]]',
        description: 'Copy and paste this to insert employee email address'
      },
      {
        value: '[[phone]]',
        description: 'Copy and paste this to insert employee phone number'
      },
      {
        value: '[[dateOfBirth]]',
        description: 'Copy and paste this to insert employee date of birth'
      },
      {
        value: '[[startDate]]',
        description: 'Copy and paste this to insert employee start date'
      },
      {
        value: '[[address]]',
        description: 'Copy and paste this to insert employee address'
      },
      {
        value: '[[signature]]',
        description: 'Copy and paste this to insert employee signature when document is signed'
      },
    ]

    const documentProvisionOptions = [
      {
        label: 'Administrator', 
        value: 'admin',
        description: 'The document will be created by an administrator and provided to the employee for their action/attention'
      },
      {
        label: 'Employee', 
        value: 'employee',
        description: 'The document will be provided by the employee at the request of the administrator.'
      }
    ]

    return (
      <UserLayout pageTitle={`New document`}>
        <div className=''>
          <div className='2xl:w-11/12 mx-auto lg:flex flex-row gap-x-8'>
            <div className='w-full pb-6 mx-auto bg-white p-8 mb-12 rounded-[8px]'>
              <div className='flex justify-between items-center mb-4'>
                <h3 className='font-medium text-lg text-gray-400'>Create a new document </h3>
              </div>

              <div className='flex items-start gap-x-[50px]'>
                <div className='w-7/12 mx-auto'>
                  <div className='w-full'>
                    {/* <div className='flex items-center justify-between gap-x-8 px-6'> */}
                      <div className='w-full'>
                        <div className='mb-4 w-full'>
                          <TextField
                            inputLabel="Document name" 
                            fieldId="document-name" 
                            inputType="text" 
                            preloadValue={documentPayload.name || ''}
                            hasError={validationErrors.name} 
                            returnFieldValue={(value)=>{setDocumentPayload({...documentPayload, ...{name: value}})}}
                          />
                        </div>          
                      </div>
                      <div className='w-full'>
                        <div className='mb-4 w-full'>
                          <TextField
                            inputLabel="Description" 
                            fieldId="document-description" 
                            inputType="text" 
                            preloadValue={documentPayload.description || ''}
                            hasError={validationErrors.description} 
                            returnFieldValue={(value)=>{setDocumentPayload({...documentPayload, ...{description: value}})}}
                          />
                        </div>                
                      </div>
                    {/* </div> */}
                    <div  className='w-full'>
                        <p className="text-sm font-[500] text-gray-600">
                            Who provides this document?
                        </p>
                        <p className='text-xs text-gray-700 mt-2'>Select who is responsible for creating the contents of this document</p>
                    </div>

                    <div className='w-full'>
                      <div className='mb-4 w-full'>
                        <RadioGroup
                          inputLabel="Provider"
                          items={documentProvisionOptions} 
                          inline={false}
                          hasError={validationErrors.provider} 
                          returnSelected={(value)=>{setDocumentPayload({...documentPayload, ...{providedBy: value.value}})}}
                        />
                      </div>
                    </div>

                    <div className='w-full pt-4 pb-4 flex gap-x-4 items-start justify-between'>
                      <div  className='w-full'>
                        <p className="text-sm font-[500] text-gray-600">
                            Requires Expiry Date
                        </p>
                        <p className='text-xs text-gray-700 mt-2'>Will the provider of this document be required to provide an expiry date for it?</p>
                      </div>
                      <div className='w-24 flex flex-row-reverse pt-2'>
                        <Switch
                          checked={documentPayload.requiresExpiry}
                          onChange={()=>{setDocumentPayload({...documentPayload, ...{requiresExpiry: !documentPayload.requiresExpiry}})}}
                          className={`${
                              documentPayload.requiresExpiry ? 'bg-verovian-purple' : 'bg-gray-200'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          {/* <span className="sr-only">Display stock levels</span> */}
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                              documentPayload.requiresExpiry ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                          />
                        </Switch>
                      </div>
                    </div>

                    <div className='w-full pt-4 pb-4 flex gap-x-4 items-start justify-between'>
                      <div className='w-full'>
                        <p className="text-sm font-[500] text-gray-600">
                            Requires Document Number
                        </p>
                        <p className='text-xs text-gray-700 mt-2'>Will the provider of this document be required to provide a document number?</p>
                      </div>
                      <div className='w-24 flex flex-row-reverse pt-2'>
                        <Switch
                          checked={documentPayload.requiresExpiry}
                          onChange={()=>{setDocumentPayload({...documentPayload, ...{requiresDocumentNumber: !documentPayload.requiresDocumentNumber}})}}
                          className={`${
                              documentPayload.requiresDocumentNumber ? 'bg-verovian-purple' : 'bg-gray-200'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          {/* <span className="sr-only">Display stock levels</span> */}
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                              documentPayload.requiresDocumentNumber ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                          />
                        </Switch>
                      </div>
                    </div>            

                    {documentPayload.providedBy === 'admin' && <div className='w-full pt-4 pb-6 flex gap-x-4 items-start justify-between'>
                      <div className='w-full'>
                        <p className="text-sm font-[500] text-gray-600">
                            Requires signing
                        </p>
                        <p className='text-xs text-gray-700 mt-2'>Flip this toggle if the employee is required to sign this document</p>
                      </div>
                      <div className='w-24 flex flex-row-reverse pt-2'>
                        <Switch
                          checked={documentPayload.requiresSigning}
                          onChange={()=>{setDocumentPayload({...documentPayload, ...{requiresSigning: !documentPayload.requiresSigning}})}}
                          className={`${
                              documentPayload.requiresSigning ? 'bg-verovian-purple' : 'bg-gray-200'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          {/* <span className="sr-only">Display stock levels</span> */}
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                              documentPayload.requiresSigning ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                          />
                        </Switch>
                      </div>
                    </div>}
                  
                  </div>
                  {documentPayload.providedBy === 'admin' && <>
                    <div className='w-full pb-4'>
                      <label 
                        className={`font-montserrat cursor-text bg-transparent text-sm z-10 font-outfit font-medium transition duration-200  
                        ${validationErrors.documentBody ? 'text-red-600' : 'text-gray-500'}`}
                      >
                          Document body
                      </label>
                      <label className='text-xs block mt-2'>Paste and format (or type in the contents of your document) in the editor below</label>
                    </div>

                    <div className='w-full'>
                      <div className='borderless long-text'>
                        <Wysiwyg 
                          fieldTitle=""
                          initialValue={''}
                          updateValue={(value)=>{setDocumentPayload({...documentPayload, ...{documentBody: value}})}}
                        />
                      </div>
                    </div>
                  </>
                  }
                </div>

                {documentPayload.providedBy === 'admin' && <div className='w-5/12'>
                  {<div className='w-full pt-4 pb-6 flex gap-x-4 items-start justify-between'>
                    <div  className='w-full'>
                      <p className="text-sm font-[500] text-gray-600">
                          Requires header image
                      </p>
                      <p className='text-xs text-gray-700 mt-2'>A header image will be placed at the top of the document serving as a letterhead</p>
                    </div>
                    <div className='w-24 flex flex-row-reverse pt-2'>
                      <Switch
                        checked={requiresHeaderImage}
                        onChange={()=>{setRequiresHeaderImage(!requiresHeaderImage)}}
                        className={`${
                            requiresHeaderImage ? 'bg-verovian-purple' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        {/* <span className="sr-only">Display stock levels</span> */}
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                            requiresHeaderImage ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                      </Switch>
                    </div>
                  </div>}
                  {documentPayload.providedBy === 'admin' && requiresHeaderImage && <div className='w-full pb-4'>
                    <FileUpload
                      hasError={false}
                      fieldLabel={`Document Header Image`}
                      returnFileDetails={(details)=>{
                        setFile(details)
                      }}
                      acceptedFormats={['jpeg', 'jpg', 'png']}
                    />
                    <label className='block mt-3 text-xs text-gray-600 leading-[1.4em]'>{`The image provided will be displayed on the header of the document as a letterhead, for best results, please use images with a minimum width of 2,480px and a maximum height of 300px`}</label>
                  </div>}
                  <div className='w-full pb-4 bg-gray-100 p-[30px] border border-gray-300 rounded-[8px]'>
                      <label 
                      className={`font-montserrat cursor-text bg-transparent text-sm z-10 font-outfit font-medium transition duration-200  
                      text-gray-500`}>
                          Document variables
                      </label>
                      <label className='text-xs block mt-2'>Document variables are placeholders for live employee data. The following list describes the useable variables. Just copy and paste them at the position where you want them replaced in the document</label>

                      {documentVariables.map((variable, variableIndex) => (
                        <div key={variableIndex} className='flex items-start gap-x-1 my-4'>
                          <CopyToClipboard text={variable.value}
                            onCopy={() =>  dispatch({
                                type: SET_SUCCESS_MESSAGE,
                                payload: {
                                  successMessage: `${variable.value} copied to clipboard`
                                }
                            })}>
                            <button className='pt-1'><CopyIcon className={`w-5 h-5 text-gray-500`}/></button>
                          </CopyToClipboard>
                          <p className='text-xs leading-[20px]'><span className='p-1 bg-gray-200 items-center gap-x-1 inline'>
                          {variable.value}</span> : {variable.description}
                          </p>
                        </div>
                      ))}
                  </div>       
                </div>}
              </div>

              <div className='my-8 flex items-center justify-between pt-8 border-t'>
                <div className='w-3/12'>
                <FormButton buttonLabel={`Save document`} buttonAction={()=>{triggerCreateDocument()}} processing={documentsSelector.creatingDocument} />
                </div>
              </div>


            </div>
          </div>
        </div>
      </UserLayout>
    )
}

export default NewDocument