import React, { useState } from 'react'
import TextField from '../../elements/form/TextField'
import FormButton from '../../elements/form/FormButton';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import { selfUpdateEmployee } from '../../../store/actions/employeeActions';

const ContactInfoUpdate = ({contactInfo, reload}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const [contactDetails, setContactDetails] = useState(contactInfo || {});

    const updateSocialMedia = (field, value) => {
        let temp = {...contactDetails}
        temp.socialMedia[field] = value
        setContactDetails(temp)
    }

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!contactDetails.email || contactDetails.email === '') {
            errors.email = true
        }
        if(!contactDetails.phone || contactDetails.phone === '') {
            errors.phone = true
        }

        setValidationErrors(errors)
        return errors
    }

    const update = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        dispatch(selfUpdateEmployee(contactDetails))
    }

    return (
        <div>
            <p className='mt-4 text-gray-600 text-sm mb-2'>Provide your contact details and links to your social media profiles below if available.</p>
                                
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Email" 
                    inputPlaceholder="Your email address" 
                    fieldId="linkedin" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.email || ''}
                    hasError={validationErrors.email} 
                    returnFieldValue={(value)=>{setContactDetails({...contactDetails, ...{email: value}})}}
                />
            </div>
                                
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Phone number" 
                    inputPlaceholder="Paste linkedin profile url" 
                    fieldId="linkedin" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.phone || ''}
                    hasError={validationErrors.phone} 
                    returnFieldValue={(value)=>{setContactDetails({...contactDetails, ...{phone: value}})}}
                />
            </div>
                                
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Linkedin" 
                    inputPlaceholder="Paste linkedin profile url" 
                    fieldId="linkedin" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.socialMedia?.linkedin || ''}
                    hasError={false} 
                    returnFieldValue={(value)=>{updateSocialMedia('linkedin', value)}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Twitter/X" 
                    inputPlaceholder="Paste twitter/X profile url" 
                    fieldId="twitter" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.socialMedia?.twitter || ''}
                    hasError={false} 
                    returnFieldValue={(value)=>{updateSocialMedia('twitter', value)}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Facebook" 
                    inputPlaceholder="Paste facebook profile url" 
                    fieldId="facebook" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.socialMedia?.facebook || ''}
                    hasError={false} 
                    returnFieldValue={(value)=>{updateSocialMedia('facebook', value)}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Instagram" 
                    inputPlaceholder="Paste instagram profile url" 
                    fieldId="instagram" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={contactDetails?.socialMedia?.instagram || ''}
                    hasError={false} 
                    returnFieldValue={(value)=>{updateSocialMedia('instagram', value)}}
                />
            </div>

            <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex items-center gap-x-4 justify-between'>
                <div className='w-6/12'>
                    <FormButton 
                        buttonLabel='Save Contact Details' 
                        buttonAction={()=>{update()}} 
                        processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContactInfoUpdate