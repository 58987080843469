import React from 'react'
import ArrowIcon from '../../../elements/icons/ArrowIcon'
import { Link } from 'react-router-dom'

const TrainingCard = ({training}) => {
    const departmentNames = training.departments && training.departments.length > 0 ? training.departments.map(dept => {return dept.name}).join(', ') : 'General'
    
    const trainingEstimatedTime = training.modules.reduce((accumulator, current) => accumulator+current.module.estimatedTime, 0)
    
    return (
        <div className={`cursor-pointer w-full`}>
                <div className='rounded flex items-center justify-center bg-gray-100 w-full h-[250px]' 
                    style={{
                        backgroundImage: `url(${training.coverImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }} 
                >
            </div>
            <div className='w-full p-[20px]'>
                <p className='text-sm text-gray-700 font-medium'>{training?.title}</p>
                <div className='my-1'>
                    <p className='text-xs'>{training?.description?.substr(0, 200)}{training?.description?.length > 200 && '...'}</p>
                </div>
                <p className='text-xs text-gray-500 mt-[10px]'>{training.modules.length} modules <span className='text-xs text-gray-500'>({trainingEstimatedTime} minutes)</span></p>

                <div className='mt-[10px]'>
                    <p className='text-xs font-[500] mb-[5px] uppercase tracking-[0.1em]'>Departments</p>
                    <p className='text-xs text-gray-500'>
                        {departmentNames}
                    </p>
                </div>

                <Link to={`/user/trainings/training/${training._id}`}  className='text-sm text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>
            </div>
        </div>
    )
}

export default TrainingCard