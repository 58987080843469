import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearCreatedTraining, createEmployeeTraining, getEmployeeTrainings, getTrainings } from '../../../store/actions/trainingsActions';
import EmptyState from '../../elements/icons/EmptyState';
import PlusIcon from '../../elements/icons/PlusIcon';
import EmployeeTrainingCard from '../trainings/trainings/EmployeeTrainingCard';
import ModalLayout from '../../layout/ModalLayout';
import AutocompleteSelect from '../../elements/form/AutocompleteSelect';
import TextareaField from '../../elements/form/TextareaField';
import DateField from '../../elements/form/DateField';
import moment from 'moment';
import FormButton from '../../elements/form/FormButton';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../store/types';
// import TrainingsPicker from '../trainings/trainings/TrainingsPicker';

const EmployeeTrainings = ({employeeDetails}) => {
  const trainingsSelector = useSelector(state => state.trainings)
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getEmployeeTrainings(0, 0, employeeDetails._id))
    dispatch(getTrainings(0, 0))
    if(trainingsSelector.createdTraining !== null){
      dispatch(clearCreatedTraining())
      setAssigningTraining(false)
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: 'Training assigned successfully'
      })
    }
    return () => {
        
    };
  }, [dispatch, employeeDetails._id, trainingsSelector.createdTraining]);

  const [assigningTraining, setAssigningTraining] = useState(false);
  const [assignmentPayload, setAssignmentPayload] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const validateAssignmentForm = () => {
    let errors = {}
    if (!assignmentPayload.training || assignmentPayload.training === '') {
        errors.training = true
    }

    setValidationErrors(errors)
    return errors
  }

  const pushTrainingAssignment = () => {
    if (Object.values(validateAssignmentForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      });
      return
    }

    const payload = {
      employee: employeeDetails._id,
      training: assignmentPayload.training,
    }

    if(assignmentPayload.startDate) {
      payload.timeLimits.startDate = assignmentPayload.startDate
    }

    if(assignmentPayload.startDate) {
      payload.timeLimits.endDate = assignmentPayload.endDate
    }

    dispatch(createEmployeeTraining(payload))
  }

  return (
    <>
      <div>
        <div className='flex items-start justify-between mt-55 mb-12'>
          <div className='w-8/12'>
            <p className="text-md mb-1 font-[500]">Employee Trainings</p>
            <p className='text-sm'>Please see trainings assigned to this employee below with their progress on each training</p>
          </div>
            <button onClick={()=>{setAssigningTraining(true)}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-[16px] py-[10px] text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
            <PlusIcon className={`h-5 w-5`} />
            Assign new Training
            </button>
        </div>

        {trainingsSelector?.employeeTrainings?.employeeTrainings?.length > 0 ?
          <>                                                            
            <div className='grid grid-cols-2 gap-6'>
                {trainingsSelector?.employeeTrainings?.employeeTrainings?.map((assignment, assignmentIndex)=>(
                <div key={assignmentIndex} className="bg-white">
                    <EmployeeTrainingCard assignment={assignment} hideLink={true} />
                </div>
                ))}
            </div>
          </>
          :
          <EmptyState emptyStateText={`No trainings assigned to this employee yet.`} />
        }
      </div>

      <ModalLayout
        isOpen={assigningTraining} 
        closeModal={()=>{setAssigningTraining(false)}} 
        actionFunction={()=>{}} 
        actionFunctionLabel='' 
        dialogTitle={`Assign training to ${employeeDetails.firstName}`}
        maxWidthClass='max-w-2xl'
      >
        <div className='w-full'>
          <div className='w-full py-1'>
            {trainingsSelector?.trainings?.trainings?.length > 0 ? 
              <AutocompleteSelect
                  selectOptions={trainingsSelector?.trainings?.trainings}
                  inputLabel="Select training"
                  titleField="title"
                  displayImage={false}
                  imageField=""
                  preSelected=''
                  fieldId="task-review"
                  hasError={validationErrors.training}
                  // return id of accounts of the selected option
                  returnFieldValue={(value) => {setAssignmentPayload({
                    ...assignmentPayload, 
                    ...{training: value._id}
                  })}}
              />
              : 
              <label className='block p-[8px] rounded text-red-600 border border-red-600 bg-red-50 text-xs my-[15px]'>No trainings created, create some first by navigating to "Training & knowledge &gt; Trainings" on the sidebar</label> 
            }
          </div>

          <div className='my-3 w-full'>
            <TextareaField
              inputLabel="Description" 
              fieldId="dept-description" 
              inputType="text" 
              preloadValue={assignmentPayload.description || ''}
              hasError={false} 
              height={'120px'}
              returnFieldValue={(value)=>{setAssignmentPayload({...assignmentPayload, ...{description: value}})}}
            />
          </div>

          <div className='my-3 w-full'>
            <p className='text-sm font-[500] text-gray-600'>Training timeline</p>
            <p className='text-xs text-gray-500 mb-2'>Set time limits (if required) for the employee to complete the training</p>
            <div className='flex items-start justify-between gap-x-[10px]'>
              <DateField
                inputLabel="Start Date" 
                inputPlaceholder="" 
                fieldId={`start-date`} 
                inputType="date" 
                hasError={false} 
                returnFieldValue={(value) => {setAssignmentPayload({
                    ...assignmentPayload, 
                    ...{
                      startDate: moment(value).format('DD-MM-YYYY')
                    }
                })}}
              />
              <DateField
                inputLabel="End Date" 
                inputPlaceholder="" 
                fieldId={`end-date`} 
                inputType="date" 
                hasError={false} 
                returnFieldValue={(value) => {setAssignmentPayload({
                    ...assignmentPayload, 
                    ...{
                      endDate: moment(value).format('DD-MM-YYYY')
                    }
                })}}
              />
            </div>
          </div>

          <div className='my-8 flex flex-row-reverse items-center justify-between'>
            <div className='w-full'>
              <FormButton 
                  buttonLabel={`Assign Training`} 
                  buttonAction={()=>{pushTrainingAssignment()}} 
                  processing={trainingsSelector.creatingTraining}
              />
            </div>
          </div>

        </div>
      </ModalLayout>
    </>
  )
}

export default EmployeeTrainings