import React, { useEffect, useState } from 'react'
import UserIcon from '../../elements/icons/UserIcon'
import Status from '../../../components/elements/Status'
import EmptyState from '../../elements/icons/EmptyState'
import moment from 'moment'
import PencilSquareIcon from '../../elements/icons/PencilSquareIcon'
import { userDetails } from '../../../utils'
import ModalLayout from '../../layout/ModalLayout'
import ContactInfoUpdate from './ContactInfoUpdate'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../store/types'
import { clearUpdatedEmployee, selfUpdateEmployee } from '../../../store/actions/employeeActions'
import AddressInfoUpdate from './AddressInfoUpdate'
import NextOfKinUpdate from './NextOfKinUpdate'
import EmergencyContactUpdate from './EmergencyContactUpdate'
import FileUpload from '../../elements/form/FileUpload'
import InlinePreloader from '../../elements/InlinePreloader'

const EmployeeProfile = ({employeeDetails, reload}) => {
  const [updatingContactDetails, setUpdatingContactDetails] = useState(false);
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const [updatingNextOfKin, setUpdatingNextOfKin] = useState(false);
  const [updatingEmergencyContact, setUpdatingEmergencyContact] = useState(false);
  const [changingPhoto, setChangingPhoto] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null)

  const handleUpload = async (file) => {
    console.log(file)

    var formData = new FormData()
    formData.append('file', file.file )
    const headers = new Headers();

    try {
  
      const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
        method: "POST",
        headers,
        body: formData,
      });
      const response = await doUpload.json();
  
      return response
    } catch (error) {
      dispatch({
        type: ERROR,
        error,
      });
    }
  }

  const dispatch = useDispatch()
  const employeesSelector = useSelector(state => state.employees)

  useEffect(() => {
    if(employeesSelector.updatedEmployee !== null){
      setUpdatingContactDetails(false);
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: {successMessage: 'Profile updated successfully!'}
      })
      dispatch(clearUpdatedEmployee())
      reload()
    }
    return () => {
      
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employeesSelector.updatedEmployee]);

  const updateAvatar = async () => {
    if(!avatarFile) return
    const uploaded = await handleUpload(avatarFile)
    const payload = {avatar: uploaded.data.file}
  
    dispatch(selfUpdateEmployee(payload))
  }

  return (
    <>
      <div className='w-full'>
          <div className='w-full flex items-start gap-x-[30px]'>
            <div className="w-[300px]">
              {changingPhoto ? 
                <div className='w-full mb-4'>
                  <FileUpload
                    hasError={false}
                    fieldLabel={`Instructor avatar`}
                    returnFileDetails={(details)=>{
                      setAvatarFile(details)
                    }}
                    acceptedFormats={['jpeg', 'jpg', 'png']}
                  />
                  <label className='block mt-3 text-xs text-gray-400'>Upload your photo</label>

                  {avatarFile && <button onClick={()=>{updateAvatar()}} className='w-full flex items-center justify-center p-[10px] rounded mt-[10px] text-xs bg-black text-white'>
                    {employeesSelector.updatingEmployee ? <InlinePreloader /> : 'Save Photo'}
                  </button>}
                </div>
              : 
              <div className='w-full'>
                <div className='w-full h-[250px] bg-gray-100 flex items-center justify-center rounded-[8px]' style={{
                  backgroundImage: `url(${employeeDetails.avatar})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }} >
                    {(!employeeDetails.avatar || employeeDetails.avatar === '') && <UserIcon className={`w-8 h-8 text-gray-300`} />}
                </div>
              </div>}
              <div className="mt-3 w-max">
                <button onClick={()=>{setChangingPhoto(!changingPhoto)}} className='text-xs text-verovian-purple transition duration-200 hover:text-gray-400'>Change photo</button>
              </div>
              <div className="mt-3 w-max">
                <Status status={employeeDetails.userProfile ? 'profiled' : 'not-profiled'} />
              </div>
            </div>
            <div className="w-full">
              <p className="text-sm mb-5">EMPLOYEE ID: <span className="font-[500]">{employeeDetails.employeeId}</span></p>
              <div className="w-full grid grid-cols-2 gap-[20px]">
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>first name</label>
                  <p className="text-sm">{employeeDetails.firstName}</p>
                </div>
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>middle name</label>
                  <p className="text-sm">{employeeDetails.middleName}</p>
                </div>
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>last name</label>
                  <p className="text-sm">{employeeDetails.lastName}</p>
                </div>
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>gender</label>
                  <p className="text-sm">{employeeDetails.gender}</p>
                </div>
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Pronouns</label>
                  <p className="text-sm">{employeeDetails.pronouns || <span className='text-gray-300 text-xs'>Not provided yet</span>}</p>
                </div>
                {/* <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>email</label>
                  <p className="text-sm">{employeeDetails.email}</p>
                </div>
                <div className="w-full">
                  <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>phone number</label>
                  <p className="text-sm">{employeeDetails.phone}</p>
                </div> */}
              </div>

              {/* <div className='w-full mt-6'>
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Address</label>
                <p className='text-sm'>123, verovian avenue, off some other street</p>
                <p className='text-sm'>Zone 2, Wuse - Abuja</p>
              </div> */}

              <div className='w-full mt-6'>
                <label className='block text-sm text-verovian-purple uppercase tracking-[0.2em] mb-2 font-[500]'>education</label>
                {employeeDetails?.education?.length > 0 ? <div className='grid grid-cols-2'>
                  {employeeDetails.education.map((education, educationIndex)=>(
                    <div key={educationIndex} className="w-full bg-white">
                      <p className="text-sm font-[500]">{education.institution}</p>
                      <p className="text-xs font-[400] mt-1">{education.type}</p>
                      <p className='text-xs text-gray-500 mt-1'>{moment(education.startDate).format('MMM YYYY')} - {moment(education.endDate).format('MMM YYYY')}</p>
                    </div>
                  ))}
                </div> :
                  <EmptyState emptyStateText={`No education data provided for this employee yet`} />
                }

              </div>
            </div>
          </div>

          {/* Contact information */}
          <div className='w-full mt-6'>
            <div className='flex items-center justify-between bg-verovian-light-purple p-[5px]'>
              <h3 className=' text-verovian-purple uppercase font-[500]'>Contact information</h3>
              {employeeDetails._id === userDetails().employeeProfile && <button onClick={()=>{setUpdatingContactDetails(true)}} className='flex items-center justify-center gap-x-[5px] text-gray-500 text-xs hover:text-gray-700 font-[500]'>
                <PencilSquareIcon className={`w-4 h-4`} />
                Edit Contact Information
              </button>}
            </div>

            {/* <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>PHone</label>
                <p className="text-sm">{employeeDetails.phone}</p>
              </div>
            </div> */}
            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Phone number</label>
                <p className="text-sm">{employeeDetails.phone}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Email address</label>
                <p className="text-sm">{employeeDetails.email}</p>
              </div>
            </div>
            <p className='font-[500] mt-6'>Social Media</p>
            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Twitter</label>
                {employeeDetails.socialMedia?.twitter ? <a href={employeeDetails.socialMedia?.twitter} className="text-sm text-blue-600">Go to twitter profile</a> : <span className='text-gray-300 text-xs'>Not provided yet</span>}
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Linkedin</label>
                {employeeDetails.socialMedia?.linkedin ? <a href={employeeDetails.socialMedia?.linkedin} className="text-sm text-blue-600">Go to linkedin profile</a> : <span className='text-gray-300 text-xs'>Not provided yet</span>}
              </div>
            </div>
            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Facebook</label>
                {employeeDetails.socialMedia?.facebook ? <a href={employeeDetails.socialMedia?.facebook} className="text-sm text-blue-600">Go to facebook profile</a> : <span className='text-gray-300 text-xs'>Not provided yet</span>}
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Instagram</label>
                {employeeDetails.socialMedia?.instagram ? <a href={employeeDetails.socialMedia?.instagram} className="text-sm text-blue-600">Go to instagram profile</a> : <span className='text-gray-300 text-xs'>Not provided yet</span>}
              </div>
            </div>
          </div>

          {/* Address information */}
          <div className='w-full mt-6'>
            <div className='flex items-center justify-between bg-verovian-light-purple p-[5px]'>
              <h3 className=' text-verovian-purple uppercase font-[500]'>Address information</h3>
              {employeeDetails._id === userDetails().employeeProfile && <button onClick={()=>{setUpdatingAddress(true)}} className='flex items-center justify-center gap-x-[5px] text-gray-500 text-xs hover:text-gray-700 font-[500]'>
                <PencilSquareIcon className={`w-4 h-4`} />
                Edit Address Information
              </button>}
            </div>

            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Address</label>
                <p className="text-sm">{employeeDetails.address.address}</p>
              </div>
            </div>
            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>City</label>
                <p className="text-sm">{employeeDetails.address.city}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Post code</label>
                <p className="text-sm">{employeeDetails.address.postCode || <span className='text-gray-300 text-xs'>Not provided</span>}</p>
              </div>
            </div>
            <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>State</label>
                <p className="text-sm">{employeeDetails.address.state}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Country</label>
                <p className="text-sm">{employeeDetails.address.country || <span className='text-gray-300 text-xs'>Not provided</span>}</p>
              </div>
            </div>
          </div>

          {/* Emergency contact */}
          <div className='w-full mt-6'>
            <div className='flex items-center justify-between bg-verovian-light-purple p-[5px]'>
              <h3 className=' text-verovian-purple uppercase font-[500]'>Emergency Contact</h3>
              {employeeDetails._id === userDetails().employeeProfile && <button onClick={()=>{setUpdatingEmergencyContact(true)}} className='flex items-center justify-center gap-x-[5px] text-gray-500 text-xs hover:text-gray-700 font-[500]'>
                <PencilSquareIcon className={`w-4 h-4`} />
                Edit Emergency Contact
              </button>}
            </div>
            {employeeDetails.emergencyContact ? 
              <>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Name</label>
                    <p className="text-sm">{employeeDetails.emergencyContact?.name}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Email address</label>
                    <p className="text-sm">{employeeDetails.emergencyContact?.email}</p>
                  </div>
                </div>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Phone number</label>
                    <p className="text-sm">{employeeDetails.emergencyContact?.phone}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Address address</label>
                    <p className="text-sm">{employeeDetails.emergencyContact?.address}</p>
                  </div>
                </div>
              </>:
              <EmptyState emptyStateText={`Emergency contacts not provided yet`} />
            }
          </div>

          {/* Next of Kin */}
          <div className='w-full mt-6'>
            <div className='flex items-center justify-between bg-verovian-light-purple p-[5px]'>
              <h3 className=' text-verovian-purple uppercase font-[500]'>Next of Kin</h3>
              {employeeDetails._id === userDetails().employeeProfile && <button onClick={()=>{setUpdatingNextOfKin(true)}} className='flex items-center justify-center gap-x-[5px] text-gray-500 text-xs hover:text-gray-700 font-[500]'>
                <PencilSquareIcon className={`w-4 h-4`} />
                Edit Next of Kin Information
              </button>}
            </div>
            {employeeDetails.nextOfKin ? 
              <>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Name</label>
                    <p className="text-sm">{employeeDetails.nextOfKin?.name}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Email address</label>
                    <p className="text-sm">{employeeDetails.nextOfKin?.email}</p>
                  </div>
                </div>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Phone number</label>
                    <p className="text-sm">{employeeDetails.nextOfKin?.phone}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Address address</label>
                    <p className="text-sm">{employeeDetails.nextOfKin?.address}</p>
                  </div>
                </div>
              </>:
              <EmptyState emptyStateText={`Next of Kin details not provided yet`} />
            }
          </div>

      </div>

      <ModalLayout
          isOpen={updatingContactDetails} 
          closeModal={()=>{setUpdatingContactDetails(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Update Contact Details'
          maxWidthClass='max-w-xl'
      >
          <ContactInfoUpdate 
            contactInfo={{
              ...{
                phone: employeeDetails.phone,
                email: employeeDetails.email,
              }, 
              ...{
                socialMedia: employeeDetails.socialMedia || {}
              }
            }
          } />
      </ModalLayout>

      <ModalLayout
          isOpen={updatingAddress} 
          closeModal={()=>{setUpdatingAddress(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Update Contact Details'
          maxWidthClass='max-w-xl'
      >
          <AddressInfoUpdate 
            addressInfo={employeeDetails.address}
          />
      </ModalLayout>

      <ModalLayout
          isOpen={updatingNextOfKin} 
          closeModal={()=>{setUpdatingNextOfKin(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Update Contact Details'
          maxWidthClass='max-w-xl'
      >
          <NextOfKinUpdate 
            nokInfo={employeeDetails.nextOfKin}
          />
      </ModalLayout>

      <ModalLayout
          isOpen={updatingEmergencyContact} 
          closeModal={()=>{setUpdatingEmergencyContact(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Update Contact Details'
          maxWidthClass='max-w-xl'
      >
          <EmergencyContactUpdate 
            emergencyContactInfo={employeeDetails.emergencyContact}
          />
      </ModalLayout>
    </>
  )
}

export default EmployeeProfile