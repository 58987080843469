import React, { useState } from 'react'
import Checkbox from '../../elements/form/Checkbox';
import FormButton from '../../elements/form/FormButton';
import TextField from '../../elements/form/TextField';
// import moment from 'moment';

const OnboardingPackageSelector = ({packages, selectedPackageId, saveSelection, closeModal}) => {
    const [selectedPackage, setSelectedPackage] = useState(selectedPackageId || null);
    const [displayedPackages, setDisplayedPackages] = useState(packages);
    
    const togglePackageSelection = (selected) => {
        setSelectedPackage(selected)
    }

    const updateAndClose = () => {
        saveSelection(selectedPackage)
        closeModal()
    }

    const searchForPackage = (searchTerm) => {
        if(searchTerm.length === 0) {
            clearSearch()
        }
        if(!searchTerm || searchTerm === '') {
            return
        }

        if (!Array.isArray(packages) || typeof searchTerm !== 'string') {
            setDisplayedPackages([])
        }
    
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
        const results = packages.filter(doc => {
            const nameIncludes = doc.name && doc.name.toLowerCase().includes(lowerCaseSearchTerm);
            const descriptionIncludes = doc.description && doc.description.toLowerCase().includes(lowerCaseSearchTerm);
            return nameIncludes || descriptionIncludes;
        });

        setDisplayedPackages(results)
    }

    const clearSearch = () => {
        setDisplayedPackages(packages)
    }

    return (
        <div>
            <div className='w-full mb-4'>
                <TextField
                    inputLabel="Search for a Package" 
                    fieldId="doc-search-term" 
                    inputType="text" 
                    preloadValue={''}
                    inputPlaceholder={`package name or part of description`}
                    hasError={false} 
                    returnFieldValue={(value)=>{searchForPackage(value)}}
                />
            </div>

            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
            {displayedPackages?.length > 0 ? 
                <div>
                    {displayedPackages?.map((pack, packIndex) => (
                    <div key={packIndex} className={`w-full border rounded flex items-start transition duration-200 gap-x-2 mb-4 p-[10px] border-transparent cursor-pointer ${selectedPackage === pack._id && 'bg-verovian-light-purple border-verovian-purple bg-opacity-30'}`} onClick={(()=>{togglePackageSelection(pack._id)})}>
                        <div className='w-[25px] pt-[5px]'>
                            <Checkbox 
                                isChecked={selectedPackage === pack._id}
                                checkboxToggleFunction={()=>{togglePackageSelection(pack._id)}}
                                checkboxLabel=''
                            />
                        </div>
                        <div className='w-full'>
                            <p className='text-[13px] text-gray-700 font-[550]'>{pack.name}</p>
                            <div className='my-1'>
                                <p className='text-xs'>{pack.description.length > 100 ? pack.description.slice(0, 100) + '...' : pack.description}</p>
                                <p className='text-xs text-gray-500 mb-[20px]'>
                                    {pack.onboardingDays.length} days total
                                </p>
                            </div>
                            {/* <div className='flex flex-wrap gap-x-[5px] gap-y-[5px]'>
                                {pack.packageItems.slice(0, 2).map((item, itemIndex) => (
                                    <div key={itemIndex} className={`px-[8px] py-[6px] rounded  ${item.type === 'review' ? 'bg-verovian-light-purple' : item.type === 'document' ? 'bg-blue-400 bg-opacity-10' : 'bg-green-400 bg-opacity-10'}`}>
                                        <p className='text-xs text-verovian-purple capitalize font-[550]'>{item.item.title || item.item.name} <span className='text-gray-500 font-[400]'>({item.type})</span></p>
                                    </div>
                                ))}
                                {pack.packageItems.length > 2 && <div className='px-[8px] py-[6px] rounded bg-gray-100'>
                                    <p className='text-xs text-verovian-purple capitalize'>+{pack.packageItems.length - 2} more</p>
                                </div>}
                            </div> */}
                        </div>
                    </div>
                    ))}
                </div> 
                :
                <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                    <p className='text-sm text-gray-700 font-[550]'>No onboarding packages found</p>
                    <p className='mt-1 text-xs text-gray-500'>Navigate to the company &gt; onboarding packages to create some first.</p>
                </div>  
                } 
            </div>
            <div className='flex flex-row-reverse'>
                <div className='w-max'>
                    <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                </div>
            </div>
        </div>
    )
}

export default OnboardingPackageSelector