import React, { useState } from 'react'
import Status from '../../elements/Status'
import { Link } from 'react-router-dom'
import VideoIcon from '../../elements/icons/VideoIcon'
import AudioIcon from '../../elements/icons/AudioIcon'
import PhotoIcon from '../../elements/icons/PhotoIcon'
import TextIcon from '../../elements/icons/TextIcon'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
import { userDetails } from '../../../utils'
import TaskAssignmentDetailsDialogContent from '../tasks/TaskAssignmentDetailsDialogContent'
import ModalLayout from '../../layout/ModalLayout'

const OnboardingDayItemCard = ({item, hideLink}) => {
    const documentCompleted = (document, documentAssignment) => {
        const completed = (document?.providedBy === 'employee' && documentAssignment?.documentUrl && documentAssignment.documentUrl !== '') || (document?.requiresSigning && documentAssignment?.documentSigning && documentAssignment.documentSigning !== '') || (!document?.requiresSigning)
        return completed
    }

    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const openTask = (task) => {
        console.log('task opening: ', task)
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }
        

    return (
        <>
            <div>
                <div className='w-[350px] rounded p-[10px] my-[20px] bg-white shadow-xl shadow-verovian-purple/5'>
                    {item.type === 'training' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100' style={{
                                backgroundImage: `url(${item.item?.coverImage})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}  />
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item?.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item?.item?.description?.length > 150 ? item?.item?.description?.slice(0, 150) + '...' : item.item?.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.completed ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <Link to={`/user/trainings/training/${item.item._id}`}  className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Go to training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                    {item.type === 'training-material' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100'>
                                <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]'>
                                    {item?.item?.content?.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {item?.item?.content?.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {item?.item?.content?.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {(item?.item?.content?.contentType === 'TEXT' || item?.content?.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                </div>
                            </div>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item?.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item?.item?.description?.length > 150 ? item?.item?.description?.slice(0, 150) + '...' : item.item?.description}</p>
                                    {item?.item?.instructor?.name && <p className='text-xs text-gray-500 mt-[5px]'>By {item?.item?.instructor?.name}</p>}
                                </div>
                                
                            </div>
                        </div>

                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item.item?.assignment?.completed === true ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <button onClick={()=>{}} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>}
                        </div>
                    </div>}
                    {item.type === 'document' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[80px] rounded-[8px]'>
                                <img src={DocumentSvgIcon} alt="" className='w-full' />
                            </div>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t  flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={documentCompleted(item.item, item.item.assignment) ? `complete` : 'pending'} />
                            </div>
                            {!hideLink && <Link to={`/user/documents/assigned/read/${item?.item?.assignment?._id}`}  className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Go to document <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                    {item.type === 'task' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.status === 'done' ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <button onClick={()=>{openTask(item?.item?.assignment)}} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open task <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>}
                        </div>
                    </div>}
                    {item.type === 'review' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.submitted ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <Link to={`/user/new-review/${item?.item?._id}/${item?.item?.assignment?._id}`} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Submit review <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                </div>
            </div>
            {activeOpenTask && userDetails().userType === 'USER' && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskAssignmentDetailsDialogContent assignment={activeOpenTask} />
            </ModalLayout>}
        </>
    )
}

export default OnboardingDayItemCard