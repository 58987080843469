import React, { useEffect, useState } from 'react'
import TaskDetails from '../tasks/TaskDetails';
import ModalLayout from '../../layout/ModalLayout';
import EmptyState from '../../elements/icons/EmptyState';
import Preloader from '../../elements/Preloader';
import AssignedTaskCard from '../tasks/AssignedTaskCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeeTasks } from '../../../store/actions/tasksActions';

const OngoingTasks = () => {
    const tasksSelector = useSelector(state => state.tasks)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchEmployeeTasks(1, 3,'status=in_progress'))
        return () => {
            
        };
    }, [dispatch]);

    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const openTask = (task) => {
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }
    

    return (
        <>
            <div>
                {tasksSelector.loadingTasks ? 

                    <Preloader preloadingText={`loading tasks`} />
                    :

                    <div className='w-full mt-[5px]'>
                        {(tasksSelector?.assignedTasks?.assignments?.length === 0) ? 
                            <EmptyState 
                                emptyStateText={`You have no tasks currently in progress`} 
                            />
                        :
                        <>
                            <div className='grid lg:grid-cols-2 gap-2'>
                                
                                {tasksSelector?.assignedTasks?.assignments?.map((assignment, assignmentIndex)=>(<div key={assignmentIndex} className='w-full'>
                                    <AssignedTaskCard assignment={assignment} openTask={()=>{openTask(assignment)}} />
                                </div>))}
                            </div>
                        </>
                        }
                    </div>
                }
            </div>

            {activeOpenTask && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskDetails task={activeOpenTask} />
            </ModalLayout>}
        </>
    )

}

export default OngoingTasks