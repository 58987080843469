import { CREATE_DOCUMENT, CREATE_DOCUMENT_ASSIGNMENT, CREATING_DOCUMENT, DOCUMENTS_ERROR, GETTING_DOCUMENTS, GET_DOCUMENTS, GET_DOCUMENT_ASSIGNMENTS, SIGNING_DOCUMENT, SIGN_DOCUMENT, UPDATE_DOCUMENT, UPDATING_DOCUMENT} from "../types"

const initialState = {
    documents: [],
    documentAssignments: [],
    loadingDocuments: false,
    documentsError: null,
    creatingDocument: false,
    createdDocument: null,
    createdDocumentAssignment: null,
    updatingDocument: false,
    updatedDocument: null,
    signingDocument: false,
    signedDocument: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_DOCUMENTS:
        return {
            ...state,
            loadingDocuments: action.payload,
            // fetchingMembers:false
        }
        case GET_DOCUMENTS:
        return{
            ...state,
            loadingDocuments: false,
            documentsError: false,
            documents: action.payload,
        }
        case GET_DOCUMENT_ASSIGNMENTS:
        return{
            ...state,
            loadingDocuments: false,
            documentsError: false,
            documentAssignments: action.payload,
        }
        case CREATING_DOCUMENT:
        return {
            ...state,
            creatingDocument: action.payload,
            // fetchingMembers:false
        }
        case CREATE_DOCUMENT:
        return{
            ...state,
            creatingDocument:false,
            documentsError: null,
            createdDocument: action.payload,
        }
        case SIGNING_DOCUMENT:
        return {
            ...state,
            signingDocument: action.payload,
            // fetchingMembers:false
        }
        case SIGN_DOCUMENT:
        return{
            ...state,
            signingDocument:false,
            documentsError: null,
            signedDocument: action.payload,
        }
        case CREATE_DOCUMENT_ASSIGNMENT:
        return{
            ...state,
            creatingDocument:false,
            documentsError: null,
            createdDocumentAssignment: action.payload,
        }
        case UPDATING_DOCUMENT:
        return {
            ...state,
            updatingDocument: action.payload,
            // fetchingMembers:false
        }
        case UPDATE_DOCUMENT:
        return{
            ...state,
            updatingDocument:false,
            documentsError: null,
            updatedDocument: action.payload,
        }
        case DOCUMENTS_ERROR:
        return{
            ...state,
            loadingDocuments:false,
            creatingDocument: false,
            updatingDocument: false,
            documentsError: action.payload 
        }
        default: return state
    }

}