import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE, UPDATING_DOCUMENT } from '../../../store/types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Preloader from '../../../components/elements/Preloader';
import DownloadIcon from '../../../components/elements/icons/DownloadIcon';
import { authHeader, userDetails } from '../../../utils';
import DocumentsLayout from '../../../components/layout/DocumentsLayout';
import SignatureUploader from '../../../components/partials/documents/SignatureUploader';
import { clearSignedDocument, clearUpdatedDocument, updateDocumentAssignment } from '../../../store/actions/documentActions';
import FileUpload from '../../../components/elements/form/FileUpload';
import DateField from '../../../components/elements/form/DateField';
import TextField from '../../../components/elements/form/TextField';
import FormButton from '../../../components/elements/form/FormButton';
import ArrowIcon from '../../../components/elements/icons/ArrowIcon';
import moment from 'moment';

const DocumentAssignment = () => {
    const [loading, setLoading] = useState(true);
    const [doc, setDoc] = useState(null);
    const dispatch = useDispatch()
    const documentsSelector = useSelector(state => state.documents)
    const {documentAssignmentId} = useParams()
    const [documentAssignment, setDocumentAssignment] = useState(null);
    const currentUser = userDetails()

    useEffect(() => {
        const fetchDocumentAssignment = async () => {    
            try {
                const headers = authHeader()
                let requestUrl = `documents/document-assignments/${documentAssignmentId}?expand=document,employee`
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        
                setDocumentAssignment(response.data.data)
                if(response.data.data.document.providedBy === 'admin') {
                    readDocument(response.data.data.document._id, response.data.data.employee._id)
                } else{ 
                    setUpdatingDocument((!response.data.data.documentUrl || response.data.data.documentUrl === ''))
                    setLoading(false)
                }
            }
            catch(error){
                dispatch( {
                    type: ERROR,
                    error
                })
            }
        }

        const readDocument = async (documentId, employeeId) => {    
            try{
              const headers = authHeader()
              console.log('doc id: ', documentId)
              console.log('employee id: ', employeeId)
              let requestUrl = `documents/read/${documentId}/${employeeId}?assignment=${documentAssignmentId}`
              setLoading(true)
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
      
              setDoc(response.data.data)
              setLoading(false)
            }
            catch(error){
              dispatch( {
                  type: ERROR,
                  error
              })
            }
          }
          fetchDocumentAssignment()

          if(documentsSelector.signedDocument !== null) {
            dispatch(clearSignedDocument())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: 'Document signed successfully!'
                }
            })
          }

          if(documentsSelector.updatedDocument !== null) {
            dispatch(clearUpdatedDocument())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: 'Document updated successfully!'
                }
            })
          }
        return () => {
            
        };
    }, [dispatch, documentAssignmentId, documentsSelector.signedDocument, documentsSelector.updatedDocument]);

    const downloadDocument = async () => {
      try {
        const header = {
          responseType: 'blob'
        }
        
        let url = `${process.env.REACT_APP_API_URL}/documents/download/${documentAssignment.document._id}/${documentAssignment.employee._id}?assignment=${documentAssignmentId}`
        const response =  await axios.get(url, header ) 

        const blob = new Blob([response.data], {type: "application/pdf"})
        const receiptUrl = window.URL.createObjectURL(blob)

        var link = document.createElement('a');
        link.href = receiptUrl;
        link.download = `${doc.slug}-${doc._id}.pdf`;
        setTimeout(() => {
            link.click();
        }, 100);
        // setFetchingCsv(false)

      } catch (error) {
          // setFetchingCsv(false)
          dispatch({
              type: ERROR,
              error
          })
      }
    }
    
    const [validationErrors, setValidationErrors] = useState({});
    const [newDocument, setNewDocument] = useState({});
    const [file, setFile] = useState(null);

    const handleUpload = async (file) => {
        console.log(file)

        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
        dispatch({
            type: UPDATING_DOCUMENT,
            payload: true
        })

        try {
            const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();

            return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }

    const validateProvisionForm = () => {
        let errors = {}
        if(!file){
            errors.file = true
        }
        if((!newDocument.documentExpiry || newDocument.documentExpiry) === '' && documentAssignment.document.requiresExpiry) {
            errors.expiry = true
        }
        if((!newDocument.documentNumber || newDocument.documentNumber) === '' && documentAssignment.document.requiresDocumentNumber) {
            errors.docNumber = true
        }
        setValidationErrors(errors)
        return errors
    }

    const provideDocument = async () => {
        if (Object.values(validateProvisionForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = {...newDocument}

        if(file) {
            const uploaded = await handleUpload(file)
            payload.documentUrl = uploaded.data.file
        }

        dispatch(updateDocumentAssignment(documentAssignment._id, payload))
    }

    const [updatingDocument, setUpdatingDocument] = useState(false);

    return (
        <DocumentsLayout sectionTitle={`read assigned document`}>
            <div>
                {loading ? 
                    <Preloader preloadingText={`Loading document`} /> :
                    <>
                        <div className='w-full flex flex-row-reverse mb-5'>
                            <button onClick={()=>{downloadDocument()}} className='flex items-center gap-x-2 px-4 py-3 rounded border border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 text-xs'>
                            <DownloadIcon className={`w-4 h-4`} />
                            Download document
                            </button>
                        </div>
                        
                        {documentAssignment?.document?.providedBy === 'admin' && documentAssignment?.document?.documentBody !== '' && <div className='pb-12 bg-white p-[40px] mb-[20px]' dangerouslySetInnerHTML={{__html: doc?.documentBody}} />}

                        {documentAssignment?.document?.providedBy === 'employee' &&

                        <> 
                            {updatingDocument ? 
                            <div className='w-full'>
                                <div className='mt-3 pt-3 border-t border-gray-300 w-full pb-4'>
									<p className='font-[550] text-verovian-purple text-sm'>{documentAssignment.document.name}</p>
									<label className='block mt-2 text-sm text-gray-500'>{documentAssignment.document.description}</label>
									<div className='w-full mb-3'>
										<FileUpload
											hasError={false}
											fieldLabel={documentAssignment.document.documentName}
											returnFileDetails={(details)=>{
												setFile(details)
											}}
											acceptedFormats={['pdf', 'jpeg', 'jpg', 'png']}
										/>
									</div>
									{documentAssignment.document.requiresExpiry && <div className='w-full mt-3'>
										<DateField
											inputLabel="Document Expiry Date" 
											inputPlaceholder="" 
											fieldId={`doc-expiry-date`} 
											inputType="date" 
											preloadValue={documentAssignment.documentExpiry || ''}
											hasError={validationErrors[`expiry`]} 
											returnFieldValue={(value) => {setNewDocument({...newDocument, ...{ documentExpiry: new Date(value)}})}}
										/>
									</div>}
									{documentAssignment.document.requiresDocumentNumber && <div className='mt-3'>
										<TextField
											inputLabel="Document Number" 
											inputPlaceholder="Document number, ID or reference" 
											fieldId={`doc-number`} 
											inputType="text" 
											disabled={false}
											preloadValue={documentAssignment.documentNumber || ''}
											hasError={validationErrors[`docNumber`]} 
											returnFieldValue={(value)=>{setNewDocument({...newDocument, ...{documentNumber: value}})}}
										/>
									</div>}
								</div>
                                <div className='my-8 flex items-center gap-x-2 pt-8 border-t'>
                                    <div className='w-3/12'>
                                    <FormButton buttonLabel={`Save document`} buttonAction={()=>{provideDocument()}} processing={documentsSelector.updatingDocument} />
                                    </div>
                                    <button onClick={()=>{setUpdatingDocument(false)}}  className='pb-1 mb-[20px] border-b border-black  w-max text-xs text-gray-800 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-[10px]' rel="noreferrer">
                                        Cancel upload
                                    </button>
                                </div>
                            </div>

                            : 

                            <>
                                <p className='font-[550] text-verovian-purple text-sm'>{documentAssignment.document.name}</p>
                                <label className='block mt-2 text-sm text-gray-500'>{documentAssignment.document.description}</label>
                                <div className='flex items-center justify-between gap-x-[20px]'> 
                                    <div className='w-full'>
                                        <img src={documentAssignment?.documentUrl} className='w-full' alt='' />
                                    </div>
                                    <div className='w-full'>
                                        <button onClick={()=>{setUpdatingDocument(true)}}  className='pb-1 mb-[20px] border-b border-black  w-max text-xs text-gray-800 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-[10px]' rel="noreferrer">
                                        Click to change document
                                        </button>
                                        <label className='block text-xs uppercase text-gray-500 font-thin mb-[5px]'>Document Number</label>
                                        <p className='mb-[10px]'>{documentAssignment.documentNumber}</p>
                                        <label className='block text-xs uppercase text-gray-500 font-thin mb-[5px]'>Document Expiry Date</label>
                                        <p className='mb-[10px]'>{moment(documentAssignment.documentExpiry).format('DD-MM-YYYY')}</p>

                                        <a href={documentAssignment.documentUrl} download target='_blank' className='text-xs text-gray-800 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-[10px]' rel="noreferrer">
                                        View/Download Document <ArrowIcon className={`w-4 h-4 -rotate-90`}/>
                                        </a>
                                    </div>
                                </div>
                            </>
                            }
                        
                        </>
                        }
                        
                        {currentUser.employeeProfile === documentAssignment.employee._id && documentAssignment.document.requiresSigning && !documentAssignment.documentSigning &&  
                            <div className='pb-[100px]'>
                                <SignatureUploader 
                                    assignmentId={documentAssignmentId}
                                    employeeId={documentAssignment.employee._id}
                                    documentId={documentAssignment.document._id}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        </DocumentsLayout>
    )
}

export default DocumentAssignment