import React, { useEffect, useState } from 'react'
import UserLayout from '../../../../components/layout/UserLayout'
import CompanyPageLayout from '../../../../components/layout/CompanyPageLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getReviewForms } from '../../../../store/actions/reviewsActions'
import { getTrainingMaterials, getTrainings } from '../../../../store/actions/trainingsActions'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types'
import TextField from '../../../../components/elements/form/TextField'
import TextareaField from '../../../../components/elements/form/TextareaField'
import FormButton from '../../../../components/elements/form/FormButton'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'
import { clearCreatedOnboardingPackage, createOnboardingPackage, updateOnboardingPackage } from '../../../../store/actions/onboardingPackagesActions'
import { fetchDocuments } from '../../../../store/actions/documentActions'
import { useNavigate, useParams } from 'react-router-dom'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import DragDropList from '../../../../components/elements/DragDropList'
import { Menu } from '@headlessui/react'
import ModalLayout from '../../../../components/layout/ModalLayout'
import TrainingsPicker from '../../../../components/partials/trainings/trainings/TrainingsPicker'
import DocumentsPicker from '../../../../components/partials/documents/DocumentsPicker'
import ReviewFormPicker from '../../../../components/partials/reviews-management/ReviewFormPicker'
import TasksPicker from '../../../../components/partials/tasks/TasksPicker'
import { fetchTasks } from '../../../../store/actions/tasksActions'
import { authHeader, userDetails } from '../../../../utils'
import axios from 'axios'
import Preloader from '../../../../components/elements/Preloader'
import DocumentSvgIcon from '../../../../assets/img/icons/document-icon.svg'
import MaterialCardHorizontal from '../../../../components/partials/trainings/materials/MaterialCardHorizontal'
import TrainingMaterialsPicker from '../../../../components/partials/trainings/TrainingMaterialsPicker'
import ClipboardIcon from '../../../../components/elements/icons/ClipboardIcon'
import CloseIcon from '../../../../components/elements/icons/CloseIcon'

const DocumentTemplate = ({data, removeItem}) => {
    return (
        <>
            <div className={`w-full flex items-start justify-between gap-x-2 mb-4 cursor-pointer`}>
                <div className='w-full flex items-start gap-x-2'>
                    <div className='w-[50px] rounded-[8px]'>
                        <img src={DocumentSvgIcon} alt="" className='w-full' />
                    </div>
                    <div className='w-full'>
                        <p className='text-xs text-gray-700 font-medium'>{data?.item?.name || data.document.name}</p>
                        <div className='my-1'>
                            {data.item && <p className='text-xs'>{data.item?.description.length > 150 ? data.item?.description.slice(0, 150) + '...' : data?.item?.description}</p>}
                            {data.document && <p className='text-xs'>{data.document?.description.length > 150 ? data.document?.description.slice(0, 150) + '...' : data?.document?.description}</p>}
                        </div>
                    </div>
                </div>
                <div className='w-[30px] text-right rounded-[8px]'>
                    <button onClick={()=>{removeItem()}} className='flex items-center justify-center w-[25px] h-[25px] text-gray-600 hover:text-red-600 hover:bg-red-100 rounded duration-200 transition text-xs'>
                        <CloseIcon className={`w-[17px] h-[17px]`} />
                    </button>
                </div>
            </div>
        </>
    )
}

const TaskTemplate = ({data, removeItem}) => {
    return (
        <>
            <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                <div className='w-full'>
                    <p className='text-xs text-gray-700 font-medium'>{data.item.title}</p>
                    <div className='my-1'>
                        <p className='text-xs'>{data.item.description.length > 150 ? data.item.description.slice(0, 150) + '...' : data.task.description}</p>
                    </div>
                </div>
                <div className='w-[30px] text-right rounded-[8px]'>
                    <button onClick={()=>{removeItem()}} className='flex items-center justify-center w-[25px] h-[25px] text-gray-600 hover:text-red-600 hover:bg-red-100 rounded duration-200 transition text-xs'>
                        <CloseIcon className={`w-[17px] h-[17px]`} />
                    </button>
                </div>
            </div>
        </>
    )
}

const ReviewFormTemplate = ({data, removeItem}) => {
    return (
        <>
            <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                <div className='w-full'>
                    <p className='text-xs text-gray-700 font-medium'>{data.item.name}</p>
                    <div className='my-1'>
                        <p className='text-xs'>{data.item.description.length > 150 ? data.item.description.slice(0, 150) + '...' : data.item.description}</p>
                    </div>
                </div>
                <div className='w-[30px] text-right rounded-[8px]'>
                    <button onClick={()=>{removeItem()}} className='flex items-center justify-center w-[25px] h-[25px] text-gray-600 hover:text-red-600 hover:bg-red-100 rounded duration-200 transition text-xs'>
                        <CloseIcon className={`w-[17px] h-[17px]`} />
                    </button>
                </div>
            </div>
        </>
    )
}


const TrainingTemplate = ({data, removeItem}) => {
    return (
        <>
            <div className={`flex items-start justify-between w-full gap-x-2 mb-4 cursor-pointer`}>
                <div className='w-full flex items-start gap-x-2'>
                    <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100' style={{
                        backgroundImage: `url(${data.item?.coverImage || data.training?.coverImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}  />
                    <div className='w-full'>
                        <p className='text-xs text-gray-700 font-medium'>{data.item?.title || data.training?.title}</p>
                        <div className='my-1'>
                            {data.item && <p className='text-xs'>{data?.item?.description?.length > 150 ? data?.item?.description?.slice(0, 150) + '...' : data.item?.description}</p>}
                            {data.training && <p className='text-xs'>{data?.training?.description?.length > 150 ? data?.training?.description?.slice(0, 150) + '...' : data.training?.description}</p>}
                        </div>
                    </div>
                </div>
                <div className='w-[30px] text-right rounded-[8px]'>
                    <button onClick={()=>{removeItem()}} className='flex items-center justify-center w-[25px] h-[25px] text-gray-600 hover:text-red-600 hover:bg-red-100 rounded duration-200 transition text-xs'>
                        <CloseIcon className={`w-[17px] h-[17px]`} />
                    </button>
                </div>
            </div>
        </>
    )
}

export const TrainingMaterialTemplate = ({data, removeItem}) => {
    return (
        <div className='w-full flex items-start justify-between gap-x-2'>
            <div className='w-full'>
                <MaterialCardHorizontal data={data.item || data.material } />
            </div>
            <div className='w-[30px] text-right rounded-[8px]'>
                <button onClick={()=>{removeItem()}} className='flex items-center justify-center w-[25px] h-[25px] text-gray-600 hover:text-red-600 hover:bg-red-100 rounded duration-200 transition text-xs'>
                    <CloseIcon className={`w-[17px] h-[17px]`} />
                </button>
            </div>
        </div>
    )
}

const OnboardingPackageDetails = () => {
    const  packageDay = {
        day: '',
        dayItems: []
    }
    const accountPermissions = userDetails().accountPermissions
    const { packageId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onboardingPackagesSelector = useSelector(state => state.onboardingPackages)
    const reviewsSelector = useSelector(state => state.reviews)
    const trainingsSelector = useSelector(state => state.trainings)
    const documentsSelector = useSelector(state => state.documents)
    const tasksSelector = useSelector(state => state.tasks)

    const [packagePayload, setPackagePayload] = useState({
        onboardingDays: [packageDay]
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [displayForm] = useState(true);
    const [loadingPackage, setLoadingPackage] = useState(true);
    
    useEffect(() => {
        dispatch(getReviewForms(0, 0))
        dispatch(getTrainings(0, 0))
        dispatch(fetchTasks())
        dispatch(fetchDocuments())
        dispatch(getTrainingMaterials(0, 0, 'forOnboardingPackage=true'))

        const getOnboardingPackage = async () => {
            try {
                const headers = authHeader()
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/onboarding-packages/${packageId}`, { headers })
                setPackagePayload(response.data.data)
                setLoadingPackage(false)
            } catch (error) {
                dispatch({
                    type: ERROR,
                    error: error
                })
                setLoadingPackage(false) 
            }
        }

        getOnboardingPackage()

        if(onboardingPackagesSelector.updatedOnboardingPackage !== null){
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: `Onboarding package updated successfully!`
                }
            })
            // navigate('/user/company/onboarding-packages')
        }

        if(onboardingPackagesSelector.createdOnboardingPackage !== null){
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: `Package Duplicated successfully! Redirecting to duplicate...`
                }
            })
            const duplicatePackageId = onboardingPackagesSelector.createdOnboardingPackage.data._id
            console.log(onboardingPackagesSelector.createdOnboardingPackage)
            setTimeout(() => {
                dispatch(clearCreatedOnboardingPackage())
                navigate(`/user/company/onboarding-packages/details/${duplicatePackageId}`)
                window.location.reload()
            }, 2000);
        }
        return () => {
            
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, onboardingPackagesSelector.updatedOnboardingPackage, onboardingPackagesSelector.createdOnboardingPackage]);

    const validateForm = () => {
        let errors = {}

        if(!packagePayload.name || packagePayload.name === ''){
            errors.name = true
        }

        if(!packagePayload.description || packagePayload.description === ''){
            errors.description = true
        }

        packagePayload.onboardingDays.forEach((day, dayIndex) => {
            if(!day.dayItems || day.dayItems.length === 0){
                errors[`day-${dayIndex}-items`] = true
            }
        });

        if(!packagePayload.description || packagePayload.description === ''){
            errors.description = true
        }

        setValidationErrors(errors)
        console.log(errors)
        return errors
    }

    const pushPackage = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const packageDays = packagePayload.onboardingDays.map((day, dayIndex) => {
            console.log('day: ', dayIndex + 1)
            return {
                // ...day, 
                // ...{
                    day: dayIndex + 1,
                    dayItems:day.dayItems.map((item, itemIndex) => {
                        console.log('package item: ',item)
                        return {
                            type: item.type,
                            item: item.training?._id || item.form?._id || item.document?._id || item.task?._id || item.material?._id || item.item?._id,
                            order: itemIndex
                        }
                    })
                // }
            }
        })
        // return
        dispatch(updateOnboardingPackage(packagePayload._id, {...packagePayload, ...{onboardingDays: packageDays}}))
    }

    const addDay = () => {
        let tempPackage = {...packagePayload}
        tempPackage.onboardingDays.push(packageDay)
        setPackagePayload(tempPackage)
    }

    const removeDay = (itemIndex) => {
        let tempPackage = {...packagePayload}
        tempPackage.onboardingDays.splice(itemIndex, 1)
        setPackagePayload(tempPackage)
    }

    const updateDay = (dayIndex, field, value) => {
        let tempPackage = {...packagePayload}
        if(field === 'dayItems') {
            // check if each item in the value array already includes the item
            const filtered = value.filter(item => {
                return !tempPackage.onboardingDays[dayIndex].dayItems.includes(item)
            })
            tempPackage.onboardingDays[dayIndex].dayItems = [...tempPackage.onboardingDays[dayIndex].dayItems, ...filtered]
        } else if (field === 'dayItemsReSorting') {
            tempPackage.onboardingDays[dayIndex].dayItems = value
        } else {
            tempPackage.onboardingDays[dayIndex][field] = value
        }

        console.log('temp package:==> ', tempPackage)
        setPackagePayload({})
        setTimeout(() => {
            setPackagePayload(tempPackage)
        }, 10);
    }

    const dayItemTemplates = {
        review: ReviewFormTemplate,
        document: DocumentTemplate,
        task: TaskTemplate,
        training: TrainingTemplate,
        'training-material': TrainingMaterialTemplate
    }

    const removePackageDayItem = (dayIndex, itemIndex) => {
        let tempPackage = {...packagePayload}
        tempPackage.onboardingDays[dayIndex].dayItems.splice(itemIndex, 1)
        setPackagePayload({})
        setTimeout(() => {
            setPackagePayload(tempPackage)
        }, 10);
    }

    const [activeDay, setActiveDay] = useState(null);
    const [trainingsPickerOpen, setTrainingsPickerOpen] = useState(false);
    const [reviewFormsPickerOpen, setReviewFormsPickerOpen] = useState(false);
    const [documentsPickerOpen, setDocumentsPickerOpen] = useState(false);
    const [tasksPickerOpen, setTasksPickerOpen] = useState(false);
    const [trainingMaterialsPickerOpen, setTrainingMaterialsPickerOpen] = useState(false)

    const openDayActivityAddition = (dayIndex, activityType) => {
        setActiveDay(dayIndex)
        if (activityType === 'training') {
            setTrainingsPickerOpen(true)
        }
        if (activityType === 'documentation') {
            setDocumentsPickerOpen(true)
        }
        if (activityType === 'task') {
            setTasksPickerOpen(true)
        }
        if (activityType === 'review') {
            setReviewFormsPickerOpen(true)
        }
        if (activityType === 'training-material') {
            setTrainingMaterialsPickerOpen(true)
        }
    }

    const duplicatePackage = () => {
        const newPackage = JSON.parse(JSON.stringify(packagePayload))
        delete newPackage._id
        newPackage.name = packagePayload.name + `-duplicate-${Date.now()}`

        const packageDays = newPackage.onboardingDays.map((day, dayIndex) => {
            console.log('day: ', dayIndex + 1)
            return {
                day: dayIndex + 1,
                dayItems:day.dayItems.map((item, itemIndex) => {
                    return {
                        type: item.type,
                        item: item.item?._id,
                        order: itemIndex
                    }
                })
            }
        })
        dispatch(createOnboardingPackage({...newPackage, ...{onboardingDays: packageDays}}))
    }

    return (
        <>
            <UserLayout pageTitle={`Company`}>
                <CompanyPageLayout sectionTitle={`Onboarding package - ${packagePayload.name}`}>
                    {loadingPackage ? 
                        <Preloader />
                    :
                        displayForm && <div className='w-full lg:w-8/12 xl:w-7/12 p-[20px'>
                        <div className='my-1 w-full'>
                            <TextField
                                inputLabel="Title" 
                                fieldId="task" 
                                inputType="text" 
                                preloadValue={packagePayload.name || ''}
                                hasError={validationErrors && validationErrors.name} 
                                returnFieldValue={(value)=>{setPackagePayload({...packagePayload, ...{name: value}})}}
                            />
                        </div>

                        <div className='my-1 w-full'>
                            <TextareaField
                                inputLabel="Description" 
                                fieldId="task-description" 
                                inputType="text" 
                                height={`100px`}
                                preloadValue={packagePayload.description || ''}
                                hasError={validationErrors && validationErrors.description} 
                                returnFieldValue={(value)=>{setPackagePayload({...packagePayload, ...{description: value}})}}
                            />
                        </div>

                        <div className=''>
                            <div className='mt-3 mb-1'>
                                <label className={`cursor-text bg-transparent text-sm z-10 font-[550] transition duration-200  
                                text-gray-800 block mb-[5px]`}>
                                    Onboarding Days
                                </label>
                                <label className={`cursor-text bg-transparent text-xs z-10 transition duration-200  
                                text-gray-800 block`}>
                                    Add days to this package and choose what items will be assigned to the employee for that day when they join the platform.
                                </label>
                            </div>

                            {packagePayload?.onboardingDays?.map((day, dayIndex)=>(
                                <div key={dayIndex} className={`my-3 p-4 rounded border relative`}>
                                    {dayIndex > 0 && <button onClick={()=>{removeDay(dayIndex)}} className='absolute top-[10px] right-[10px] flex items-center justify-center w-[25px] h-[25px] text-red-600 hover:text-red-800 hover:bg-red-100 rounded duration-200 transition text-xs'>
                                        <TrashIcon className={`w-[17px] h-[17px]`} />
                                    </button>}
                                    <h3 className='uppercase tracking-[0.2em] text-[12px] font-[500] text-verovian-purple '>Day {dayIndex + 1}</h3>

                                    {day.dayItems.length > 0 ? 
                                        <>
                                            <p className='mb-[10px] text-xs text-gray-600'>You can drag the items in the order they are required or use the arrows to re-order them.</p>
                                            <DragDropList 
                                                listItems={day.dayItems.map(dayItem => {
                                                    return {
                                                        ...dayItem,
                                                        ...{ItemTemplate: dayItemTemplates[dayItem.type]}
                                                    }
                                                })} 
                                                returnSorting={(list)=>{updateDay(dayIndex, 'dayItemsReSorting', list)}} 
                                                ItemTemplate={null} 
                                                removeItem={(itemIndex)=>{removePackageDayItem(dayIndex, itemIndex)}}
                                            />
                                        </>
                                        :
                                        <div className='py-2'>
                                            <p className='text-gray-500 text-xs'>No activities added for day {dayIndex + 1} yet. Please use the controls below add some activities.</p>
                                        </div>
                                    } 
                                    <div className='mt-[10px]'>
                                        <div>
                                            <Menu>
                                                <Menu.Button 
                                                    className={`inline-flex items-center gap-2 rounded bg-verovian-light-purple bg-opacity-70 border border-verovian-purple py-2 px-3 text-sm/6 font-[400] text-verovian-purple shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}
                                                >
                                                    Add Activities for<span className='font-[550]'>Day {dayIndex + 1}</span>
                                                </Menu.Button>
                                                <Menu.Items 
                                                    // transition
                                                    anchor="bottom end"
                                                    className="w-max absolute origin-top-right rounded-[8px] border border-black/5 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 bg-white p-[10px]"
                                                    style={{zIndex: 999}}
                                                >
                                                    <Menu.Item>
                                                        <button onClick={()=>{openDayActivityAddition(dayIndex, 'training')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                            Trainings
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button onClick={()=>{openDayActivityAddition(dayIndex, 'training-material')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                            Training Materials
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button onClick={()=>{openDayActivityAddition(dayIndex, 'review')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                            Review Forms
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button onClick={()=>{openDayActivityAddition(dayIndex, 'documentation')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                            Documentations
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button onClick={()=>{openDayActivityAddition(dayIndex, 'task')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                            Tasks
                                                        </button>
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className='mt-3 mb-1'>
                                <button onClick={()=>{addDay()}} className='rounded-[8px] mt-[12px] p-3 border border-dashed border-gray-300 w-full text-xs text-gray-500 text-center hover:border-gray-600 hover:text-gray-600 transition duration-200'>
                                    <span className='flex items-center justify-center gap-x-[5px] uppercase tracking-[0.2em] '>
                                    <PlusIcon className={`w-5 h-5`} />
                                    add onboarding day
                                    </span>
                                    click to set up a new onboarding day and it's activities
                                </button>
                            </div>
                        </div>

                        <div className='mt-[60px] flex flex-row-reverse items-center justify-between gap-x-[10px]'>
                            <div className='w-max'>
                                <FormButton buttonLabel="Save updates" buttonAction={()=>{pushPackage()}} processing={onboardingPackagesSelector.creatingOnboardingPackage} />
                            </div>
                            <div className='flex items-center gap-x-[10px]'>
                                {/* {(accountPermissions?.includes('*') || accountPermissions?.includes('onboardingPackages.*') || accountPermissions?.includes('onboarding-packages.delete')) && <button onClick={()=>{}} className='transition duration-200 hover:text-red-700 px-[18px] py-[10px] text-gray-600 rounded-[18px] hover:bg-gray-100'>
                                    <TrashIcon className={`w-5 h-5`} />
                                </button>} */}
                                {(accountPermissions?.includes('*') || accountPermissions?.includes('onboarding-packages.*') || accountPermissions?.includes('onboarding-packages.create')) && <button 
                                    onClick={()=>{duplicatePackage()}} 
                                    disabled={onboardingPackagesSelector.creatingOnboardingPackage}
                                    className='transition duration-200 hover:text-gray-700 px-[18px] py-[10px] flex items-center justify-center rounded-[8px] gap-x-[5px] bg-verovian-light-purple bg-opacity-30 border border-verovian-purple text-xs text-verovian-purple hover:bg-opacity-80 font-[500]'>
                                    <ClipboardIcon className={`w-5 h-5`} 
                                />
                                    {onboardingPackagesSelector.creatingOnboardingPackage ? 'Duplicating...' : 'Duplicate Package'}
                                </button>}
                            </div>
                        </div>
                    </div>}
                </CompanyPageLayout>
            </UserLayout>

            {activeDay > -1 && packagePayload.onboardingDays && <ModalLayout
                isOpen={trainingsPickerOpen} 
                closeModal={()=>{setTrainingsPickerOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Add Trainings'
                dialogIntro={`Select the trainings required for this onboarding day below`}
                maxWidthClass='max-w-xl'
            >
                <TrainingsPicker  
                    trainings={trainingsSelector?.trainings?.trainings}
                    selectedTrainings={packagePayload?.onboardingDays[activeDay]?.dayItems}
                    saveNewList={(list)=>{
                        console.log('trainings list', list)
                        updateDay(activeDay, 'dayItems', list)}
                    }
                    closeModal={()=>{setTrainingsPickerOpen(false)}}
                />
            </ModalLayout>}

            {activeDay > -1 && packagePayload.onboardingDays && <ModalLayout
                isOpen={documentsPickerOpen} 
                closeModal={()=>{setDocumentsPickerOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Add Documents'
                dialogIntro={`Select the documents required for this onboarding day below`}
                maxWidthClass='max-w-xl'
            >
                <DocumentsPicker  
                    documents={documentsSelector?.documents}
                    selectedDocuments={packagePayload?.onboardingDays[activeDay]?.dayItems}
                    saveNewList={(list)=>{updateDay(activeDay, 'dayItems', list)}}
                    closeModal={()=>{setDocumentsPickerOpen(false)}}
                />
            </ModalLayout>}

            {activeDay > -1 && packagePayload.onboardingDays && <ModalLayout
                isOpen={reviewFormsPickerOpen} 
                closeModal={()=>{setReviewFormsPickerOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Add Review Forms'
                dialogIntro={`Select the review forms that require completion for this onboarding day below`}
                maxWidthClass='max-w-xl'
            >
                <ReviewFormPicker  
                    forms={reviewsSelector?.reviewForms?.forms}
                    selectedForms={packagePayload?.onboardingDays[activeDay]?.dayItems}
                    saveNewList={(list)=>{updateDay(activeDay, 'dayItems', list)}}
                    closeModal={()=>{setReviewFormsPickerOpen(false)}}
                />
            </ModalLayout>}

            {activeDay > -1 && packagePayload.onboardingDays &&  <ModalLayout
                isOpen={tasksPickerOpen} 
                closeModal={()=>{setTasksPickerOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Add Tasks'
                dialogIntro={`Select the tasks that require completion for this onboarding day below`}
                maxWidthClass='max-w-xl'
            >
                <TasksPicker  
                    tasks={tasksSelector?.tasks?.tasks}
                    selectedTasks={packagePayload?.onboardingDays[activeDay]?.dayItems}
                    saveNewList={(list)=>{updateDay(activeDay, 'dayItems', list)}}
                    closeModal={()=>{setTasksPickerOpen(false)}}
                />
            </ModalLayout>}

            {activeDay > -1 && packagePayload.onboardingDays &&  <ModalLayout
                isOpen={trainingMaterialsPickerOpen} 
                closeModal={()=>{setTrainingMaterialsPickerOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Add materials' 
                dialogTitle='Add training materials'
                dialogIntro={`Select the training materials that require completion for this onboarding day below`}
                maxWidthClass='max-w-xl'
            >
                <TrainingMaterialsPicker
                    materials={trainingsSelector?.trainingMaterials?.materials}
                    selectedMaterials={packagePayload?.onboardingDays[activeDay]?.dayItems}
                    saveNewList={(list)=>{updateDay(activeDay, 'dayItems', list)}}
                    closeModal={()=>{setTrainingMaterialsPickerOpen(false)}}
                />
            </ModalLayout>}
        </>
    )
}

export default OnboardingPackageDetails