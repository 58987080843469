import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import { selfUpdateEmployee } from '../../../store/actions/employeeActions';
import TextField from '../../elements/form/TextField';
import FormButton from '../../elements/form/FormButton';

const NextOfKinUpdate = ({nokInfo}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const [nextOfKin, setNextOfKin] = useState(nokInfo || {});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!nextOfKin.name || nextOfKin.name === '') {
            errors.nokName = true
        }
        if(!nextOfKin.phone || nextOfKin.phone === '') {
            errors.nokPhone = true
        }
        if(!nextOfKin.email || nextOfKin.email === '') {
            errors.nokEmail = true
        }
        if(!nextOfKin.address || nextOfKin.address === '') {
            errors.nokAddress = true
        }

        setValidationErrors(errors)
        return errors
    }

    const update = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        dispatch(selfUpdateEmployee({
            nextOfKin: nextOfKin
        }))
    }
    return (
        <div>
            <p className='mt-3 text-gray-600 text-sm font-[500]'>Please Provide details of your next of Kin below</p>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Next of Kin Name" 
                    inputPlaceholder="Name" 
                    fieldId="nok-name" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={nextOfKin.name || ''}
                    hasError={validationErrors.nokName} 
                    returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{name: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Phone number" 
                    inputPlaceholder="Active phone number" 
                    fieldId="nok-phone" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={nextOfKin.phone || ''}
                    hasError={validationErrors.nokPhone} 
                    returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{phone: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Email address" 
                    inputPlaceholder="Active email address" 
                    fieldId="nok-email" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={nextOfKin.email || ''}
                    hasError={validationErrors.nokEmail} 
                    returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{email: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Address" 
                    inputPlaceholder="Current address of Next of Kin" 
                    fieldId="nok-address" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={nextOfKin.address || ''}
                    hasError={validationErrors.nokAddress} 
                    returnFieldValue={(value)=>{setNextOfKin({...nextOfKin, ...{address: value}})}}
                />
            </div>


            <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex flex-row-reverse items-center gap-x-4 justify-between'>
                <div className='w-6/12'>
                    <FormButton
                        buttonLabel='Save Next of Kin' 
                        buttonAction={()=>{update()}} 
                        processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default NextOfKinUpdate