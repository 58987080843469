import React, { useState } from 'react'
import Logo from '../../assets/img/ss-tt.png'
import TextField from '../../components/elements/form/TextField';
import { Link } from 'react-router-dom';
import FormButton from '../../components/elements/form/FormButton';
import { ERROR } from '../../store/types';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const RequestPasswordReset = () => {
    const [authPayload, setAuthPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
	const dispatch = useDispatch()
	
	const validateForm = () => {
        let errors = {}
        if (!authPayload.email || authPayload.email === '') {
            errors.email = true
        }

        setValidationErrors(errors)
        return errors
    }

	const [processing, setProcessing] = useState(false);
	const [requestSent, setRequestSent] = useState(false);

	const sendRequest = async () => {
		if (Object.values(validateForm()).includes(true)) {
			dispatch({
				type: ERROR,
				error: {response: {data: {
					message: 'Please check the highlighted fields'
				}}}
			});
			return
		}
		
		setProcessing(true)
	
		const requestPayload = {
			email: authPayload.email,
		}
	
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/auth/password-reset/request`, requestPayload)
			setProcessing(false)
			setRequestSent(true)
		} catch (error) {
			console.log(error.response)
			dispatch({
				type: ERROR,
				error: error
			});
			setProcessing(false)
		}
	}

	return (
		<>

			<div className="w-full flex items-center justify-center h-screen">
				<div className="w-4/12 p-10 rounded border">
					<Link to="/">
						<span className="flex items-center gap-x-2">
							<img src={Logo} width={100} alt="logo" />
							{/* <p className="tracking-[0.2em] font-medium mt-[10px]">HRMS</p> */}
						</span>
					</Link>

					<h3 className='font-montserrat font-medium text-gray-700 mt-8'>Forgot your password?</h3>
					<p className='mt-2 dark:text-gray-500 text-sm'>Please provide your registered work email below to get a new one</p>

					{requestSent && <p className='text-sm text-green-600 bg-green-100 border border-green-600 rounded-[8px] bg-opacity-20 border-opacity-50 p-[10px] mt-[10px]'>
						We have sent an email to you with a link to create a new password. Please check your email <span className='font-[550]'>({authPayload?.email || ""})</span> to proceed.
					</p>}
					<div className='mt-6 w-full'>
						<TextField
							inputLabel="Email Address" 
							inputPlaceholder="Your registered email address" 
							fieldId="email" 
							inputType="email" 
							hasError={validationErrors.email} 
							returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{email: value}})}}
						/>
					</div>

					<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full'>
						<FormButton 
							buttonLabel="Request password reset" 
							buttonAction={()=>{sendRequest()}} 
							processing={processing}
						/>
						{/* <Link to="/admin" className='block w-full p-4 bg-black text-white text-center text-md rounded-md transition duration-200 hover:bg-allawee-ink-navy'>Login to your account</Link> */}
					</div>
					
					<div className='animate__animated animate__fadeIn w-full text-center mt-3'>
						<p className='text-gray-400 text-sm'><Link href="/" className='text-blue-500 dark:text-kt-orange font-medium' to='password-reset'>Click here</Link> to go back to log in page</p>
					</div>
				</div>
			</div>

		</>
	);
}

export default RequestPasswordReset