import React from 'react'

const ModuleCardHorizontal = ({data}) => {
    return (
        <div className={`flex items-start gap-x-2 mb-2 cursor-pointer w-full`}>
          <div className='w-[80px]'>
            <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]' style={{
                  backgroundImage: `url(${(data?.coverImage || data?.module?.coverImage)}`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  position: 'relative'
                }}>
            </div>
          </div>
          <div className='w-full'>
            <p className='text-sm text-gray-700 font-medium'>{data?.title || data?.module?.title} <span className='text-xs text-gray-500'>({data?.estimatedTime} minutes)</span></p>
            <div className='my-1 w-[90%] relative'>
              <p className='text-xs truncate w-[90%]'>{data?.description || data?.module?.description}</p>
            </div>
          </div>
        </div>
      )
    }

export default ModuleCardHorizontal