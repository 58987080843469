import { FETCHING_SEARCH_RESULTS, SEARCH_ERROR, SEARCH_RESULTS } from "../types"

const initialState = {
    results: [],
    fetchingResults: false,
    searchError: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case FETCHING_SEARCH_RESULTS:
        return {
            ...state,
            fetchingResults:action.payload,
        }
        case SEARCH_RESULTS:
        return{
            ...state,
            fetchingResults:false,
            searchError: false,
            results: action.payload,
        }
        case SEARCH_ERROR:
        return{
            ...state,
            fetchingResults:false,
            searchError: action.payload 
        }
        default: return state
    }

}