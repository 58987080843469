import React from 'react'
import StarIcon from '../../elements/icons/StarIcon'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'

const DashboardNewsArticleCard = ({article}) => {
    // const departmentNames = article.departments && article.departments.length > 0 ? article.departments.map(dept => {return dept.name}).join(', ') : 'General'
    return (
        <div className='flex items-start justify-between gap-x-[10px]'>
            <div style={{
                width: '150px',
                height: '100px',
                backgroundImage: `url(${(article.coverImageUrl)}`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                position: 'relative'
            }}>
                {!article.published && <span className="absolute top-[10px] right-[10px] bg-black  rounded p-[5px] text-white bg-opacity-60 text-xs flex items-center gap-x-1">
                    Draft article
                </span>}
                {article.featured && <span className="absolute top-[10px] right-[10px] bg-black rounded p-[5px] text-yellow-500 bg-opacity-60 text-xs flex items-center gap-x-1">
                    <StarIcon className={`w-4 h-4`} />
                </span>}
            </div>
            <div className='w-full p-1'>
                <h3 className="text-sm mb-1 font-medium text-verovian-purple">{article.title}</h3>
                <div className='flex items-center gap-x-1 flex-wrap'>
                    {/* {article.authors.map((author, authorIndex)=>( */}
                        <p className="text-xs text-gray-400">{article.authors[0].name}{article.authors.length > 1 && <span> +{article.authors.length - 1 } more</span>}</p>
                    {/* ))} */}
                </div>
                <p className="text-xs mt-3 mb-2">{article.excerpt.substr(1,100)}{article.excerpt.length > 100 && '...'}</p>

                {/* <>
                    <p className='text-xs font-[500] mb-[5px] uppercase tracking-[0.1em]'>Departments</p>
                    <p className='text-xs text-gray-500'>
                        {departmentNames}
                    </p>
                </> */}

                {article.published ? <Link className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3' to={`/user/news/article/read/${article._id}`}>Read article <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link> 
                :
                <Link className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3' to={`article/edit/${article._id}`}>Edit article <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>
                }

            </div>
        </div>
    )
}

export default DashboardNewsArticleCard