import React, { useEffect, useState } from 'react'
import { authHeader, transactionTimeStamp, userDetails } from '../../../utils'
import Status from '../../elements/Status'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import { Menu } from '@headlessui/react'
import { updateEmployeeTaskAssignment } from '../../../store/actions/tasksActions'
import { useDispatch } from 'react-redux'
import { ERROR } from '../../../store/types'
import axios from 'axios'
import Preloader from '../../elements/Preloader'

const TaskAssignmentDetailsDialogContent = ({assignment}) => {
    const dispatch = useDispatch()
    const pushStatusUpdate = (status) => {
        dispatch(updateEmployeeTaskAssignment(assignment._id, {status: status}))
    }
    const [loading, setLoading] = useState(true);
    const [taskAssignment, setTaskAssignment] = useState(null);

    useEffect(() => {
        const fetchTaskAssignment = async () => {
            try {
                const headers = authHeader()
                let requestUrl = `task-assignments/${assignment._id}`
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        
                setTaskAssignment(response.data.data)
                setLoading(false)
            }
            catch(error){
                dispatch( {
                    type: ERROR,
                    error
                })
            }
        }

        fetchTaskAssignment()

        return () => {
            
        };
    }, [assignment._id, dispatch]);
    return (
        <div className='w-full bg-white relative'>
            {loading ? <Preloader /> :
                <>
                    <p className='text-sm font-550 text-black font-[500]'>{taskAssignment.task.title}</p>
                    <p className='text-xs mt-[10px]'>{taskAssignment.task.description}</p>
                    <div className='flex flex-row-reverse mt-[10px] justify-between'>
                        <p className='text-xs font-[550] pb-1 border-b border-gray-600'>Due Date: {transactionTimeStamp(taskAssignment.dueDate).date}</p>
                        <Status status={taskAssignment.status} />
                    </div>

                    {taskAssignment.task.taskItem && <p className='mt-[15px] text-xs tracking-[0.1em] pb-1 border-b w-max border-gray-400 font-[550]'>TASK ITEM</p>}

                    <div className='flex items-center justify-between mt-[10px]'>
                        {taskAssignment.task.taskItem.itemType === 'training' && <div>
                            <div className={`mb-4`}>
                                <div className='w-full h-[350px] rounded-[8px] bg-gray-100' style={{
                                    backgroundImage: `url(${taskAssignment.task.itemDetails?.coverImage})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}  />
                                <div className='w-full mt-[15px]'>
                                    <p className='text-sm text-gray-700 font-medium'>{taskAssignment.task.itemDetails?.title}</p>
                                    <div className='my-1'>
                                        <p className='text-xs'>{taskAssignment.task.itemDetails?.description?.length > 150 ? taskAssignment.task.itemDetails?.description?.slice(0, 150) + '...' : taskAssignment.task.itemDetails?.description}</p>
                                    </div>
                                </div>
                                <div className='w-full flex flex-row-reverse mt-[15px] gap-x-[8px]'>
                                    <Link to={`/user/trainings/training/${taskAssignment.task.itemDetails._id}/player`} className='flex items-center justify-center gap-x-[5px] text-white text-sm bg-verovian-purple rounded-[8px] px-[16px] py-[10px]'>
                                        Go to training
                                        <ArrowIcon className={`w-4 h-4 -rotate-90`} />
                                    </Link>
                                    <div className='relative w-max'>
                                        <Menu>
                                            <Menu.Button 
                                                className={`inline-flex items-center gap-2 rounded-[8px] bg-verovian-light-purple bg-opacity-70 border border-verovian-purple py-2 px-3 text-sm/6 font-[400] text-verovian-purple shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}
                                            >
                                                Update Status
                                            </Menu.Button>
                                            <Menu.Items 
                                                // transition
                                                anchor="top end"
                                                className="w-max absolute left-0 bottom-[50px] rounded-[8px] border border-black/5 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 bg-white p-[10px]"
                                                style={{zIndex: 999}}
                                            >
                                                <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('in_progress')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        In progress
                                                    </button>
                                                </Menu.Item>
                                                {userDetails().userType !== 'USER' && <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('cancelled')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Cancelled
                                                    </button>
                                                </Menu.Item>}
                                                {userDetails().userType !== 'USER' && <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('overdue')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Overdue
                                                    </button>
                                                </Menu.Item>}
                                                <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('done')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Done
                                                    </button>
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {taskAssignment.task.taskItem.itemType === 'review' && <div>
                            <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                                <div className='w-full'>
                                    <p className='text-sm text-gray-700 font-medium'>{taskAssignment.task.itemDetails.name}</p>
                                    <div className='my-1'>
                                        <p className='text-xs'>{taskAssignment.task.itemDetails.description.length > 150 ? taskAssignment.task.itemDetails.description.slice(0, 150) + '...' : taskAssignment.task.itemDetails.description}</p>
                                    </div>
                                    <div className='flex flex-row-reverse mt-[10px]'>
                                        <Status status={`pending`} />
                                    </div>
                                </div>
                                <div className='w-full flex flex-row-reverse mt-[15px] gap-x-[8px]'>
                                    <Link className='flex items-center justify-center gap-x-[5px] text-sm text-white bg-verovian-purple rounded-[8px] px-[16px] py-[10px]'>
                                        Submit Review
                                        <ArrowIcon className={`w-4 h-4 -rotate-90`} />
                                    </Link>
                                    <div className='relative w-max'>
                                        <Menu>
                                            <Menu.Button 
                                                className={`inline-flex items-center gap-2 rounded-[8px] bg-verovian-light-purple bg-opacity-70 border border-verovian-purple py-2 px-3 text-sm/6 font-[400] text-verovian-purple shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}
                                            >
                                                Update Status
                                            </Menu.Button>
                                            <Menu.Items 
                                                // transition
                                                anchor="top end"
                                                className="w-max absolute left-0 bottom-[50px] rounded-[8px] border border-black/5 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 bg-white p-[10px]"
                                                style={{zIndex: 999}}
                                            >
                                                <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('in_progress')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        In progress
                                                    </button>
                                                </Menu.Item>
                                                {userDetails().userType !== 'USER' && <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('done')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Cancelled
                                                    </button>
                                                </Menu.Item>}
                                                {userDetails().userType !== 'USER' && <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('done')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Overdue
                                                    </button>
                                                </Menu.Item>}
                                                <Menu.Item>
                                                    <button onClick={()=>{pushStatusUpdate('done')}} className="text-sm font-[500] flex w-full items-center gap-2 rounded-[8px] py-2 my-[5px] px-3 hover:bg-verovian-light-purple/50">
                                                        Done
                                                    </button>
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </>
            }
        </div>
    )
}

export default TaskAssignmentDetailsDialogContent