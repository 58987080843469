import React, { useEffect, useState } from 'react'
import DocumentsLayout from '../../../components/layout/DocumentsLayout'
import Preloader from '../../../components/elements/Preloader'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDocumentAssignments } from '../../../store/actions/documentActions'
import Pagination from '../../../components/elements/Pagination'
import EmptyState from '../../../components/elements/icons/EmptyState'
import DocumentAssignmentCard from '../../../components/partials/documents/DocumentAssignmentCard'
import { hasPermission } from '../../../utils'

const DocumentAssignments = () => {
    const dispatch = useDispatch()
    const documentsSelector = useSelector(state => state.documents)
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    useEffect(() => {
        dispatch(fetchDocumentAssignments(page, perPage))
        return () => {
            
        };
    }, [dispatch, page, perPage]);

    return (
        <DocumentsLayout sectionTitle={`Document Assignments`}>
        {documentsSelector.loadingDocuments ? 
            <Preloader />
            :
            <div className=''>
                <div className='w-full lg:flex flex-row gap-x-8'>
                    <div className='w-full pb-6 mx-auto mt-4'>
                        <div className='flex justify-between items-start mb-6'>
                            <div className='w-8/12'>
                                <p className='mt-2 text-sm'>
                                    Documents assigned to different employees and signing status. You can create a new assignment by clicking on "Assign Document". 
                                </p>
                            </div>

                            {hasPermission(['*', 'documents.*','documents.create']) && <Link to={`new-document`}>
                                <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                    {/* <PlusIcon className={`h-5 w-5`} /> */}
                                    Assign Document
                                </button>
                            </Link>}
                        </div>

                        {documentsSelector?.loadingDocuments && documentsSelector.loadingDocuments === true ? 
                            <Preloader preloadingText={'Loading departments... '} />
                            : 
                            <>
                                {documentsSelector?.documentAssignments?.assignments?.length > 0 ? <>
                                    <div className='grid grid-cols-2 xl:grid-cols-3 gap-2'>
                                        {documentsSelector?.documentAssignments?.assignments?.map((document, documentIndex)=>(<Link key={documentIndex} to={`read/${document._id}`}>
                                            <DocumentAssignmentCard assignment={document} />
                                        </Link>))}
                                    </div>
                                    <Pagination 
                                        pagination={{
                                            currentPage: page,
                                            perPage: perPage,
                                            totalItems: documentsSelector.documentAssignments?.total
                                        }}
                                        changePage={(value)=>{setPage(value)}}
                                        updatePerPage={(value)=>{setPerPage(value)}}
                                    />
                                </> 
                                : 
                                    <EmptyState emptyStateText={`No documents assigned yet.`} />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        }
        </DocumentsLayout>
    )
}

export default DocumentAssignments