import axios from "axios"
import { authHeader } from "../../utils"
import { FETCHING_SEARCH_RESULTS, SEARCH_ERROR, SEARCH_RESULTS } from "../types"


export const search = (term) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: FETCHING_SEARCH_RESULTS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/global-search/${term}`, { headers })
        
        dispatch({
            type: SEARCH_RESULTS,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: SEARCH_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const clearSearchResults = () => async (dispatch) => {    
    dispatch({
        type: SEARCH_RESULTS,
        payload: []
    })
}

