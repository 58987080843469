import React, { useRef } from 'react'
import SearchIcon from '../icons/SearchIcon'
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '../../../utils';
import { clearSearchResults, search } from '../../../store/actions/globalSearchActions';
import CloseIcon from '../icons/CloseIcon';
import Preloader from '../Preloader';
import EmptyState from '../icons/EmptyState';
import { UseOutsideClickCloser } from '../UseOutsideClickCloser';
import { useNavigate } from 'react-router-dom';

const GlobalSearchField = () => {
  const dispatch = useDispatch()
  const searchSelector = useSelector(state => state.search)
  const navigate = useNavigate()

  const performSearch = debounce((searchTerm) => {
    if(!searchTerm || searchTerm === '') {
      return
    }
    dispatch(search(searchTerm.trim()))
  })

  const resetSearch = () => {
    dispatch(clearSearchResults())
  }

  const collections = {
    departments: {
      label: "Review Forms", 
      link: '/user/reviews-management/review-forms/detail',
      type: 'page'
    },
    departments: {
      label: "Departments", 
      link: '/user/company/departments/details',
      type: 'page'
    },
    employees: {
      label: "Employees", 
      link: '/user/employees/profile',
      type: 'page'
    },
    designation: {
      label: "Departments", 
      link: '/user/company/designations',
      type: 'modal'
    },
    groups: {
      label: "Groups", 
      link: '/user/company/groups',
      type: 'page'
    },
    Shifts: {
      label: "Shifts", 
      link: '/user/company/shifts/details',
      type: 'page'
    },
    tasks: {
      label: "Tasks", 
      link: '/user/task-management',
      type: 'modal'
    },
    trainingmaterials: {
      label: "Training Materials", 
      link: '/user/trainings/training-materials',
      type: 'modal'
    },
    posts: {
      label: "News", 
      link: '/user/news/article/read',
      type: 'page'
    },
    trainings: {
      label: "Trainings", 
      link: '/user/trainings/training',
      type: 'page'
    },
    instructors: {
      label: "Instructors", 
      link: '/user/trainings/instructors',
      type: 'modal'
    },
    trainingmodules: {
      label: "Training Modules", 
      link: '/user/trainings/training-modules',
      type: 'page'
    },
    reviewforms: {
      label: "Review Forms", 
      link: '/user/reviews-management/review-forms', 
      type: 'page'
    },
    documentassignments: {
      label: "Document Assignments", 
      link: '', 
      type: 'modal'
    },
    documents: {
      label: "Documents", 
      link: '/user/documents/details',
      type: 'page'
    },
    holidays: {
      label: "Holidays", 
      link: '/user/leaves-holidays/holidays',
      type: 'modal'
    },
    onboardingpackages: {
      label: "Onboarding Packages", 
      link: '/user/company/onboarding-packages/details',
      type: 'page'
    },
  }

  
  const navigateToResult = (resultId, collection) => {
    console.log('collection -> ', collections[collection].link)

    const link = `${collections[collection].link}${collections[collection].type === 'page' ? `/${resultId}` : `?active=${resultId}`}`

    console.log('link -> ', link)
    navigate(link)
    resetSearch()
  }

  const totalResults = (results) => { 
    return results.reduce((accumulator, current) => accumulator+current.results.length, 0) 
  }

  const wrapperRef = useRef(null);
  UseOutsideClickCloser(wrapperRef, resetSearch);

  return (
    <div className='w-full relative'>
        <div className='bg-[#fff] flex items-center gap-x-3 rounded p-2 transition duration-200 w-[500px] border border-transparent focus-within:border-verovian-purple'>
            <div className='flex items-center justify-center'>
                <SearchIcon className={`w-7 h-7 text-verovian-purple text-opacity-40`} />
            </div>
            <input 
              placeholder='Search for anything' 
              onChange={(e)=>{performSearch(e.target.value)}} className='w-full placeholder:text-sm outline-none' />
        </div>
        {(searchSelector?.fetchingResults || searchSelector?.results?.results?.length > 0) && 
          <div ref={wrapperRef} className='w-full bg-white z-50 border border-gray-300 absolute left-0 top-[50px] p-[20px] shadow-lg shadow-black/10 h-[600px] overflow-y-scroll'>
            <div className='w-full flex flex-row-reverse'>
              <button onClick={()=>{resetSearch()}} className='text-gray-400 hover:text-gray-600 transition duration-200'><CloseIcon className={`w-4 h-4`} /> </button>
            </div>

            <div className='w-full'>
              {searchSelector?.fetchingResults ? 
                <Preloader preloadingText={'searching... '} />
                :
                <div className='w-full'>
                  {searchSelector?.results?.results?.length > 0 ? 
                    <div className='w-full'>
                      <p className='text-sm font-[500] pb-[5px] border-b w-full mb-[15px]'>Search Results <span className='text-xs font-[400]'>({totalResults(searchSelector?.results?.results)} total results)</span></p>
                      {searchSelector?.results?.results?.map((collection, collectionIndex) => (
                        <div key={collectionIndex} className='w-full pb-[5px] mb-[15px] border-b'>
                          <p className='text-[12px] font-[400] text-gray-800 mb-[10px]'><span className='uppercase tracking-[0.1em]'>{collections[collection.collection]?.label || collection.collection}</span> - <span className='tracking-normal'>{collection.results.length} result(s)</span></p>

                         {collection.results.map((result, resultIndex)=>(
                          <div onClick={()=>{navigateToResult(result._id, collection.collection)}} key={resultIndex} className='w-full'>
                            <div className='w-full rounded px-[10px] py-[5px] hover:bg-purple-50 duration-200 transition cursor-pointer'>
                              {(result.name || result.title) && <p className='text-[12px] font-[500] text-gray-800 mb-[5px]'>{result.name || result.title}</p>}
                              {(result.fullName) && <p className='text-[12px] font-[500] text-gray-800 mb-[5px]'>{result.fullName || result.fullName}</p>}
                              {result.email && result.phone && <p className='text-[10px] font-[500] text-gray-400 pb-[5px] mb-[5px]'>{result.email}, {result.phone}</p>}
                              {result.description && <p className='text-[10px] font-[500] text-gray-400 pb-[5px] mb-[5px]'>{result.description.substr(0, 100)}{result.description.length > 100 && '...'}</p>}
                              {result.excerpt && <p className='text-[10px] font-[500] text-gray-400 pb-[5px] mb-[5px]'>{result.excerpt.substr(0, 100)}{result.excerpt.length > 100 && '...'}</p>}
                              {result.about && <p className='text-[10px] font-[500] text-gray-400 pb-[5px] mb-[5px]'>{result.about.substr(0, 100)}{result.about.length > 100 && '...'}</p>}
                            </div>
                          </div>
                         ))}

                        </div>
                      ))}
                    </div>
                    :
                    <div>
                      <EmptyState emptyStateText={`No results found`} />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
    </div>
  )
}

export default GlobalSearchField