import React, { useEffect, useState } from 'react'
import UserLayout from '../../components/layout/UserLayout'
import { authHeader } from '../../utils';
import { useDispatch } from 'react-redux';
import { ERROR } from '../../store/types';
import axios from 'axios';
import Preloader from '../../components/elements/Preloader';
import EmptyState from '../../components/elements/icons/EmptyState';
import moment from 'moment';
import OnboardingDayItemCard from '../../components/partials/onboarding-packages/OnboardingDayItemCard';

const UserOnboarding = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [onboardingPackage, setOnboardingPackage] = useState(null);
    useEffect(() => {
        
        const fetchEmployeeDetails = async () => {    
            try{
                const headers = authHeader()
                let requestUrl = `user/employee-profile?expand=designation,department,documents,invitation`
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        
                setEmployeeDetails(response.data.data)
                // setLoading(false)
                if(response.data.data.onboardingPackage){
                    getOnboardingPackage(response.data.data.onboardingPackage, response.data.data._id)
                } else {
                    setLoading(false)
                }
            }
            catch(error){
                dispatch( {
                    type: ERROR,
                    error
                })
            }
        }

        const getOnboardingPackage = async (packageId, employeeId) => {
            try {
                const headers = authHeader()
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/onboarding-packages/employee/${employeeId}/${packageId}`, { headers })
                setOnboardingPackage(response.data.data)
                localStorage.setItem("onboardingCompletion", JSON.stringify(response.data.data.completion));
                setLoading(false)
            } catch (error) {
                dispatch({
                    type: ERROR,
                    error: error
                })
                setLoading(false) 
            }
        }

        fetchEmployeeDetails()

        return () => {
            
        };
    }, [dispatch]);

   
    return (
        <UserLayout pageTitle={`User Onboarding`}>
            {loading ? 
                <Preloader preloadingText={`Fetching your onboarding package`} />
                :
                <>
                    {onboardingPackage ? 
                        <>
                            <div className='w-1/2 mb-[20px]'>
                                <h3 className='font-[550] text-verovian-purple'>{onboardingPackage.name}</h3>
                                <p className='text-sm mt-[5px] text-gray-600'>{onboardingPackage.description}</p>
                            </div>
                            <div className='w-full overflow-x-scroll min-h-[800px] h-inherit border border-verovian-purple/10 rounded-[8px] bg-verovian-light-purple/40 flex flex-shrink-0 items-start gap-x-[20px] px-[20px]'>
                                
                                    {onboardingPackage.onboardingDays.map((day, dayIndex) => (
                                        <div key={dayIndex} className='mt-[10px] w-[350px] pt-[20px]'>
                                            <h3 className='uppercase tracking-[0.2em] text-[12px] font-[500] text-verovian-purple mb-[20px]'>Day {dayIndex + 1} 
                                            - <span className='text-[10px] font-[400] text-gray-500'>{moment(employeeDetails.onboardingDays[dayIndex + 1]).format('ddd, DD MMM YYYY')}</span>
                                            </h3>
                                            {day.dayItems.map((item, itemIndex) => (
                                                <div key={itemIndex} className='w-[350px]'>
                                                    <OnboardingDayItemCard item={item} hideLink={false} />
                                                </div>
                                                
                                            ))}
                                        </div>
                                    ))}
                                    {/* {onboardingPackage.onboardingDays.map((day, dayIndex) => (
                                        <div key={dayIndex} className='mt-[10px] w-[300px]'>
                                            <h3 className='uppercase tracking-[0.2em] text-[12px] font-[500] text-verovian-purple '>Day {dayIndex + 1}</h3>
                                            
                                        </div>
                                    ))} */}
                            </div>
                        </>
                    :
                        <EmptyState emptyStateText={`An onboarding package was not assigned to you, please contact an administrator to assign you one`} />
                    }
                </>
            }
        </UserLayout>
    )
}

export default UserOnboarding