import axios from "axios"
import { authHeader } from "../../utils"
import { CREATE_DOCUMENT, CREATING_DOCUMENT, DOCUMENTS_ERROR, GETTING_DOCUMENTS, GET_DOCUMENTS, GET_DOCUMENT_ASSIGNMENTS, SIGNING_DOCUMENT, SIGN_DOCUMENT, UPDATE_DOCUMENT, UPDATING_DOCUMENT} from "../types"

export const createDocument = (designationPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_DOCUMENT,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents`, designationPayload, { headers })
        
        dispatch({
            type: CREATE_DOCUMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DOCUMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const clearCreatedDocument = () => async (dispatch) => {    
    dispatch({
        type: CREATE_DOCUMENT,
        payload: null
    })
}

export const signDocument = (assignmentId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: SIGNING_DOCUMENT,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/document-assignments/sign/${assignmentId}`, payload, { headers })
        
        dispatch({
            type: SIGN_DOCUMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DOCUMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const clearSignedDocument = () => async (dispatch) => {    
    dispatch({
        type: SIGN_DOCUMENT,
        payload: null
    })
}

export const fetchDocuments = (filters, page, perPage) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'documents?expand=document'

        dispatch( {
            type: GETTING_DOCUMENTS,
            payload: true
        })

        if(filters && filters !== '') {
            requestUrl += `&${filters}`
        }

        if(page && page !== ''){
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}page=${page}`
        }
        
        if(perPage && perPage !== ''){
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}perPage=${perPage}`
        }


        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        // const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}?expand=custodian&limit=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })

        dispatch( {
            type: GET_DOCUMENTS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: DOCUMENTS_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

export const fetchDocumentAssignments = (page, perPage, filters) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'documents/document-assignments?expand=document,employee,createdBy'

        if(page && page !== ''){
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage !== ''){
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        if(filters && filters !== ''){
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}=${filters}`
        }

        dispatch( {
            type: GETTING_DOCUMENTS,
            payload: true
        })

        // let appliedFilters =''
        // if (filters && filters!==null && filters.length > 0) {
        //     appliedFilters = parseFilters(filters, action, 'WALLETS')
        // }


        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        // const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}?expand=custodian&limit=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })

        dispatch( {
            type: GET_DOCUMENT_ASSIGNMENTS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: DOCUMENTS_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

export const updateDocument = (documentId, documentPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_DOCUMENT,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/update/${documentId}`, documentPayload, { headers })
        
        dispatch({
            type: UPDATE_DOCUMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DOCUMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const clearUpdatedDocument = (documentId, documentPayload) => async (dispatch) => {
    dispatch({
        type: UPDATE_DOCUMENT,
        payload: null
    })
}

export const updateDocumentAssignment = (id, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_DOCUMENT,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/documents/document-assignments/${id}`, payload, { headers })
        
        dispatch({
            type: UPDATE_DOCUMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DOCUMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}


