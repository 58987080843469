import React from 'react'
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
// import ArrowIcon from '../../elements/icons/ArrowIcon'
import CheckIcon from '../../elements/icons/CheckIcon'
import moment from 'moment'

const DocumentAssignmentCard = ({assignment}) => {
  return (
    <div className='w-full h-full bg-white rounded-[8px] hover:border-gray-200 transition duration-200 cursor-pointer border'>
        <div className='w-full px-1 py-3 flex items-start gap-x-1 '>
            <div className='w-[60px] h-inherit pt-1'>
                <img src={DocumentSvgIcon} alt="" />
            </div>
            <div className='w-full'>
                <p className='text-sm font-medium text-verovian-purple'>{assignment.document.name}</p>
                <p className='text-xs text-gray-500'>{assignment.document.description}</p>
                <p className='text-xs text-gray-800 font-[550] mt-[10px]'>Assigned to: {assignment?.employee?.fullName}</p>
                {/* <p className='text-xs text-gray-500'>Assigned by: {document.createdBy?.fullName}</p> */}
                <p className='text-xs text-gray-500 mt-3'>Assigned {new Date(assignment.createdAt)?.toLocaleDateString()}</p>

                {assignment.document.providedBy === 'employee' && !assignment.documentUrl && 
                    <p className='text-xs text-yellow-700 mt-[10px]'>Upload pending</p>
                }

                {assignment.document.providedBy === 'employee' && assignment.documentUrl && assignment.documentUrl !== '' && 
                    <p className='text-xs text-green-600 mt-[10px] flex items-center gap-x-[5px]'><CheckIcon className={`w-4 h-4`} />Uploaded by employee</p>
                }

                {assignment.document.providedBy === 'admin' && assignment.document.requiresSigning && !assignment.documentSigning && 
                    <p className='text-xs text-yellow-700 mt-[10px]'>Signature pending</p>
                }

                {assignment.document.providedBy === 'admin' && assignment.document.requiresSigning && assignment.documentSigning && assignment.documentSigning !== '' &&
                    <p className='text-xs text-green-600 mt-[10px] flex items-center gap-x-[5px]'><CheckIcon className={`w-4 h-4`} />Signed by employee</p>
                }

                {assignment.document.requiresDocumentNumber && assignment.documentNumber && assignment.documentNumber !== '' &&
                    <p className='text-xs text-gray-600 mt-[10px] flex items-center gap-x-[5px]'>Doc Number: {assignment.documentNumber}</p>
                }

                {assignment.document.requiresExpiry && assignment.documentExpiry && assignment.documentExpiry !== '' &&
                <div className='flex items-center gap-x-[10px]'>
                    <p className='text-xs text-gray-600 mt-[10px] flex items-center gap-x-[5px]'>Expiry: {moment(assignment.expiry).format('DD-MM-YYYY')}</p>
                    {new Date(assignment.documentExpiry) <= new Date() && <span className='block p-[3px] mt-[5px] text-[10px] text-red-600 border-red-600 rounded border bg-red-100 bg-opacity-40 uppercase'>Expired</span>}
                </div>
                }
            </div>
        </div>
    </div>
  )
}

export default DocumentAssignmentCard