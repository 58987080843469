import React from 'react';

const CircularGauge = ({
  percentage,
  completeColor,
  incompleteColor,
  size = 100,
  strokeWidth = 10,
}) => {
  // Ensure the percentage is within 0 to 100
  const validPercentage = Math.max(0, Math.min(percentage, 100));

  // Circle dimensions
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Stroke offset for the filled part of the circle
  const offset = circumference - (validPercentage / 100) * circumference;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={incompleteColor}
        strokeWidth={strokeWidth}
      />
      {/* Foreground Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={completeColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotate to start from the top
      />
      {/* Percentage Text */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={size * 0.35}
        fontWeight="medium"
        fill={completeColor}
      >
        {Math.round(validPercentage)}%
      </text>
    </svg>
  );
};

export default CircularGauge;
