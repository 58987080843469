import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Preloader from '../../elements/Preloader'
import EmptyState from '../../elements/icons/EmptyState'
import AssignedTaskCard from '../tasks/AssignedTaskCard'
import ModalLayout from '../../layout/ModalLayout'
import TaskDetails from '../tasks/TaskDetails'
import { authHeader } from '../../../utils'
import { TASKS_ERROR } from '../../../store/types'
import axios from 'axios'

const PendingTasks = () => {

    const dispatch = useDispatch()
    const [fetchingTasks, setFetchingTasks] = useState(true);
    const [tasks, setTasks] = useState(null);

    useEffect(() => {
        const fetchEmployeePendingTasks = async() => {  
            console.log('fetching...')  
            try{

                const headers = authHeader()
                let requestUrl = 'task-assignments?expand=assignee,task&perPage=3&page=1&status=todo'
    
        
                setFetchingTasks(true)
        
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        
                setTasks(response.data.data.assignments)
                setFetchingTasks(false)
            }
            catch(error){
                setFetchingTasks(false)
                dispatch( {
                    type: TASKS_ERROR,
                    // payload: error.response.data,
                    error
                })
            }
        }

        fetchEmployeePendingTasks()

        return () => {
            
        };
    }, [dispatch]);

    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const openTask = (task) => {
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }
    

    return (
        <>
            <div>
                {fetchingTasks ? 

                    <Preloader preloadingText={`loading tasks`} />
                    :

                    <div className='w-full mt-[5px]'>
                        {(tasks?.length === 0) ? 
                            <EmptyState 
                                emptyStateText={`There are no tasks assigned to you at the moment.' `} 
                            />
                        :
                        <>
                            <div className='grid lg:grid-cols-1 gap-2'>
                                
                                {tasks?.map((assignment, assignmentIndex)=>(<div key={assignmentIndex} className='w-full'>
                                    <AssignedTaskCard assignment={assignment} openTask={()=>{openTask(assignment)}} />
                                </div>))}
                            </div>
                        </>
                        }
                    </div>
                }
            </div>

            {activeOpenTask && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskDetails task={activeOpenTask} />
            </ModalLayout>}
        </>
    )
}

export default PendingTasks