import React, { useEffect, useState } from 'react'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import { Link } from 'react-router-dom'
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDocuments } from '../../../store/actions/documentActions'
import Preloader from '../../../components/elements/Preloader'
import DocumentsLayout from '../../../components/layout/DocumentsLayout'
import Pagination from '../../../components/elements/Pagination'
import EmptyState from '../../../components/elements/icons/EmptyState'

const Documents = () => {
  const dispatch = useDispatch()
  const documentsSelector = useSelector(state => state.documents)
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    dispatch(fetchDocuments('', page, perPage ))
    return () => {
      
    };
  }, [dispatch, page, perPage]);
  return (
    <DocumentsLayout sectionTitle={`Document templates`}>
      {documentsSelector.loadingDocuments ? 
        <Preloader />
        :
        <div className=''>
          <div className='w-full lg:flex flex-row gap-x-8'>
            <div className='w-full pb-6 mx-auto mt-4'>
              <div className='flex justify-between items-start mb-6'>
                <div className='w-8/12'>
                  <p className='mt-2 text-sm'>
                    Create and manage your document templates here. Document templates can be assigned to your employees at any time to download, print and/or sign as part of onboarding or other processes. 
                  </p>
                </div>

                <Link to={`new-document`}>
                  <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                    <PlusIcon className={`h-5 w-5`} />
                    Create a document
                  </button>
                </Link>
              </div>

              {documentsSelector.documents?.documents?.length > 0 ? <>
                <div className='grid grid-cols-2 xl:grid-cols-3 gap-2'>
                  {documentsSelector.documents?.documents?.map((document, documentIndex)=>(<Link key={documentIndex} to={`details/${document._id}`}>
                    <div className='w-full px-1 py-3 flex items-start gap-x-1 border bg-white rounded-[8px] hover:border-gray-200 transition duration-200 cursor-pointer'>
                      <div className='w-[100px] h-inherit pt-1'>
                        <img src={DocumentSvgIcon} alt="" />
                      </div>
                      <div className='w-full'>
                        <p className='text-sm font-medium text-verovian-purple'>{document.name}</p>
                        <p className='text-xs text-gray-500'>{document.description}</p>
                        <p className='text-xs text-gray-500 mt-3'>Created {new Date(document.createdAt)?.toLocaleDateString()}</p>
                      </div>
                    </div>
                  </Link>))}
                </div>

                <Pagination 
                  pagination={{
                    currentPage: page,
                    perPage: perPage,
                    totalItems: documentsSelector?.documents?.total
                  }}
                  changePage={(value)=>{setPage(value)}}
                  updatePerPage={(value)=>{setPerPage(value)}}
                />
              </> : 
                  <EmptyState emptyStateText={`No documents created yet.`} />
              }

            </div>
          </div>
        </div>
      }
    </DocumentsLayout>
  )
}

export default Documents