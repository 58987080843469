import axios from "axios"
import { authHeader } from "../../utils"
import { CREATE_REVIEW, CREATE_REVIEW_FORM, CREATE_REVIEW_QUESTION, CREATING_REVIEW, CREATING_REVIEW_FORM, CREATING_REVIEW_QUESTION, DELETE_REVIEW_FORM, DELETE_REVIEW_QUESTION, DELETING_REVIEW_FORM, GET_EMPLOYEE_REVIEWS, GET_REVIEW_FORMS, GET_REVIEW_QUESTIONS, GET_REVIEW_SCHEDULES, GET_REVIEWS, GETTING_REVIEW_FORMS, GETTING_REVIEW_QUESTIONS, GETTING_REVIEW_SCHEDULES, GETTING_REVIEWS, REVIEWS_ERROR, UPDATE_REVIEW_FORM, UPDATE_REVIEW_QUESTION, UPDATING_REVIEW_FORM, UPDATING_REVIEW_QUESTION } from "../types"


export const createReviewQuestion = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_REVIEW_QUESTION,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews/review-questions`, payload, { headers })
        
        dispatch({
            type: CREATE_REVIEW_QUESTION,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const getReviewQuestions = (page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()
        let url = `reviews/review-questions`
        if((page || page===0) && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        if((perPage || perPage === 0) && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: GETTING_REVIEW_QUESTIONS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
        // console.log(response.data.data)
        dispatch({
            type: GET_REVIEW_QUESTIONS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const updateReviewQuestion = (id, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_REVIEW_QUESTION,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/reviews/review-questions/${id}`, payload, { headers })
        
        dispatch({
            type: UPDATE_REVIEW_QUESTION,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const deleteReviewQuestion = (id) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_REVIEW_QUESTION,
            payload: true
        })
        
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/reviews/review-questions/${id}`, { headers })
        
        dispatch({
            type: DELETE_REVIEW_QUESTION,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const clearCreatedReviewQuestion = () => async (dispatch) => {    
    dispatch({
        type: CREATE_REVIEW_QUESTION,
        payload: null
    })
}

export const clearUpdatedReviewQuestion = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_REVIEW_QUESTION,
        payload: null
    })
}

export const clearDeletedReviewQuestion = () => async (dispatch) => {    
    dispatch({
        type: DELETE_REVIEW_QUESTION,
        payload: null
    })
}

export const createReviewForm = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_REVIEW_FORM,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews/review-forms`, payload, { headers })
        
        dispatch({
            type: CREATE_REVIEW_FORM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const getReviewForms = (page, perPage, filter) => async (dispatch) => {    
    try{
        const headers = authHeader()
        let url = `reviews/review-forms`
        
        if((page || page===0) && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if((perPage || perPage === 0) && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        if(filter && filter !== ''){
            url += `${url.includes('?') ? '&' : '?'}${filter}`
        }

        dispatch({
            type: GETTING_REVIEW_FORMS,
            payload: true
        })

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
        // console.log(response.data.data)
        dispatch({
            type: GET_REVIEW_FORMS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const updateReviewForm = (id, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_REVIEW_FORM,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/reviews/review-forms/${id}`, payload, { headers })
        
        dispatch({
            type: UPDATE_REVIEW_FORM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const deleteReviewForm = (id) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: DELETING_REVIEW_FORM,
            payload: true
        })
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/reviews/review-forms/${id}`, { headers })
        
        dispatch({
            type: DELETE_REVIEW_FORM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const clearCreatedReviewForm = () => async (dispatch) => {    
    dispatch({
        type: CREATE_REVIEW_FORM,
        payload: null
    })
}

export const clearUpdatedReviewForm = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_REVIEW_FORM,
        payload: null
    })
}

export const clearDeletedReviewForm = () => async (dispatch) => {    
    dispatch({
        type: DELETE_REVIEW_FORM,
        payload: null
    })
}

export const createReview = (trainingPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_REVIEW,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews/employee-reviews`, trainingPayload, { headers })
        
        dispatch({
            type: CREATE_REVIEW,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const getReviews = (page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `reviews/employee-reviews?expand=reviewer,employee,form`
        if(page && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        if(perPage && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: GETTING_REVIEWS,
            payload: true
        })
        
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
        dispatch({
            type: GET_REVIEWS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const getEmployeeReviews = (employeeId, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `reviews/employee-reviews/employee/${employeeId}?expand=reviewer,employee,form`

        if(page && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        
        if(perPage && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: GETTING_REVIEWS,
            payload: true
        })
        
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
        dispatch({
            type: GET_EMPLOYEE_REVIEWS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}

export const clearCreatedReview = () => async (dispatch) => {    
    dispatch({
        type: CREATE_REVIEW,
        payload: null
    })
}


export const createReviewSchedule = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_REVIEW,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews/schedules`, payload, { headers })
        
        dispatch({
            type: CREATE_REVIEW,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}


export const getReviewSchedules = (page, perPage, filters) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `reviews/schedules?expand=reviewer,employee,form`
        if(page && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        if(perPage && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }
        if(filters && filters !== ''){
            url += `${url.includes('?') ? '&' : '?'}${filters}`
        }

        dispatch({
            type: GETTING_REVIEW_SCHEDULES,
            payload: true
        })
        
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
        // console.log(response.data.data)
        dispatch({
            type: GET_REVIEW_SCHEDULES,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: REVIEWS_ERROR,
            error
        })
    }
}
