import React, { useState } from 'react'
import TextField from '../../elements/form/TextField'
import FormButton from '../../elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import { selfUpdateEmployee } from '../../../store/actions/employeeActions'

const BankDetailsUpdate = ({bankInfo}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const [bankDetails, setBankDetails] = useState(bankInfo || {});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
		let errors = {}
		if(!bankDetails.accountNumber || bankDetails.accountNumber === '') {
			errors.accountNumber = true
		}
		if(!bankDetails.accountName || bankDetails.accountName === '') {
			errors.accountName = true
		}
		if(!bankDetails.bank || bankDetails.bank === '') {
			errors.bank = true
		}
		if(!bankDetails.sortCode || bankDetails.sortCode === '') {
			errors.sortCode = true
		}

        setValidationErrors(errors)
        return errors
	}

    const update = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        dispatch(selfUpdateEmployee({
            bankDetails: bankDetails
        }))
    }

    return (
        <div className='w-full'>
            <p className='mt-4 text-gray-600 text-sm mb-2'>Provide your valid bank details below.</p>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Account Number" 
                    inputPlaceholder="Your account number" 
                    fieldId="account-number" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={bankDetails.accountNumber || ''}
                    hasError={validationErrors.accountNumber} 
                    returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{accountNumber: value}})}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Account Name" 
                    inputPlaceholder="Valid account name" 
                    fieldId="account-number" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={bankDetails.accountName || ''}
                    hasError={validationErrors.accountName} 
                    returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{accountName: value}})}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Bank Name" 
                    inputPlaceholder="Bank name" 
                    fieldId="account-number" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={bankDetails.bank || ''}
                    hasError={validationErrors.bank} 
                    returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{bank: value}})}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Sort Code" 
                    inputPlaceholder="Bank Sort Code" 
                    fieldId="sort-code" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={bankDetails.sortCode || ''}
                    hasError={validationErrors.bank} 
                    returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{sortCode: value}})}}
                />
            </div>
            
            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="National Insurance Number" 
                    inputPlaceholder="National Insurance Number" 
                    fieldId="nin" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={bankDetails.nationalInsuranceNumber || ''}
                    hasError={validationErrors.bank} 
                    returnFieldValue={(value)=>{setBankDetails({...bankDetails, ...{nationalInsuranceNumber: value}})}}
                />
            </div>

            <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex flex-row-reverse items-center gap-x-4 justify-between'>
                <div className='w-6/12'>
                    <FormButton 
                        buttonLabel='Save Bank Details' 
                        buttonAction={()=>{update()}} 
                        processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default BankDetailsUpdate