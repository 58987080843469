import React, { useState } from 'react'
import TextField from '../../elements/form/TextField';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from '../../elements/form/FormButton';
import { ERROR } from '../../../store/types';
import { selfUpdateEmployee } from '../../../store/actions/employeeActions';

const EmergencyContactUpdate = ({emergencyContactInfo}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const [emergencyContact, setEmergencyContact] = useState(emergencyContactInfo || {});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!emergencyContact.name || emergencyContact.name === '') {
            errors.emergencyContactName = true
        }
        if(!emergencyContact.phone || emergencyContact.phone === '') {
            errors.emergencyContactPhone = true
        }
        if(!emergencyContact.email || emergencyContact.email === '') {
            errors.emergencyContactEmail = true
        }
        if(!emergencyContact.address || emergencyContact.address === '') {
            errors.emergencyContactAddress = true
        }

        setValidationErrors(errors)
        return errors
    }

    const update = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        dispatch(selfUpdateEmployee({
            emergencyContact: emergencyContact
        }))
    }
    return (
        <div>
            <p className='mt-3 text-gray-600 text-sm font-[500]'>Please Provide details of your emergency contact below</p>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Emergency Contact Name" 
                    inputPlaceholder="Name" 
                    fieldId="emergencyContact-name" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={emergencyContact.name || ''}
                    hasError={validationErrors.emergencyContactName} 
                    returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{name: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Phone number" 
                    inputPlaceholder="Active phone number" 
                    fieldId="emergencyContact-phone" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={emergencyContact.phone || ''}
                    hasError={validationErrors.emergencyContactPhone} 
                    returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{phone: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Email address" 
                    inputPlaceholder="Active email address" 
                    fieldId="emergencyContact-email" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={emergencyContact.email || ''}
                    hasError={validationErrors.emergencyContactEmail} 
                    returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{email: value}})}}
                />
            </div>

            <div className='mt-3 w-full'>
                <TextField
                    inputLabel="Address" 
                    inputPlaceholder="Current address of emergency contact" 
                    fieldId="emergencyContact-address" 
                    inputType="text" 
                    disabled={false}
                    preloadValue={emergencyContact.address || ''}
                    hasError={validationErrors.emergencyContactAddress} 
                    returnFieldValue={(value)=>{setEmergencyContact({...emergencyContact, ...{address: value}})}}
                />
            </div>


            <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full flex flex-row-reverse items-center gap-x-4 justify-between'>
                <div className='w-6/12'>
                    <FormButton
                        buttonLabel='Save Emergency Contact' 
                        buttonAction={()=>{update()}} 
                        processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmergencyContactUpdate