import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { signDocument } from "../../../store/actions/documentActions";
import FormButton from "../../elements/form/FormButton";
import { SIGNING_DOCUMENT } from "../../../store/types";

const SignatureUploader = ({assignmentId, employeeId, documentId}) => {
    const dispatch = useDispatch()
    const documentsSelector = useSelector(state => state.documents)
    const sigCanvasRef = useRef(null);

    const clearSignature = () => {
        sigCanvasRef.current?.clear();
    };

    const uploadSignature = async () => {
        try {
        // Get the signature as a base64-encoded image
        const signatureDataURL = sigCanvasRef.current?.toDataURL("image/png");
        if (!signatureDataURL) {
            alert("Please draw a signature before uploading.");
            return;
        }

        // Convert base64 to Blob
        const signatureBlob = await fetch(signatureDataURL).then((res) => res.blob());

        // Create a FormData object for the upload
        const formData = new FormData();
        formData.append("file", signatureBlob, `${documentId}-${employeeId}-signature.png`);
	    const headers = new Headers();

        dispatch({
            type: SIGNING_DOCUMENT,
            payload: true
        })
        // Upload to the backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
            method: "POST",
            headers,
            body: formData,
        });

        if (response.ok) {
            const data = await response.json();
            // return data

            dispatch(signDocument(assignmentId, {
                signature: data.data.file
            }))
            // alert(`Signature uploaded successfully! Document Link: ${data.link}`);
        } else {
            alert("Failed to upload signature.");
        }
        } catch (error) {
        console.error("Error uploading signature:", error);
        alert("An error occurred while uploading the signature.");
        }
    };

    return (
        <div>
            <div className="w-1/2">
                <h1 className="font-medium text-verovian-purple">Sign Document</h1>
                <p className="mt-[5px] text-sm">Please sign your signature in the box provided below. When you are satisfied, click on "Upload signature" to complete document signing</p>
            </div>
            <div className="border mt-[20px] bg-white w-max mb-[20px]">
                <SignatureCanvas
                    ref={sigCanvasRef}
                    canvasProps={{ width: 500, height: 200, className: "signature-canvas" }}
                />
            </div>
            <div className="flex items-center gap-x-[10px]">
                <div className="w-max">
                    <FormButton buttonLabel={`Upload Signature`} buttonAction={uploadSignature} processing={documentsSelector.signingDocument} />
                </div>

                <button onClick={clearSignature} className="w-max text-sm font-[550] p-[10px] bg-white rounded-[8px] hover:text-gray-400 transition duration-200">Clear Signature</button>
                {/* <button onClick={uploadSignature} className="p-[15px] rounded-[8px] text-white text-sm bg-verovian-purple">Upload Signature</button> */}
                
            </div>
        </div>
    );
};

export default SignatureUploader;
