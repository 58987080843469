import React, { useEffect, useState } from 'react'
import TrainingsLayout from '../../../components/layout/TrainingsLayout'
import TextField from '../../../components/elements/form/TextField';
import TextareaField from '../../../components/elements/form/TextareaField';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE, UPDATING_TRAINING } from '../../../store/types';
import { clearUpdatedTraining, getTrainingModules, updateTraining } from '../../../store/actions/trainingsActions';
import FormButton from '../../../components/elements/form/FormButton';
import FileUpload from '../../../components/elements/form/FileUpload';
import DragDropList from '../../../components/elements/DragDropList';
import PlusIcon from '../../../components/elements/icons/PlusIcon';
import ModalLayout from '../../../components/layout/ModalLayout';
import Checkbox from '../../../components/elements/form/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import { fetchDepartments } from '../../../store/actions/departmentActions';
import ModuleCardHorizontal from '../../../components/partials/trainings/modules/ModuleCardHorizontal';
import { authHeader } from '../../../utils';
import axios from 'axios';
import Preloader from '../../../components/elements/Preloader';
import CloseIcon from '../../../components/elements/icons/CloseIcon';

const EditTraining = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const trainingsSelector = useSelector((state => state.trainings))
    const departmentsSelector = useSelector(state => state.departments)
    const [trainingPayload, setTrainingPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [modulesList, setModulesList] = useState([]);
    const { trainingId } = useParams()
    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);
  
    useEffect(() => {
        const getTraining = async () => {
          try {
            const headers = authHeader()
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/trainings/${trainingId}?expand=modules.module,departments`, { headers })
            if(response.data.data.departments && response.data.data.departments.length > 0){
              setDepartments(response.data.data.departments.filter(dept => dept !== null).map(dept => dept._id))
            }
            setTrainingPayload(response.data.data)
            setModulesList(response.data.data.modules.filter(module => module.module !== null))
            setLoading(false)
          } catch (error) {
            console.error(error)
            dispatch({
              type: ERROR,
              error: error
            })
            setLoading(false)
          }
        }
        
        getTraining()
        dispatch(getTrainingModules())
        dispatch(fetchDepartments())
    
        if(trainingsSelector.updatedTraining !== null){
          dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: {successMessage: "Training updated successfully"}
          })
          dispatch(clearUpdatedTraining())
        }
        return () => {
            
        };
    }, [dispatch, navigate, trainingId, trainingsSelector.updatedTraining]);
  
    const [addingMaterials, setAddingMaterials] = useState(false);
  
    const validateForm = () => {
      let errors = {}
      if (!trainingPayload.title || trainingPayload.title === '') {
        errors.title = true
      }
      if (!trainingPayload.description || trainingPayload.description === '' || trainingPayload.description.length > 400) {
        errors.description = true
      }
      if (!modulesList || modulesList.length === 0) {
        errors.estTime = true
      }
  
      setValidationErrors(errors)
      return errors
    }
  
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);
    const handleUpload = async (file) => {
      setUploading(true)
  
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
  
        try {
            const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
    
           return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }
  
    const pushTraining = async () => {
      if (Object.values(validateForm()).includes(true)) {
        dispatch({
            type: ERROR,
            error: {response: {data: {
                message: 'Please check the highlighted fields'
            }}}
        });
        return
      }

      const payload = {
        ...trainingPayload,
        ...{
          modules: modulesList.filter(mod => mod.module !== null).map((module, moduleIndex) => {return {
            order: moduleIndex,
            module: module.module ? module.module._id : module._id
          }})
        }
      }

      if(departments && departments.length > 0) {
        payload.departments = departments
      }

      dispatch({
        type: UPDATING_TRAINING,
        payload: true
      })
  
      if(file) {
        const uploaded = await handleUpload(file)
        payload.coverImage = uploaded.data.file
      }
  
      dispatch(updateTraining(trainingId, payload))
      setUploading(false)
    }
  
    const toggleModuleSelection = (moduleToToggle) => {
      const tempList = [...modulesList]
      const index = tempList.findIndex((module) => 
        module._id === moduleToToggle._id
      )
  
      if(index > -1) {
        tempList.splice(index, 1)
      } else {
        tempList.push(moduleToToggle)
      }
      setModulesList([])
      setTimeout(() => {
        setModulesList(tempList)
      }, 10);
    }
  
    const moduleSelected = (moduleToCheck) => {
      const index = modulesList.findIndex((module) => 
        module._id === moduleToCheck._id
      )
  
      return index > -1
    }

    const toggleDepartment = (departmentId) => {
      let temp = [...departments]
      if(temp.includes(departmentId)){
        temp = temp.filter(dept => dept !== departmentId)
      } else {
        temp.push(departmentId)
      }
      setDepartments(temp)
    }

    const [changingCover, setChangingCover] = useState(false);
  
    return (
      <>
        {loading ? 
            <Preloader />
          :
        <TrainingsLayout sectionTitle={`Edit Training`}>
          <div className='w-8/12 max-w-[60%] mb-[100px] px-[20px] pb-[20px] pt-[10px] bg-white mt-[20px]'>
            <p className='mt-[20px] font-medium text-[15px]'>Training details</p>
            <p className='text-[12px] mt-2'>Please provide training details below to create a new training</p>
  
            <div>
              <div className='w-full max-w-[100%]'>
                <div className='mb-4 mt-2 w-full'>
                  <TextField
                    inputLabel="Title" 
                    fieldId="material-name" 
                    inputType="text" 
                    preloadValue={trainingPayload.title || ''}
                    hasError={validationErrors.title } 
                    returnFieldValue={(value)=>{setTrainingPayload({...trainingPayload, ...{title: value}})}}
                  />
                </div>
  
                <div className='mb-4 mt-2 w-full'>
                  <TextareaField
                    inputLabel="Description" 
                    fieldId="material-description" 
                    inputType="text" 
                    preloadValue={trainingPayload.description || ''}
                    hasError={validationErrors.description} 
                    returnFieldValue={(value)=>{setTrainingPayload({...trainingPayload, ...{description: value}})}}
                  />
                  {trainingPayload?.description?.length > 400 && <p className='text-xs text-red-500 mt-[10px]'>Description text is too long, please make it 400 characters or less</p>}
                </div>
  
                <div className='w-full my-4 flex gap-x-4 items-center justify-between'>
                  <div  className='w-full'>
                      <p className="text-sm text-gray-600">
                          Compulsory for new employees
                      </p>
                      <p className='text-xs text-gray-400'>Toggle this switch on if this training should be compulsory for all new employees</p>
                  </div>
                  <div className='w-24 flex flex-row-reverse'>
                      <Switch
                          checked={trainingPayload?.compulsoryForNewEmployees}
                          onChange={()=>{setTrainingPayload({...trainingPayload, ...{compulsoryForNewEmployees: !trainingPayload.compulsoryForNewEmployees}})}}
                          className={`${
                              trainingPayload.compulsoryForNewEmployees ? 'bg-verovian-purple' : 'bg-gray-200'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          {/* <span className="sr-only">Display stock levels</span> */}
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                              trainingPayload.compulsoryForNewEmployees ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                          />
                      </Switch>
                  </div>
                </div>
                {/* {!departmentsSelector.loadingDepartments && departmentsSelector.departments && departmentsSelector.departments.length > 0 && <div className='my-4 w-full'>
                  <SelectField
                      selectOptions={departmentsSelector.departments}
                      inputLabel="Select Department"
                      titleField="name"
                      displayImage={false}
                      imageField=""
                      preSelected=''
                      fieldId="designation-department"
                      hasError={false}
                      // return id of accounts of the selected option
                      returnFieldValue={(value) => {setTrainingPayload({...trainingPayload, ...{department: value._id}})}}
                  />
                  <p className='text-xs text-gray-400 mt-[10px]'>Select a department above to restrict this training to a department</p>
                </div>} */}

                <div>
                  <label className={`block cursor-text bg-transparent text-sm z-10 font-outfit transition duration-200 text-gray-800 mt-[25px]`}>
                    Departments
                  </label>
                  <label className={`block cursor-text bg-transparent text-xs mt-[5px] z-10 font-outfit transition duration-200 text-gray-500`}>
                    Select departments below to restrict the training to them.
                  </label>
                  {!departmentsSelector?.loadingDepartments && departmentsSelector?.departments && departmentsSelector?.departments?.departments?.length > 0 && departmentsSelector?.departments?.departments?.map((dept, deptIndex)=>(
                  <div className='flex items-center justify-between' key={deptIndex}>
                    <div className='flex flex-row gap-x-4 justify-between items-start my-1 hover:bg-gray-100 transition duration-200 py-2 rounded px-6'>
                      <span className='mt-[2px]'>
                        <Checkbox 
                          CheckboxLabel=""
                          checkboxToggleFunction={()=>{toggleDepartment(dept._id)}} 
                          isChecked={departments.includes(dept._id)} 
                          hasError={false}
                        />
                      </span>
                      <div className='w-full flex gap-x-2 items-start'>
                        <div>
                          <p className='font-medium text-sm'>{dept.name}</p>
                          {/* <p className='font-thin text-gray-600 text-xs'>{item.description}</p> */}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  ))}
                </div>
  
                <div className='my-4 w-full'>
                    {!changingCover ? <div style={{
                        width: '100%',
                        height: '400px',
                        backgroundImage: `url(${(trainingPayload.coverImage)}`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        position: 'relative',
                        marginBottom: '20px'
                    }}>
                        <div className='w-full h-[400px] bg-black bg-opacity-20 flex items-center justify-center'>
                            <button onClick={()=>{setChangingCover(true)}} className=' bg-white bg-opacity-50 text-black p-3 rounded-md border border-black text-xs font-medium hover:text-black transition duration-200'>Change Cover Image</button>
                        </div>
                    </div>
                    :
                    <div className='relative'>
                        <button onClick={()=>{setChangingCover(false)}} className='z-50 absolute right-0 top-3 flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                          <CloseIcon className={`w-5 h-5`} />
                          Cancel
                        </button>
                        <>
                        {/* <p className='text-xs mt-[10px] mb-[20px]'>Please drop your <span className=''>{content.contentType.toLowerCase()}</span> content in the box below to upload.</p> */}
                        <FileUpload
                          hasError={validationErrors.content}
                          noSizeRestrict={true}
                          fieldLabel={`Add a cover image for this training`}
                          returnFileDetails={(details)=>{
                            setFile(details)
                          }}
                          acceptedFormats={['jpeg', 'jpg', 'png']}
                        />
                        <label className='block mt-3 text-xs text-gray-400'>Upload the file by clicking above or dropping the file</label>
                      </>
                    </div>
                    }
                </div>
              </div>
  
              <p className='mt-[20px] font-medium text-[15px]'>Training Modules</p>
              <p className='text-[12px] mt-2'>Add modules required for this training below. You can drag the modules to place them in the order you want your employees to use them</p>
  
              <div className='p-5 border border-gray-200 rounded-[8px] mt-4 max-w-[100%]'>
                {modulesList && modulesList.length > 0 && <DragDropList listItems={modulesList} returnSorting={(list)=>{setModulesList(list)}} ItemTemplate={ModuleCardHorizontal} />}
                <button onClick={()=>{setAddingMaterials(true)}} className='rounded-[8px] mt-[12px] p-3 border border-dashed border-gray-300 w-full text-xs text-gray-400 text-center hover:border-gray-600 hover:text-gray-600 transition duration-200'>
                  <span className='flex items-center justify-center gap-x-[5px] uppercase tracking-[0.2em] '>
                    <PlusIcon className={`w-5 h-5`} />
                    add new module
                  </span>
                  click to add a new training material
                </button>
              </div>
            </div>
  
            <div className='w-max mt-[40px]'>
              <FormButton buttonLabel={`Save Updates`} processing={trainingsSelector.creatingTraining} buttonAction={()=>{pushTraining()}} />
            </div>
          </div>
        </TrainingsLayout>
        }
  
        <ModalLayout
          isOpen={addingMaterials} 
          closeModal={()=>{setAddingMaterials(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='Add modules' 
          dialogTitle='Add modules'
          dialogIntro={`Select the modules required for this training below`}
          maxWidthClass='max-w-xl'
        >
        <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
          {trainingsSelector.trainingModules?.modules?.length > 0 ? 
            <div className='w-full'>
              {trainingsSelector.trainingModules?.modules?.map((module, moduleIndex) => (
                <div key={moduleIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleModuleSelection(module)})}>
                  <div className='w-[25px] pt-[5px]'>
                    <Checkbox 
                      isChecked={moduleSelected(module)}
                      checkboxToggleFunction={()=>{}}
                      checkboxLabel=''
                    />
                  </div>
                  <div className='rounded flex items-center justify-center' style={{
                    width: '75px',
                    height: '75px',
                    backgroundImage: `url(${(module.coverImage)}`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    position: 'relative'
                  }} >
                    {/* {material.content.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                    {material.content.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                    {material.content.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                    {(material.content.contentType === 'TEXT' ||material.content.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />} */}
                  </div>
                  <div className='w-full'>
                    <p className='text-sm text-gray-700 font-medium'>{module.title} <span className='text-xs text-gray-500'>({module.estimatedTime} minutes)</span></p>
                    <div className='my-1 max-w-[80%]'>
                      <p className='text-xs truncate'>{module.description}</p>
                    </div>
                    {/* <p className='text-xs text-gray-500'>By {material.instructor.name}</p> */}
                  </div>
                </div>
              ))}
            </div> 
            :
            <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
              <p className='text-sm text-gray-700 font-[550]'>No modules found</p>
              <p className='mt-1 text-xs text-gray-500'>Navigate to the training modules page and create some modules first.</p>
            </div>  
          } 
        </div>
        <div className='flex flex-row-reverse mt-[20px]'>
          <div className='w-max'>
            <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{setAddingMaterials(false)}} processing={uploading || trainingsSelector.creatingTraining} />
          </div>
        </div>
        </ModalLayout>
      </>
    )
  }
  
export default EditTraining