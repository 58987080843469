import React, { useEffect, useState } from 'react'
import EmptyState from '../../elements/icons/EmptyState'
import ModalLayout from '../../layout/ModalLayout';
import BankDetailsUpdate from './BankDetailsUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { clearUpdatedEmployee } from '../../../store/actions/employeeActions';
import { SET_SUCCESS_MESSAGE } from '../../../store/types';

const EmployeeBankDetails = ({employeeDetails, reload}) => {
  const dispatch = useDispatch()
  const [updatingBankDetails, setUpdatingBankDetails] = useState(false);
  const employeesSelector = useSelector(state => state.employees)

  useEffect(() => {
    if(employeesSelector.updatedEmployee !== null){
      setUpdatingBankDetails(false);
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: 'Banks details saved successfully!'
      })
      dispatch(clearUpdatedEmployee())
      reload()
    }
    return () => {
      
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employeesSelector.updatedEmployee]);
  return (
    <>
      <div className='w-full'>
        <div className='w-full mt-2'>
            {employeeDetails.bankDetails ? 
              <>
                <div className='flex items-center justify-between pb-[20px] border-b mb-[20px]'>
                  <div className='w-8/12'>
                    <p className='text-sm text-gray-500'>Below are your current bank details. You can update/change bank details by clicking on "Update bank details"</p>
                  </div>
                  <button onClick={()=>{setUpdatingBankDetails(true)}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-white text-[13px] transition duration-200 hover:bg-blue-800'>
                    Update Bank Details
                  </button>
                </div>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Account Name</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.accountName}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Account Number</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.accountNumber}</p>
                  </div>
                </div>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Bank</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.bank}</p>
                  </div>
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Sort Code</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.sortCode || <span className='text-gray-300 text-xs'>Not provided</span>}</p>
                  </div>
                </div>
                <div className="w-full flex items-start justify-between gap-x-[20px] mt-4">
                  <div className="w-full">
                    <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>National Insurance Number</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.nationalInsuranceNumber || <span className='text-gray-300 text-xs'>Not provided</span>}</p>
                  </div>
                  <div className="w-full">
                    {/* <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Sort Code</label>
                    <p className="text-sm">{employeeDetails.bankDetails?.sortCode}</p> */}
                  </div>
                </div>
              </> :
              <div className='w-full text-center relative'>
                <EmptyState emptyStateText={`Bank details not provided yet`} />
                <button onClick={()=>{setUpdatingBankDetails(true)}} className='mx-auto -mt-[200px]! transition duration-200 rounded bg-gray-100 hover:bg-verovian-purple hover:text-white text-gray-700 text-xs p-[7px]'>
                  Provide Bank Details
                </button>
              </div>
            }
          </div>
      </div>

      <ModalLayout
          isOpen={updatingBankDetails} 
          closeModal={()=>{setUpdatingBankDetails(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Update Bank Details'
          maxWidthClass='max-w-xl'
      >
          <BankDetailsUpdate bankInfo={employeeDetails.bankDetails} />
      </ModalLayout>
    </>
  )
}

export default EmployeeBankDetails