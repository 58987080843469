import React from 'react'
// import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'

const PendingDocumentSignings = ({documents}) => {
    // const documentsSelector = useSelector(state => state.documents)
    return (
        <div>
            <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
                <h3 className='text-verovian-purple text-[16px] font-[500]'>Pending Document Signings</h3>
            </div>
            {documents?.map((document, documentIndex)=>(<Link key={documentIndex} to={`/user/documents/assigned/read/${document._id}`}>
                <div className='w-full px-1 py-3 mb-[15px] flex items-start gap-x-1 border bg-white rounded-[8px] hover:border-gray-200 transition duration-200 cursor-pointer'>
                    <div className='w-[60px] h-inherit pt-1'>
                        <img src={DocumentSvgIcon} alt="" />
                    </div>
                    <div className='w-full'>
                        <p className='text-sm font-medium text-verovian-purple'>{document.document.name}</p>
                        <p className='text-xs text-gray-500'>{document.document.description}</p>
                        <p className='text-xs text-gray-800 font-[550] mt-[10px]'>Assigned to: {document?.employee?.fullName}</p>
                        <p className='text-xs text-gray-500'>Assigned by: {document.createdBy?.fullName}</p>
                        <p className='text-xs text-gray-500 mt-3'>Created {new Date(document.createdAt)?.toLocaleDateString()}</p>
                    </div>
                </div>
            </Link>))}
        </div>
    )
}

export default PendingDocumentSignings