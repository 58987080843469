
import React, { useEffect, useState } from 'react'
import UserLayout from '../../components/layout/UserLayout'
import EmployeeProfile from '../../components/partials/employees/EmployeeProfile';
import { useDispatch } from 'react-redux';
import { ERROR } from '../../store/types';
import axios from 'axios';
import { authHeader } from '../../utils';
import Preloader from '../../components/elements/Preloader';
import EmployeeProfileDocumentAssignmentCard from '../../components/partials/employees/EmployeeProfileDocumentAssignmentCard';
import EmployeeBankDetails from '../../components/partials/employees/EmployeeBankDetails';

const Profile = () => {
    // const {employeeId} = useParams()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [activeTab, setActiveTab] = useState('profile');
    const tabs = [
      {
        label: "Profile Information",
        value: 'profile'
      },
      {
        label: "Bank Details",
        value: 'bank-details'
      },
      {
        label: "Inventory",
        value: 'inventory'
      },
      // {
      //   label: "Onboarding",
      //   value: 'onboarding'
      // },
      // {
      //   label: "Employee Trainings",
      //   value: 'trainings'
      // },
      // {
      //   label: "Employee Tasks",
      //   value: 'tasks'
      // },
      // {
      //   label: "Employee Reviews",
      //   value: 'reviews'
      // },
    ]

    // eslint-disable-next-line no-unused-vars
    const [userProfile, setUserProfile] = useState({});
    const [departmentLeavePolicies, setDepartmentLeavePolicies] = useState(null);
    const [maxLeaveDays, setMaxLeaveDays] = useState(0);
    const [reload, setReload] = useState(0);
  
    useEffect(() => {
      const fetchUserDetails = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `user/profile?expand=documents`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setUserProfile(response.data.data)
          fetchEmployeeDetails()
        //   setLoading(false)
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }  

      const fetchEmployeeDetails = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `user/employee-profile?expand=designation,department,documents`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setEmployeeDetails(response.data.data)
          fetchDepartmentLeavePolicies(response.data.data.department._id)
          // setLoading(false)
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }  

      const fetchDepartmentLeavePolicies = async (departmentId) => {    
        try{
          const headers = authHeader()
          let requestUrl = `leave-policies/${departmentId}`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setDepartmentLeavePolicies(response.data.data.data)
          let maxLeaveDays = response.data.data.data?.policies?.allowedLeaveTypes?.types?.reduce((accumulator, current) => accumulator + current.maxDays, 0)
          console.log('max leave days', maxLeaveDays)
          setMaxLeaveDays(maxLeaveDays)
          setLoading(false)
        }
        catch(error){
          console.log('department leave policies error -> ', error)
  
          dispatch( {
              type: ERROR,
              error
          })
        }
      }
      
      fetchUserDetails()

      return () => {
        
      };
    }, [dispatch, reload]);
  
    return (
      <UserLayout pageTitle={`Employee Profile`}>
       {loading ? <Preloader preloadingText={`Loading profile`} /> :
        <div className='w-full flex items-start justify-between gap-x-[20px]'>
          <div className='w-8/12 bg-white p-[20px] min-h-[100vh] mb-[20px]'>
            <div className='w-full flex items-center border-b border-gray-400'>
              {tabs.map((tab, tabIndex)=>(
                <button key={tabIndex} onClick={()=>{setActiveTab(tab.value)}} className={`${tab.value === activeTab ? 'bg-gray-100 font-[500]' : ''} px-[16px] py-[8px] border-b text-sm`}>
                  {tab.label}
                </button>
              ))}
            </div>
            <div className='w-full py-[20px] px-[10px]'>
              {activeTab === 'profile' && <EmployeeProfile employeeDetails={employeeDetails} reload={()=>{setReload(reload+1)}} />}
              {activeTab === 'bank-details' && <EmployeeBankDetails employeeDetails={employeeDetails} reload={()=>{setReload(reload+1)}} />}
              {/* {activeTab === 'onboarding' && <EmployeeOnboarding employeeDetails={employeeDetails} />}
              {activeTab === 'documents' && <EmployeeDocuments />}
              {activeTab === 'tasks' && <EmployeeTasks />}
              {activeTab === 'trainings' && <EmployeeTrainings />}
              {activeTab === 'reviews' && <EmployeeReviews />} */}
            </div>
          </div>
          <div className='w-4/12'>

          <div className='text-white bg-verovian-purple p-[15px] rounded'>
            <p className='text-xs uppercase tracking-[0.2em] mb-2'>Start Date</p>
            <p className='text-sm mb-1 font-medium'>{new Date(employeeDetails.startDate).toDateString()}</p>
          </div>

          {/* designation & department */}
          <p className='text-xs uppercase tracking-[0.2em] mt-4 mb-2'>current department/designation</p>
          <div className="w-full p-[25px] bg-white">
            <p className='text-[15px] text-gray-600 mb-1 font-medium'>{employeeDetails.department.name}</p>
            <p className='text-xs'>{employeeDetails.designation.name}</p>
          </div>

          <p className='text-xs uppercase tracking-[0.2em] mb-2 mt-5'>Employee Documents</p>
          <div>
            {employeeDetails.documents.map((doc, docIndex)=>(<div key={docIndex} className='w-full'>
              <EmployeeProfileDocumentAssignmentCard assignment={doc} />
            </div>))}
          </div>

          <p className='text-xs uppercase tracking-[0.2em] mb-2 mt-5'>leave/holiday allowance</p>
          <div className="w-full p-[25px] bg-white">
            <p className='text-[20px] font-medium'><span className='font-space-grotesk'>{maxLeaveDays}</span> <span className="text-[13px] font-[400]">days total,</span> <span className='font-space-grotesk'>0</span> <span className="text-[13px] font-[400]">days used</span></p>
            <div className="h-[1px] bg-gray-300 w-full my-2" />
            {departmentLeavePolicies?.policies?.allowedLeaveTypes.types.map((leaveType, leaveTypeIndex)=>(
              <p key={leaveTypeIndex} className='text-xs mt-2 capitalize'>{leaveType.leaveType.toLowerCase()} leaves days - {leaveType.maxDays} days - <span className='underline'>0 taken</span></p>))}
            {/* <p className='text-sm mt-1'>Annual leave days taken - 8 days</p> */}
          </div>

        </div>
        </div>
       }
      </UserLayout>
    )
  }

export default Profile