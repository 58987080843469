// user profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GETTING_DEPARTMENTS = 'GETTING_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATING_DEPARTMENT = 'GETTING_DEPARTMENTS';
export const DEPARTMENTS_ERROR = 'DEPARTMENTS_ERROR';

// leave policies
export const UPDATING_LEAVE_POLICIES = 'UPDATING_LEAVE_POLICIES';
export const UPDATE_LEAVE_POLICIES = 'UPDATE_LEAVE_POLICIES';
export const LEAVE_POLICIES_ERROR = 'LEAVE_POLICIES_ERROR';

// leaves and leave applications
export const CREATING_LEAVE_APPLICATION = 'CREATING_LEAVE_APPLICATION';
export const CREATE_LEAVE_APPLICATION = 'CREATE_LEAVE_APPLICATION';
export const UPDATING_LEAVE_APPLICATION = 'UPDATING_LEAVE_APPLICATION';
export const UPDATE_LEAVE_APPLICATION = 'UPDATE_LEAVE_APPLICATION';
export const GETTING_LEAVE_APPLICATIONS = 'GETTING_LEAVE_APPLICATIONS';
export const GET_LEAVE_APPLICATIONS = 'GET_LEAVE_APPLICATIONS';
export const GETTING_LEAVES = 'GETTING_LEAVES';
export const GET_LEAVES = 'GET_LEAVES';
export const UPDATING_LEAVE = 'UPDATING_LEAVE';
export const UPDATE_LEAVE = 'UPDATE_LEAVE';
export const LEAVES_ERROR = 'LEAVES_ERROR'

// Documents
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GETTING_DOCUMENTS = 'GETTING_DOCUMENTS';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATING_DOCUMENT = 'CREATING_DOCUMENT';
export const SIGN_DOCUMENT = 'SIGN_DOCUMENT';
export const SIGNING_DOCUMENT = 'SIGNING_DOCUMENT';
export const UPDATING_DOCUMENT = 'UPDATING_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DOCUMENTS_ERROR = 'DEPARTMENTS_ERROR';

// Document assignments
export const CREATE_DOCUMENT_ASSIGNMENT = 'CREATE_DOCUMENT_ASSIGNMENT';
export const GET_DOCUMENT_ASSIGNMENTS = 'GET_DOCUMENT_ASSIGNMENTS';

// Groups
export const GET_GROUPS = 'GET_GROUPS';
export const GETTING_GROUPS = 'GETTING_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATING_GROUP = 'CREATING_GROUP';
export const GROUPS_ERROR = 'GROUPS_ERROR';

// holidays
export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const GETTING_HOLIDAYS = 'GETTING_HOLIDAYS';
export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';
export const CREATING_HOLIDAY = 'CREATING_HOLIDAY';
export const HOLIDAYS_ERROR = 'HOLIDAYS_ERROR';

// Shifts
export const GET_SHIFTS = 'GET_SHIFTS';
export const GETTING_SHIFTS = 'GETTING_SHIFTS';
export const GET_SHIFT_DETAILS = 'GET_SHIFT_DETAILS';
export const GETTING_SHIFT_DETAILS = 'GETTING_SHIFT_DETAILS';
export const CREATE_SHIFT = 'CREATE_SHIFT';
export const CREATING_SHIFT = 'CREATING_SHIFT';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const UPDATING_SHIFT = 'UPDATING_SHIFT';
export const SHIFTS_ERROR = 'SHIFTS_ERROR';

// tasks
export const GET_TASKS = 'GET_TASKS';
export const GET_ASSIGNED_TASKS = 'GET_ASSIGNED_TASKS';
export const GETTING_TASKS = 'GETTING_TASKs';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATING_TASK = 'CREATING_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATING_TASK = 'UPDATING_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETING_TASK = 'DELETING_TASK';
export const TASKS_ERROR = 'TASKS_ERROR';

// Designations
export const GET_DESIGNATIONS = 'GET_DESIGNATIONS';
export const GETTING_DESIGNATIONS = 'GETTING_DESIGNATIONS';
export const CREATE_DESIGNATION = 'CREATE_DESIGNATION';
export const CREATING_DESIGNATION = 'CREATING_DESIGNATION';
export const DESIGNATIONS_ERROR = 'DESIGNATIONS_ERROR';

// Employees
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GETTING_EMPLOYEES = 'GETTING_EMPLOYEES';
export const INVITE_EMPLOYEE = 'INVITE_EMPLOYEE';
export const INVITING_EMPLOYEE = 'INVITING_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATING_EMPLOYEE = 'UPDATING_EMPLOYEE';
export const SIGNUP_EMPLOYEE = 'SIGNUP_EMPLOYEE';
export const SIGNING_UP_EMPLOYEE = 'SIGNING_UP_EMPLOYEE';
export const EMPLOYEES_ERROR = 'EMPLOYEES_ERROR';

// News
export const GET_NEWS_ARTICLES = 'GET_NEWS_ARTICLES';
export const GETTING_NEWS_ARTICLES = 'GETTING_NEWS_ARTICLES';
export const CREATE_NEWS_ARTICLE = 'CREATE_NEWS_ARTICLES';
export const CREATING_NEWS_ARTICLE = 'CREATING_NEWS_ARTICLE';
export const NEWS_ERROR = 'NEWS_ERROR';

// Trainings
export const GET_TRAININGS = 'GET_TRAININGS';
export const GETTING_EMPLOYEE_TRAININGS = 'GETTING_EMPLOYEE_TRAININGS';
export const GET_EMPLOYEE_TRAININGS = 'GET_EMPLOYEE_TRAININGS';
export const GETTING_TRAININGS = 'GETTING_TRAININGS';
export const CREATE_TRAINING = 'CREATE_TRAINING';
export const CREATING_TRAINING = 'CREATING_TRAINING';
export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const UPDATING_TRAINING = 'UPDATING_TRAINING';
export const DELETING_TRAINING = 'DELETING_TRAINING';
export const DELETE_TRAINING = 'DELETE_TRAINING';
export const GET_TRAINING_MATERIALS = 'GET_TRAINING_MATERIALS';
export const GETTING_TRAINING_MATERIALS = 'GETTING_TRAINING_MATERIALS';
export const CREATE_TRAINING_MATERIAL = 'CREATE_TRAINING_MATERIAL';
export const CREATING_TRAINING_MATERIAL = 'CREATING_TRAINING_MATERIAL';
export const UPDATE_TRAINING_MATERIAL = 'UPDATE_TRAINING_MATERIAL';
export const UPDATE_EMPLOYEE_TRAINING = 'UPDATE_EMPLOYEE_TRAINING';
export const UPDATING_TRAINING_MATERIAL = 'UPDATING_TRAINING_MATERIAL';
export const GET_TRAINING_MODULES = 'GET_TRAINING_MODULES';
export const GETTING_TRAINING_MODULES = 'GETTING_TRAINING_MODULES';
export const CREATE_TRAINING_MODULE = 'CREATE_TRAINING_MODULE';
export const CREATING_TRAINING_MODULE = 'CREATING_TRAINING_MODULE';
export const UPDATE_TRAINING_MODULE = 'UPDATE_TRAINING_MODULE';
export const UPDATING_TRAINING_MODULE = 'UPDATING_TRAINING_MODULE';
export const TRAININGS_ERROR = 'TRAININGS_ERROR';

// Reviews
export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_EMPLOYEE_REVIEWS = 'GET_EMPLOYEE_REVIEWS';
export const GETTING_REVIEWS = 'GETTING_REVIEWS';
export const CREATE_REVIEW = 'CREATE_REVIEW';
export const CREATING_REVIEW = 'CREATING_REVIEW';

export const GET_REVIEW_QUESTIONS = 'GET_REVIEW_QUESTIONS';
export const GETTING_REVIEW_QUESTIONS = 'GETTING_REVIEW_QUESTIONS';
export const CREATE_REVIEW_QUESTION = 'CREATE_REVIEW_QUESTION';
export const CREATING_REVIEW_QUESTION = 'CREATING_REVIEW_QUESTION';
export const UPDATE_REVIEW_QUESTION = 'UPDATE_REVIEW_QUESTION';
export const UPDATING_REVIEW_QUESTION = 'UPDATING_REVIEW_QUESTION';
export const DELETE_REVIEW_QUESTION = 'DELETE_REVIEW_QUESTION';
export const DELETING_REVIEW_QUESTION = 'DELETING_REVIEW_QUESTION';

export const GET_REVIEW_FORMS = 'GET_REVIEW_FORMS';
export const GETTING_REVIEW_FORMS = 'GETTING_REVIEW_FORMS';
export const CREATE_REVIEW_FORM = 'CREATE_REVIEW_FORM';
export const CREATING_REVIEW_FORM = 'CREATING_REVIEW_FORM';
export const UPDATE_REVIEW_FORM = 'UPDATE_REVIEW_FORM';
export const UPDATING_REVIEW_FORM = 'UPDATING_REVIEW_FORM';
export const DELETE_REVIEW_FORM = 'DELETE_REVIEW_FORM';
export const DELETING_REVIEW_FORM = 'DELETING_REVIEW_FORM';

export const GET_REVIEW_SCHEDULES = 'GET_REVIEW_SCHEDULES';
export const GETTING_REVIEW_SCHEDULES = 'GETTING_REVIEW_SCHEDULES';

export const REVIEWS_ERROR = 'REVIEWS_ERROR';

// Instructors
export const GET_INSTRUCTORS = 'GET_INSTRUCTORS';
export const GETTING_INSTRUCTORS = 'GETTING_INSTRUCTORS';
export const CREATE_INSTRUCTOR = 'CREATE_INSTRUCTOR';
export const CREATING_INSTRUCTOR = 'CREATING_INSTRUCTOR';
export const UPDATE_INSTRUCTOR = 'UPDATE_INSTRUCTOR';
export const UPDATING_INSTRUCTOR = 'UPDATING_INSTRUCTOR';
export const INSTRUCTORS_ERROR = 'INSTRUCTORS_ERROR';

// Onboarding packages
export const GET_ONBOARDING_PACKAGES = 'GET_ONBOARDING_PACKAGES';
export const GETTING_ONBOARDING_PACKAGES = 'GETTING_ONBOARDING_PACKAGES';
export const CREATE_ONBOARDING_PACKAGE = 'CREATE_ONBOARDING_PACKAGE';
export const CREATING_ONBOARDING_PACKAGE = 'CREATING_ONBOARDING_PACKAGE';
export const UPDATE_ONBOARDING_PACKAGE = 'UPDATE_ONBOARDING_PACKAGE';
export const UPDATING_ONBOARDING_PACKAGE = 'UPDATING_ONBOARDING_PACKAGE';
export const DELETE_ONBOARDING_PACKAGE = 'DELETE_ONBOARDING_PACKAGE';
export const DELETING_ONBOARDING_PACKAGE = 'DELETING_ONBOARDING_PACKAGE';
export const ONBOARDING_PACKAGES_ERROR = 'ONBOARDING_PACKAGES_ERROR';

// Instructors
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GETTING_ASSESSMENTS = 'GETTING_ASSESSMENTS';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const CREATING_ASSESSMENT = 'CREATING_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const UPDATING_ASSESSMENT = 'UPDATING_ASSESSMENT';
export const ASSESSMENTS_ERROR = 'ASSESSMENTS_ERROR';

// Inventory
export const GET_INVENTORY_ITEMS = 'GET_INVENTORY_ITEMS';
export const GETTING_INVENTORY_ITEMS = 'GETTING_INVENTORY_ITEMS';
export const CREATE_INVENTORY_ITEM_CATEGORY = 'CREATE_INVENTORY_ITEM_CATEGORY';
export const CREATING_INVENTORY_ITEM_CATEGORY = 'CREATING_INVENTORY_ITEM_CATEGORY';
export const CREATE_INVENTORY_ITEM_VARIANT = 'CREATE_INVENTORY_ITEM_VARIANT';
export const CREATING_INVENTORY_ITEM_VARIANT = 'CREATING_INVENTORY_ITEM_VARIANT';
export const UPDATE_INVENTORY_ITEM_VARIANT = 'UPDATE_INVENTORY_ITEM_VARIANT';
export const UPDATING_INVENTORY_ITEM_VARIANT = 'UPDATING_INVENTORY_ITEM_VARIANT';
export const UPDATE_INVENTORY_ITEM_CATEGORY = 'UPDATE_INVENTORY_ITEM_CATEGORY';
export const UPDATING_INVENTORY_ITEM_CATEGORY = 'UPDATING_INVENTORY_ITEM_CATEGORY';
export const GET_INVENTORY_ITEM_CATEGORIES = 'GET_INVENTORY_ITEM_CATEGORIES';
export const GETTING_INVENTORY_ITEM_CATEGORIES = 'GETTING_INVENTORY_ITEM_CATEGORIES';
export const CREATE_INVENTORY_ITEM = 'CREATE_INVENTORY_ITEM';
export const CREATING_INVENTORY_ITEM = 'CREATING_INVENTORY_ITEM';
export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';
export const UPDATING_INVENTORY_ITEM = 'UPDATING_INVENTORY_ITEM';
export const CREATE_INVENTORY_ITEM_ASSIGNMENT = 'CREATE_INVENTORY_ITEM_ASSIGNMENT';
export const CREATING_INVENTORY_ITEM_ASSIGNMENT = 'CREATING_INVENTORY_ITEM_ASSIGNMENT';
export const UPDATE_INVENTORY_ITEM_ASSIGNMENT = 'UPDATE_INVENTORY_ITEM_ASSIGNMENT';
export const UPDATING_INVENTORY_ITEM_ASSIGNMENT = 'UPDATING_INVENTORY_ITEM_ASSIGNMENT';
export const GET_INVENTORY_ITEM_ASSIGNMENT = 'GET_INVENTORY_ITEM_ASSIGNMENT';
export const GETTING_INVENTORY_ITEM_ASSIGNMENT = 'GETTING_INVENTORY_ITEM_ASSIGNMENT';
export const INVENTORY_ERROR = 'INVENTORY_ERROR';


// Roles & Permissions
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GETTING_PERMISSIONS = 'GETTING_PERMISSIONS';
export const GET_ROLES = 'GET_ROLES';
export const GETTING_ROLES = 'GETTING_ROLES';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATING_ROLE = 'CREATING_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATING_ROLE = 'UPDATING_ROLE';
export const ROLES_PERMISSIONS_ERROR = 'ROLES_PERMISSIONS_ERROR';

// global search
export const FETCHING_SEARCH_RESULTS = 'FETCHING_SEARCH_RESULTS'
export const SEARCH_RESULTS = 'SEARCH_RESULTS'
export const SEARCH_ERROR = 'SEARCH_ERROR'

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Success
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE'