import React, { useState } from 'react'
import Logo from '../../assets/img/ss-tt.png'
import TextField from '../../components/elements/form/TextField';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FormButton from '../../components/elements/form/FormButton';
import { useDispatch } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types';
import axios from 'axios';


const ResetPassword = () => {
    const [authPayload, setAuthPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {resetCode} = useParams()
	const validateForm = () => {
        let errors = {}
        if (!authPayload.password || authPayload.password === '') {
            errors.password = true
        }

        setValidationErrors(errors)
        return errors
    }

	const [processing, setProcessing] = useState(false);


	const createPassword = async () => {
		if (Object.values(validateForm()).includes(true)) {
			dispatch({
				type: ERROR,
				error: {response: {data: {
					message: 'Please check the highlighted fields'
				}}}
			});
			return
		}
		
		setProcessing(true)
	
		const requestPayload = {
			password: authPayload.password,
			resetCode
		}
	
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/auth/password-reset`, requestPayload)
			setProcessing(false)
			
			dispatch({
				type: SET_SUCCESS_MESSAGE,
				payload: {
					successMessage: `New password created successfully, redirecting to log in...`
				}
			})

			setTimeout(() => {
				navigate('/')
			}, 3000);
		} catch (error) {
			console.log(error.response)
			dispatch({
				type: ERROR,
				error: error
			});
			setProcessing(false)
		}
	}
	return (
		<>

			<div className="w-full flex items-center justify-center h-screen">
				<div className="w-4/12 p-10 rounded border">
					<Link href="/">
						<span className="flex items-center gap-x-2">
							<img src={Logo} width={100} alt="logo" />
							{/* <p className="tracking-[0.2em] font-medium mt-[10px]">HRMS</p> */}
						</span>
					</Link>

					<h3 className='font-montserrat font-medium text-gray-700  mt-8'>Create password?</h3>
					<p className='mt-2 dark:text-gray-500 text-sm'>Choose your new password below</p>
					<div className='my-4 w-full'>
						<TextField
							inputLabel="Password" 
							inputPlaceholder="Your password" 
							fieldId="password" 
							inputType="password" 
							hasError={validationErrors.password} 
							returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{password: value}})}}
						/>
					</div>

					<div className='animate__animated animate__fadeIn mb-4 mt-8 w-full'>
						<FormButton 
							buttonLabel="Set your password" 
							buttonAction={()=>{createPassword()}} 
							processing={processing}
						/>
						
					</div>
					
					<div className='animate__animated animate__fadeIn w-full text-center mt-3'>
						<p className='text-gray-400 text-sm'><Link href="/" className='text-blue-500 dark:text-kt-orange font-medium' to='password-reset'>Click here</Link> to go back to log in page</p>
					</div>
				</div>
			</div>

		</>
    )
}

export default ResetPassword