import React, { useCallback, useEffect, useState } from 'react'
import PlayerLayout from '../../../components/layout/PlayerLayout'
import { authHeader } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ERROR, TRAININGS_ERROR } from '../../../store/types';
import Preloader from '../../../components/elements/Preloader';
import ChevronIcon from '../../../components/elements/icons/ChevronIcon';
import VideoIcon from '../../../components/elements/icons/VideoIcon';
import AudioIcon from '../../../components/elements/icons/AudioIcon';
import PhotoIcon from '../../../components/elements/icons/PhotoIcon';
import TextIcon from '../../../components/elements/icons/TextIcon';
import VideoPlayer from '../../../components/elements/VideoPlayer';
import AudioPlayer from '../../../components/elements/AudoPlayer';
// import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
import DownloadIcon from '../../../components/elements/icons/DownloadIcon';
import PlayIcon from '../../../components/elements/icons/PlayIcon';
import Checkbox from '../../../components/elements/form/Checkbox';
import { clearUpdatedTraining, completeTrainingModule } from '../../../store/actions/trainingsActions';

const TrainingPlayer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const trainingsSelector = useSelector(state => state.trainings)
    const { trainingId } = useParams()
    const [training, setTraining] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeMaterial, setActiveMaterial] = useState(null);
    const [activeModule, setActiveModule] = useState(null);

    const [loadingModuleDetails, setLoadingModuleDetails] = useState(null);
    const [activeModuleDetails, setActiveModuleDetails] = useState(null);
    const [trainingAssignment, setTrainingAssignment] = useState(null);
    const [completedModules, setCompletedModules] = useState([]);

    const getTrainingModule = useCallback(async (moduleId, setMaterial = true) => {
      if(moduleId === activeModuleDetails?._id) {
        setActiveModuleDetails(null)
        return;
      }
      setActiveModuleDetails(null)
      try {
        const headers = authHeader()
        setLoadingModuleDetails(moduleId)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/modules/${moduleId}?expand=materials.material,assessment`, { headers })
        setActiveModuleDetails(response.data.data)
        if(setMaterial){
            setActiveMaterial(response.data.data.materials[0].material)
        }
        setLoadingModuleDetails(null)
      } catch (error) {
        dispatch({
          type: ERROR,
          error: error
        })
        setLoadingModuleDetails(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const findTrainingAssignment = useCallback(async () => {
        try{
            const headers = authHeader()
    
            let url = `training/employee?training=${trainingId}&expand=training,trainingMaterial`
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
            if(response.data.data.employeeTrainings.length > 0) {
                setTrainingAssignment(response.data.data.employeeTrainings[0])
                setCompletedModules(response.data.data.employeeTrainings[0].completedModules || [])
            }
        }
        catch(error){
            dispatch({
                type: TRAININGS_ERROR,
                error
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        const getTraining = async () => {
          try {
            const headers = authHeader()
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/trainings/${trainingId}?expand=modules.module`, { headers })
            setTraining(response.data.data)
            setActiveModule(response.data.data.modules[0].module)
            getTrainingModule(response.data.data.modules[0].module._id)
            findTrainingAssignment()
            setLoading(false)
          } catch (error) {
            dispatch({
              type: ERROR,
              error: error
            })
            setLoading(false)
          }
        }
        
        getTraining()
        if(trainingsSelector.updatedTraining !== null) {
            dispatch(clearUpdatedTraining())
        }
        return () => {
            
        };
    }, [dispatch, findTrainingAssignment, getTrainingModule, navigate, trainingId, trainingsSelector.updatedTraining]);

    const trainingEstimatedTime = training?.modules?.reduce((accumulator, current) => accumulator+current.module.estimatedTime, 0)

    const selectMaterial = (material) => {
        setLoadingModuleDetails('loading') 
        setActiveMaterial(material)
        setTimeout(() => {
            setLoadingModuleDetails(null)
        }, 200);
    }

    const moduleInCompletedList = (moduleId) => {
        const completed = completedModules.find(mod => mod.module === moduleId)
        return completed ? true : false
    }

    const setAsComplete = (moduleId) => {
        // TODO: Send assessment answers if available
        dispatch(completeTrainingModule(trainingAssignment._id, moduleId, {}))
    }

    return (
        <PlayerLayout pageTitle={training?.title}>
            {loading ? 
                <Preloader />
            :
            <div className='w-full px-[30px] flex items-start justify-between gap-x-[30px]'>
                <div className='w-8/12'>
                
                        <div className='w-full mb-[20px]'>
                        {loadingModuleDetails !== null ?
                            <Preloader />
                            :
                            <>
                                {activeMaterial?.content?.contentType === 'VIDEO' && <VideoPlayer videoSrc={activeMaterial?.content?.content} /> }
                                {activeMaterial?.content?.contentType === 'AUDIO' && <AudioPlayer audioSrc={activeMaterial?.content?.content} /> }
                                {activeMaterial?.content?.contentType === 'TEXT' && <div className='pb-12' dangerouslySetInnerHTML={{__html: activeMaterial?.content?.content}} />}
                                {activeMaterial?.content?.contentType === 'INFOGRAPHIC' && <img src={activeMaterial?.content?.content} alt='' className='w-full' /> }
                            </>
                        }
                        </div>
                        <div className='w-full flex items-start justify-between gap-x-[20px]'>
                            <div className='w-8/12'>
                            {loadingModuleDetails !== null ?
                                <Preloader />
                                :
                                <>
                                    <h3 className='font-[550] text-sm mb-[10px]'>{activeModule?.title} - {activeMaterial?.title}</h3>
                                    <p className='text-sm text-gray-600'>{activeMaterial?.description}</p>
                                    {activeMaterial?.content?.contentType === 'DOCUMENT' && 
                                    <>
                                        <p className='text-sm text-gray-400 mt-5 mb-3'>This lecture is a downloadable content. Please use the button below to download it.</p>
                                        <a href={activeMaterial?.content?.content} target="_blank" className='mt-[20px] rounded p-2 border-[1.5px] flex w-max items-center justify-center gap-x-[5px] border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 font-[550] text-xs' rel="noreferrer"><DownloadIcon className={`w-5 h-5`} />Download Training Material</a>
                                    </>
                                    }
                                </>}
                            </div>
                            <div className='w-4/12'>
                                <div className='w-full rounded-[10px] bg-verovian-purple text-white p-[20px]'>
                                    <img src={training?.coverImage} alt='' className='w-full mb-[10px]' />
                                    <h3 className='font-[550] text-sm mb-[10px]'>{training?.title}</h3>
                                    <p className='text-xs'>{training?.description}</p>
                                </div>
                            </div>
                        </div>
                    {/* </>
                    } */}
                </div>

                <div className='w-4/12'>
                    <p className='text-verovian-purple font-[550] mt-[20px]'>{training?.modules?.length} modules <span className='text-xs text-gray-500'>{trainingEstimatedTime} minutes total</span></p>
                    <p className='text-gray-500 text-xs mb-[20px]'>Click on a module below to see more details and all materials in it</p>

                    {training?.modules.map((module, moduleIndex) => (
                    <div key={moduleIndex} className='my-[20px]'>
                        <div key={moduleIndex} className='w-full rounded-[8px]'>
                            <div className={`flex items-start gap-x-2 mb-2 cursor-pointer w-full`}>
                                <div className='w-[80px]'>
                                    <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]' style={{
                                        backgroundImage: `url(${(module.module?.coverImage)}`,
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'cover',
                                        position: 'relative'
                                    }} />
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm text-gray-700 font-[550]'>{module.module?.title} <span className='text-xs text-gray-500'>({module.module?.estimatedTime} minutes)</span></p>
                                    {/* <div className='my-1 relative'>
                                        <p className='text-xs'>{module.module?.description}</p>
                                    </div> */}
                                    <button onClick={()=>{getTrainingModule(module.module._id, false)}} className='text-xs text-gray-700 font-[550] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>More details <ChevronIcon className={`w-4 h-4 ${activeModuleDetails?._id === module.module._id ? '' : '-rotate-90'}`}/></button>
                                </div>
                            </div>
                            {loadingModuleDetails === module.module._id && 
                                <div><Preloader /></div>
                            }
                            {activeModuleDetails && activeModuleDetails._id === module.module._id && 
                                <div className='w-full mt-[20px]'>
                                {activeModuleDetails.materials.map((material, materialIndex) => (
                                    <div key={materialIndex} className='px-[20px]'>
                                        <div onClick={()=>{selectMaterial(material.material)}} className={`flex items-center gap-x-2 mb-[10px] cursor-pointer w-full p-[10px] rounded-[8px] border ${activeMaterial._id === material.material._id ? 'bg-gray-100 border-gray-400' : 'border-transparent'}`}>
                                            <div className='w-[60px]'>
                                                <div className='rounded flex items-center justify-center bg-gray-100 w-[50px] h-[50px]'>
                                                    {material.material?.content?.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                                    {material.material?.content?.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                                    {material.material?.content?.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                                    {(material.material?.content?.contentType === 'TEXT' || material.material?.content?.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                                </div>
                                            </div>
                                            <div className='w-full'>
                                                <p className='text-sm text-gray-700 font-medium'>{material.material?.title} <span className='text-xs text-gray-500'>({material.material?.estimatedTime} minutes)</span></p>
                                                {material.material.instructor.name && <p className='text-xs text-gray-500 mt-[5px]'>By {material.material?.instructor?.name}</p>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                                {activeModuleDetails && activeModuleDetails?.assessment && activeModuleDetails?.assessment !== null &&
                                <div className='p-[20px] bg-verovian-light-purple bg-opacity-60 rounded-[10px]'>
                                    <h4 className='text-xs tracking-[0.1em] mb-[10px]'>ASSESSMENT</h4>
                                    <div className={`flex items-start gap-x-2 mb-[20px] cursor-pointer w-full`}>
                                        <div className='w-full'>
                                        <p className='text-sm text-gray-700 font-medium mb-1'>{activeModuleDetails?.assessment?.title}</p>
                                        <div className='my-1'>
                                            <p className='text-xs text-gray-500'>{activeModuleDetails?.assessment?.description}</p>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <p className='text-xs text-gray-500 mt-1'>{activeModuleDetails?.assessment?.questions?.length} questions</p>
                                            <button className='flex items-center justify-center gap-x-[5px] text-gray-600 text-xs hover:text-black'>
                                                <PlayIcon className={'w-4 h-4'} />
                                                Start assessment
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className='relative mt-[20px]'>
                                    {trainingsSelector.updatingTraining && <div className='absolute top-0 left-0 bg-white bg-opacity-50 w-full h-[100px]' />}
                                    <div className={`flex items-start gap-x-2 mb-4 ml-[35px] cursor-pointer`} onClick={(()=>{setAsComplete(module.module._id)})}>
                                        <div className='w-[25px] pt-[3x]'>
                                        <Checkbox 
                                            isChecked={moduleInCompletedList(module.module._id)}
                                            checkboxToggleFunction={()=>{setAsComplete(module.module._id)}}
                                            checkboxLabel=''
                                        />
                                        </div>
                                        <div className='w-full'>
                                            <p className='text-xs text-gray-700'>Completed this module (Check this when you have completed this module)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        </div>
                    </div>
                    ))}
                </div>
            </div>
            }
        </PlayerLayout>
    )
}

export default TrainingPlayer