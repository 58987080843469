import React, { useEffect, useState } from 'react'
import TextField from '../../elements/form/TextField'
import TextareaField from '../../elements/form/TextareaField'
// import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import FormButton from '../../elements/form/FormButton'
import SelectField from '../../elements/form/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import { fetchEmployees } from '../../../store/actions/employeeActions'
import { deleteTask, updateTask } from '../../../store/actions/tasksActions'
import { userDetails } from '../../../utils'
import TrashIcon from '../../elements/icons/TrashIcon'
import PencilSquareIcon from '../../elements/icons/PencilSquareIcon'
import OrderStatus from '../../elements/OrderStatus'
import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import { fetchDepartments } from '../../../store/actions/departmentActions'
import Checkbox from '../../elements/form/Checkbox'
import { Switch } from '@headlessui/react'

const TaskDetails = ({task}) => {
    const dispatch = useDispatch()
    const trainingsSelector = useSelector(state => state.trainings)
    const reviewsSelector = useSelector(state => state.reviews)
    const tasksSelector = useSelector(state => state.tasks)
    const departmentsSelector = useSelector(state => state.departments)

    useEffect(() => {
        dispatch(fetchDepartments(0, 0))
        dispatch(fetchEmployees())
        return () => {
            
        };
    }, [dispatch]);

    const [updatingTask, setUpdatingTask] = useState(false);
    const [taskPayload, setTaskPayload] = useState(task);
    const [validationErrors, setValidationErrors] = useState({});
    const accountPermissions = userDetails().accountPermissions

    const validateForm = () => {
        let errors = {}
        if(!taskPayload.title || taskPayload.title === ''){
            errors.title = true
        }
        setValidationErrors(errors)
        return errors
    }

    const pushUpdate = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = taskPayload
        // if(taskStatus && taskStatus !== ''){
        //     payload.status = taskStatus
        // }
        // if(parentTask && parentTask !== ''){
        //     payload.parent = parentTask
        // }
        if(departments && departments.length > 0 && departments[0] !== '') {
            payload.departments = departments
        }
        dispatch(updateTask(task._id, payload))
    }

    const pushTaskDelete = () => {
        if(window.confirm("Are you sure you want to delete this task?")) {
            dispatch(deleteTask(task._id))
        }
    }


    const [departments, setDepartments] = useState(task.departments?.map(dept => dept._id) || [])
    const toggleDepartment = (departmentId) => {
        let temp = [...departments]
        if(temp.includes(departmentId)){
            temp = temp.filter(dept => dept !== departmentId)
        } else {
            temp.push(departmentId)
        }
        setDepartments(temp)
    }

    const [linkTask, setLinkTask] = useState(task.taskItem);

    return (
        <div>
            <div className='flex items-center flex-row-reverse gap-x-[5px]'>
                {(accountPermissions?.includes('*') || accountPermissions?.includes('tasks.*') || accountPermissions?.includes('tasks.delete')) && <button onClick={()=>{pushTaskDelete()}} className='transition duration-200 hover:text-red-700 p-1 text-gray-600 rounded'>
                    <TrashIcon className={`w-5 h-5`} />
                </button>}
                {(accountPermissions?.includes('*') || accountPermissions?.includes('tasks.*') || accountPermissions?.includes('tasks.update')) && <button onClick={()=>{setUpdatingTask(!updatingTask)}} className='transition duration-200 hover:text-gray-700 p-1 text-gray-600 rounded'>
                    <PencilSquareIcon className={`w-5 h-5`} />
                </button>}
            </div>
            {updatingTask ? <div className='w-full p-[20px'>
                <div className='my-1 w-full'>
                    <TextField
                        inputLabel="Title" 
                        fieldId="task" 
                        inputType="text" 
                        preloadValue={taskPayload.title || ''}
                        hasError={validationErrors && validationErrors.title} 
                        returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{title: value}})}}
                    />
                </div>
                <div className='my-1 w-full'>
                    <TextareaField
                        inputLabel="Description" 
                        fieldId="task-description" 
                        inputType="text" 
                        preloadValue={taskPayload.description || ''}
                        hasError={false} 
                        returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{description: value}})}}
                    />
                </div>

                <div>
                <label className={`block cursor-text bg-transparent text-sm z-10 font-outfit transition duration-200 text-gray-800 mt-[25px]`}>
                    Departments
                </label>
                <label className={`block cursor-text bg-transparent text-xs mt-[5px] z-10 font-outfit transition duration-200 text-gray-500`}>
                    Select departments below to restrict the training to them.
                </label>
                {!departmentsSelector?.loadingDepartments && departmentsSelector?.departments && departmentsSelector?.departments?.departments?.length > 0 && departmentsSelector?.departments?.departments?.map((dept, deptIndex)=>(
                <div className='flex items-center justify-between' key={deptIndex}>
                    <div className='flex flex-row gap-x-4 justify-between items-start my-1 hover:bg-gray-100 transition duration-200 py-2 rounded px-6'>
                        <span className='mt-[2px]'>
                            <Checkbox 
                                CheckboxLabel=""
                                checkboxToggleFunction={()=>{toggleDepartment(dept._id)}} 
                                isChecked={departments.includes(dept._id)} 
                                hasError={false}
                            />
                        </span>
                        <div className='w-full flex gap-x-2 items-start'>
                            <div>
                                <p className='font-medium text-sm'>{dept.name}</p>
                                {/* <p className='font-thin text-gray-600 text-xs'>{item.description}</p> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
                ))}
            </div>

            <div className='w-full my-4 flex gap-x-4 items-center justify-between'>
                <div  className='w-full'>
                    <p className="text-sm text-gray-600">
                        Link task to an item
                    </p>
                    <p className='text-xs text-gray-400'>You will be able to link the task to a training or a review if you toggle this switch</p>
                </div>
                <div className='w-24 flex flex-row-reverse'>
                    <Switch
                        checked={linkTask}
                        onChange={()=>{setLinkTask(!linkTask)}}
                        className={`${
                            linkTask ? 'bg-verovian-purple' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        {/* <span className="sr-only">Display stock levels</span> */}
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                           linkTask ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
            </div>
                
            {linkTask && <div className='mt-3 mb-1'>
                <label className={`cursor-text bg-transparent text-sm z-10 font-[550] transition duration-200  
                text-gray-800 block`}>
                    Task Item
                </label>
                <label className={`cursor-text bg-transparent text-xs z-10 transition duration-200  
                text-gray-800 block`}>
                    Choose what item this task will be linked to. Completing this item resolves the task for the employee as done.
                </label>
                <div className='my-1 w-full'>
                    <SelectField
                        inputPlaceholder={`Click to select an item type`}
                        selectOptions={[
                            {label: 'Training', value: 'training'},
                            {label: 'Review', value: 'review'},
                        ]}
                        inputLabel="Select item type"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        preSelected={taskPayload?.taskItem?.itemType}
                        fieldId="task-item-type"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{itemType: value.value}
                            }}
                        })
                        }}
                    />
                </div>
            </div>}

            {(!taskPayload?.taskItem?.itemType || taskPayload?.taskItem?.itemType === '') && <div className='mt-[60px]' />}

            {taskPayload?.taskItem && taskPayload?.taskItem?.itemType === 'training' && <div className='w-full py-1'>
                {trainingsSelector.trainings?.trainings?.length > 0 ?   
                    <AutocompleteSelect
                        selectOptions={trainingsSelector.trainings.trainings}
                        inputLabel="Select training"
                        titleField="title"
                        displayImage={false}
                        imageField=""
                        preSelected=''
                        fieldId="task-training"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{item: value._id}
                            }}
                        })}}
                    />
                    :
                    <label className='block p-[8px] rounded text-red-600 border border-red-600 bg-red-50 text-xs my-[15px]'>No trainings created, create some first by navigating to "Trainings" on the sidebar</label> 
                }
            </div>}

            {taskPayload?.taskItem && taskPayload?.taskItem?.itemType === 'review' && <div className='w-full py-1'>
                {reviewsSelector?.reviewForms?.forms?.length > 0 ? 
                    <AutocompleteSelect
                        selectOptions={reviewsSelector?.reviewForms?.forms}
                        inputLabel="Select review form"
                        titleField="name"
                        displayImage={false}
                        imageField=""
                        preSelected=''
                        fieldId="task-review"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{item: value._id}
                            }}
                        })}}
                    />
                    : 
                    <label className='block p-[8px] rounded text-red-600 border border-red-600 bg-red-50 text-xs my-[15px]'>No review forms created, create some first by navigating to "Reviews &gt; Review Forms" on the sidebar</label> 
                }
            </div>}

                <div className='mt-3'>
                    <FormButton buttonLabel="Create task" buttonAction={()=>{pushUpdate()}} processing={tasksSelector.creatingTask} />
                </div>
            </div> : 
            <div className='w-full'>
                <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>TITLE</label>
                    <p className='text-sm font-medium'>{task.title}</p>
                </div>
                {/* <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>priority</label>
                    <span className={`${task.priority === 'urgent' && 'bg-red-400 text-white text-opacity-90'} ${task.priority === 'high' && 'bg-red-400 bg-opacity-10 text-red-500'} ${task.priority === 'medium' && 'bg-gray-400 bg-opacity-10 text-gray-500'} ${task.priority === 'low' && 'bg-blue-400 bg-opacity-10 text-blue-500'} inline-block  uppercase text-[10px] py-1 px-2 font-medium`}>{task.priority} priority
                    </span>
                </div> */}
                <div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>description</label>
                    <p className='text-sm'>{task.description}</p>
                </div>
                {/* {task.dueDate &&<div className='mb-[20px]'>
                    <label className='text-xs text-gray-400 tracking-[0.2em] block mb-[5px] uppercase'>due date</label>
                    <p className='text-sm'>{formatDate(new Date(task.dueDate))}</p>
                </div>} */}

                <div className='flex items-center justify-between mb-[20px]'>
                    <div className='flex items-center gap-x-2'>
                        {/* <div className='w-[40px] h-[40px] rounded-full border border-gray-400 bg-gray-100 flex items-center justify-center'>
                            <UserIcon className={`w-5 h-5 rounded-sm text-gray-400 `} />
                        </div> */}
                        {/* <div>
                            <p className='text-gray-600 text-xs'>0 Assignees</p>
                            <p className='text-gray-6400 text-xs mt-1 font-thin'>0 Employees currently performing this task</p>
                        </div> */}
                    </div>
                    <OrderStatus status={task.status} />
                </div>
            </div>
            }
        </div>
    )
}

export default TaskDetails