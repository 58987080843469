import React, { useEffect, useState } from 'react'
import UserLayout from '../../components/layout/UserLayout'
import { authHeader, employeeProfile, onboardingCompletion, userDetails } from '../../utils'
// import TaskCard from '../../components/partials/tasks/TaskCard';
// import Donut from '../../components/elements/Donut';
import EmptyState from '../../components/elements/icons/EmptyState';
import PendingReviews from '../../components/partials/home/PendingReviews';
import { useDispatch, useSelector } from 'react-redux';
import PendingDocumentSignings from '../../components/partials/home/PendingDocumentSignings';
import { fetchDocumentAssignments } from '../../store/actions/documentActions';
import PendingTasks from '../../components/partials/home/PendingTasks';
import OngoingTasks from '../../components/partials/home/OngoingTasks';
import axios from 'axios';
import { ERROR, TASKS_ERROR } from '../../store/types';
import CircularGauge from '../../components/elements/CircularGauge';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../components/elements/icons/ArrowIcon';
import { fetchNewsArticles } from '../../store/actions/newsActions';
import Preloader from '../../components/elements/Preloader';
import DashboardNewsArticleCard from '../../components/partials/home/DashboardNewsArticleCard';

const Home = () => {
  const dispatch = useDispatch()
  const documentsSelector = useSelector(state => state.documents)
  const newsSelector = useSelector(state => state.news)
  
  // const [performanceTotal, setPerformanceTotal] = useState(45);
  // const [monthlyPerformances, setMonthlyPerformances] = useState([12, 12, 18, 40, 11, 2, 6]);

  // const tasks = [
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'IN_PROGRESS',
  //     comments: 3,
  //     priority: 'high',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   },
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'IN_PROGRESS',
  //     comments: 3,
  //     priority: 'normal',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   },
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'IN_PROGRESS',
  //     comments: 3,
  //     priority: 'normal',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   },
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'IN_PROGRESS',
  //     comments: 3,
  //     priority: 'normal',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   },
  // ]

  // const upcomingTasks = [
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'BACKLOG',
  //     comments: 3,
  //     priority: 'high',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   },
  //   {
  //     title: "Lorem ipsum dolor sit amet",
  //     description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
  //     status: 'BACKLOG',
  //     comments: 3,
  //     priority: 'normal',
  //     dueDate: "25/11/2024",
  //     createdAt: new Date()
  //   }
  // ]

  const [tasksCount, setTasksCount] = useState(0);

  useEffect(() => {
    dispatch(fetchDocumentAssignments(1, 3, userDetails().employeeProfile))
    dispatch(fetchNewsArticles(1, 4))

    const fetchEmployeePendingTasksCount = async() => {  
      try{
        const headers = authHeader()
        let requestUrl = 'task-assignments?expand=assignee,task&perPage=0&page=0&status=todo'

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setTasksCount(response.data.data.total)
      }
      catch(error){
        // setFetchingTasks(false)
        dispatch( {
            type: TASKS_ERROR,
            // payload: error.response.data,
            error
        })
      }
    }

    fetchEmployeePendingTasksCount()

    const fetchDepartmentLeavePolicies = async () => {    
      if(!employeeProfile() || !employeeProfile()?.department) {
        setLoadingLeaves(false)
        return
      }
       try{
        const headers = authHeader()
        let requestUrl = `leave-policies/${employeeProfile()?.department?._id}`
        setLoadingLeaves(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setDepartmentLeavePolicies(response.data.data.data)
        let maxLeaveDays = response.data.data.data?.policies?.allowedLeaveTypes?.types?.reduce((accumulator, current) => accumulator + current.maxDays, 0)
        console.log('max leave days', maxLeaveDays)
        setMaxLeaveDays(maxLeaveDays)
        setLoadingLeaves(false)
      }
      catch(error){
        console.log('department leave policies error -> ', error)

        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    fetchDepartmentLeavePolicies()


    return () => {
      
    };
  }, [dispatch]);

  // const months = ['Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024']

  // const storeSalesData = [
  //   {
  //       label: 'Completed',
  //       amount: 41,
  //       colour: '#00D78C'
  //   },
  //   {
  //       label: 'in Progress',
  //       amount: 17,
  //       colour: '#6D3ADD'
  //   },
  //   {
  //       label: 'Backlog',
  //       amount: 15,
  //       colour: '#e3e3e3'
  //   },
  // ]

  // const cleanUpChartData = (data) => {
  //     const labels = []
  //     const series = []
  //     const colours = []

  //     data.forEach((item) => {
  //         labels.push(item.label)
  //         series.push(item.amount)
  //         colours.push(item.colour)
  //     });

  //     return {
  //         labels, series, colours
  //     }
  // }

  const [loadingLeaves, setLoadingLeaves] = useState(true);

  const [departmentLeavePolicies, setDepartmentLeavePolicies] = useState(null);
  const [maxLeaveDays, setMaxLeaveDays] = useState(0);


  return (
    <UserLayout pageTitle={`Home`}>
      <div className='w-full flex items-start gap-x-6 mb-[100px]'>
        <div className='w-8/12'>
          <div className='w-full rounded-[8px] bg-verovian-purple py-[20px] min-h-[150px] h-inherit px-[50px] mt-[32px] flex items-start justify-between'>
            <div className='w-full'>
              <p className='text-[24px] font-[600] text-white'>Welcome, {userDetails()?.firstName}</p>
              <p className='text-white text-sm mt-[10px] mb-[10px]'>You have <span className='text-yellow-500'>{tasksCount} new tasks</span> to attend to</p>

              <Link to={'/user/task-management'} className='rounded-[8px] bg-white text-verovian-purple text-sm px-[18px] py-[12px] inline-block'>Go to Task Management</Link>
            </div>

            {userDetails().employeeProfile && userDetails().employeeProfile !== '' && <div className='w-full text-right'>
              <p className='text-white text-sm mt-[10px] mb-[10px]'>Your onboarding progress</p>
              <div className='w-full flex flex-row-reverse'>
                <CircularGauge
                  percentage={onboardingCompletion()}
                  completeColor="#A359F7"
                  incompleteColor="#F7EEFF"
                  size={75}
                  strokeWidth={4}
                />
              </div>
              <Link to={`/user/onboarding`}  className='text-right text-xs text-gray-200 font-[500] flex flex-row-reverse items-center gap-x-2 hover:text-verovian-light-purple duration-200 transition mt-3'>
                <ArrowIcon className={`w-4 h-4 -rotate-90`}/>
                Go to onboarding 
              </Link>
              
            </div>}
          </div>

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Monthly Performance</h3>
          </div>

          <div className='bg-white rounded-[8px] p-[40px]'>
          <EmptyState emptyStateText={`No performance data yet`} />
            {/* <div className="relative w-full h-[300px] flex flex-row-reverse justify-between items-end gap-x-[4px]">
              {monthlyPerformances.map((item, index) => (
                <div
                  key={index}
                  onMouseEnter={() => {}}
                  onMouseLeave={() => {}}
                  className="w-[54px] border-t border-verovian-purple bar bar-gradient transition duration-200"
                  style={{
                    '--final-height': `${(item / performanceTotal) * 100}%`,
                  }}
                ></div>
              ))}
            </div>
            <div className="relative w-full h-[50px] flex justify-between items-end gap-x-[4px]">
              {months.map((month, monthIndex)=>(
                <div key={monthIndex} className="">
                  <p className='text-xs text-gray-500'>{month}</p>
                </div>
              ))}
            </div> */}
          </div>

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Ongoing Tasks</h3>
          </div>
          <div className='bg-white rounded-[8px] p-[40px]'>
            <OngoingTasks />
          </div>

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Latest News</h3>
          </div>
          <div className='bg-white rounded-[8px] p-[40px]'>
            {newsSelector.loadingNewsArticles ? 
                <Preloader />
              :
                <>
                  {newsSelector?.newsArticles?.articles?.length > 0 ?
                    <div className='grid grid-cols-2 gap-[20px]'>
                      {newsSelector?.newsArticles?.articles?.map((article, articleIndex) => (
                        <div key={articleIndex} className='w-full'>
                          <DashboardNewsArticleCard article={article} />
                        </div>
                      ))}
                    </div>
                  :
                  <EmptyState emptyStateText={`No news articles at the moment`} />}
                </>
            }


          </div>

          
        </div>

        {/* Right sidebar */}
        <div className='w-4/12'>
          {employeeProfile() && <>
            <p className='text-xs uppercase tracking-[0.2em] mt-4 mb-2'>current department/designation</p>
            <div className="w-full p-[25px] bg-white">
              <p className='text-[15px] text-gray-600 mb-1 font-medium'>{employeeProfile().department.name}</p>
              <p className='text-xs'>{employeeProfile().designation.name}</p>
            </div>
          </>}

          {documentsSelector?.gettingDocuments && documentsSelector?.documentAssignments?.assignments?.filter(doc => {return doc.document.requiresSigning === true && !doc.documentSigning}).length > 0 && <PendingDocumentSignings documents={documentsSelector?.documentAssignments?.assignments?.filter(doc => {return doc.document.requiresSigning === true && !doc.documentSigning})} />}

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Pending Tasks</h3>
          </div>
          <PendingTasks />
          
          <PendingReviews />

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Leave/Holiday Allowance</h3>
          </div>
          {loadingLeaves ? 
            <Preloader />
          :
          <div className="w-full p-[25px] bg-white">
            <p className='text-[20px] font-medium'><span className='font-space-grotesk'>{maxLeaveDays || 0}</span> <span className="text-[13px] font-[400]">days total,</span> <span className='font-space-grotesk'>0</span> <span className="text-[13px] font-[400]">days used</span></p>
            <div className="h-[1px] bg-gray-300 w-full my-2" />
            {departmentLeavePolicies?.policies?.allowedLeaveTypes.types.map((leaveType, leaveTypeIndex)=>(
              <p key={leaveTypeIndex} className='text-xs mt-2 capitalize'>{leaveType.leaveType.toLowerCase()} leaves days - {leaveType.maxDays} days - <span className='underline'>0 taken</span></p>))}
            {/* <p className='text-sm mt-1'>Annual leave days taken - 8 days</p> */}
          </div>}

          {/* <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Activity Progress</h3>
          </div>
          <div className='w-full p-[20px] bg-white'>
          <EmptyState emptyStateText={`No activity data yet`} /> */}

            {/* <Donut showLegend={true} 
                showDataLabels={false} 
                size="55%" 
                series={cleanUpChartData(storeSalesData).series} 
                colours={cleanUpChartData(storeSalesData).colours} 
                labels={cleanUpChartData(storeSalesData).labels} /> */}
          {/* </div> */}
          {/* <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Holiday Allowance</h3>
          </div>
          <div className='p-[20px] bg-white'>
            <p className='text-sm mt-4'><span className='text-2xl text-white font-medium p-[10px] bg-verovian-purple'>4</span> days left out of <span className='text-gray-400'>28</span></p>
          </div> */}
        </div>
      </div>
    </UserLayout>
  )
}

export default Home