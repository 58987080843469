import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearCreatedReview, getEmployeeReviews } from '../../../store/actions/reviewsActions';
// import { Link } from 'react-router-dom';
// import PlusIcon from '../../elements/icons/PlusIcon';
import EmployeeReviewFormCard from './EmployeeReviewFormCard';
import EmptyState from '../../elements/icons/EmptyState';
import { SET_SUCCESS_MESSAGE } from '../../../store/types';
import ModalLayout from '../../layout/ModalLayout';
import NewReviewSchedule from '../reviews-management/NewReviewSchedule';
import PlusIcon from '../../elements/icons/PlusIcon';

const EmployeeReviews = ({employeeDetails}) => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getEmployeeReviews(employeeDetails._id, 0, 0))

      if(reviewsSelector.createdReview !== null){
          dispatch({
            type: SET_SUCCESS_MESSAGE,
            payload: {
              successMessage: 'Review scheduled successfully!'
            }
        })
        dispatch(clearCreatedReview())
        setSchedulingReview(false)
      }
      return () => {
          
      };
  }, [dispatch, employeeDetails._id, reviewsSelector.createdReview]);

  const reviewProgresses = [
    {done: 10, pending: 2, sentiment: 'positive'}, 
    {done: 24, pending: 9, sentiment: 'neutral'}, 
    {done: 1, pending: 0, sentiment: 'negative'}, 
    {done: 1, pending: 1}, 
  ]

  const [schedulingReview, setSchedulingReview] = useState(false);
  
  return (
    <>
      <div>
        <div className='flex items-start justify-between mt-55 mb-12'>
          <div>
            <p className="text-md mb-1 font-[500]">Employee Reviews</p>
            <p className='text-sm'>Please see reviews for this employee below with their progress on each training</p>
          </div>
          <div className='w-4/12 flex flex-row-reverse justify-between items-center mt-4 mb-4'>
              <button onClick={()=>{setSchedulingReview(true)}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Schedule a review
              </button>
            </div>
        </div>

        {reviewsSelector?.reviews?.reviews?.length > 0 ?
          <>                                    
            <div className='grid grid-cols-2 gap-6'>
                {reviewsSelector?.reviews?.reviews?.map((form, formIndex)=>(
                <div key={formIndex} className="bg-white">
                    <EmployeeReviewFormCard form={form} progress={reviewProgresses[formIndex]} />
                </div>
                ))}
            </div>
          </>
          :
          <EmptyState emptyStateText={`No reviews submitted for this employee yet.`} />
        }
      </div>

      <ModalLayout
        isOpen={schedulingReview} 
        closeModal={()=>{setSchedulingReview(false)}} 
        actionFunction={()=>{}} 
        actionFunctionLabel='' 
        dialogTitle='Create a review schedule'
        maxWidthClass='max-w-lg'
      >
        <NewReviewSchedule employee={employeeDetails} />

      </ModalLayout>
    </>
  )
}

export default EmployeeReviews